// Карточка шага
.step-card {
	&__item {
		height: 100%;
		// padding: 30px 40px;
		// border-radius: 20px;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		background-color: #fff;
		border-radius: 20px;
		padding: 30px 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		position: relative;

		// background: #FAF0E1;
		box-shadow: 0px 20px 60px -10px rgba(15, 29, 67, 0.25);

		@include respond-to(md) {
			flex-direction: row;
			align-items: flex-start;
			gap: 18px;
			padding: 25px 25px;

			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; // легкая тень
		}
	}

	&__info {}

	&__icon {}

	&__number {
		display: flex;
		align-items: center;
		justify-content: center;

		width: 70px;
		height: 70px;

		border-radius: 50px;
		background-color: #f5f5f5;
		background: linear-gradient(180deg, #FFE89E 0%, #FECC3C 100%);

		font-weight: 700;
		font-size: 21px;
		line-height: normal;
		text-align: center;

		// position: absolute;
		// top: -10px;
		// left: -10px;

		@include respond-to(md) {
			font-size: 14px;
			width: 40px;
			height: 40px;
		}
	}

	&__number-wrapper {
		@include respond-from(md) {
			margin-top: -50px;
		}
	}

	&__title {
		font-size: 20px;
		font-weight: 600;
		line-height: 140%;
		text-align: center;
		margin-top: 20px;
		// padding: 0 40px;

		@include respond-to(md) {
			text-align: left;
			font-size: 16px;
			line-height: 150%;
			margin-top: 0;
		}
	}

	&__description {
		@extend .content-area;
		font-size: 14px;
		font-weight: 400;
		line-height: 170%;
		margin-top: 30px;

		p {
			font-size: 14px;
			font-weight: 400;
			line-height: 170%;
		}

		@include respond-to(md) {
			margin-top: 10px;
			line-height: 150%;
		}
	}
}

// Сетка
.step-grid {
	display: flex;
	flex-wrap: wrap;
	column-gap: 30px;
	row-gap: 50px;
	justify-content: center;

	@include respond-to(md) {
		row-gap: 20px;
	}

	&__item {
		flex: 0 0 calc((100% / 3) - 30px);

		@include respond-to(xl) {
			flex: 1 1 calc((100% / 2) - 30px);
		}

		@include respond-to(lg) {
			flex: 1 1 calc((100% / 2) - 30px);
		}

		@include respond-to(md) {
			flex: 1 1 calc((100% / 1) - 30px);
		}
	}
}

.step {
	&__items {
		position: relative;
	}
}

.steps-line {
	height: 3px;
	background: linear-gradient(90deg, transparent, transparent 50%, #fff 50%, #fff 100%),
		#D4D4D4;
	background-size: 20px 3px,
		100% 3px;
	border: none;

	position: absolute;
	top: 33px;
	left: 120px;
	right: 120px;

	@include respond-to(md) {
		display: none;
	}
}