.price-table {
  &__title {
    @extend .base-header;
    font-size: 32px;
    margin-bottom: 20px;
    color: $color-primary;

    @include respond-to(md) {
      text-align: center;
    }
  }

  &__table {
    @extend .price-table-full;
    @extend .tile;
    @extend .tile-white-shadow;
    background-color: #fff;

    &+& {
      margin-top: 50px;
    }

    @include respond-to(md) {
      background-color: unset;
      box-shadow: unset;
      padding: 0;
    }
  }
}

.price-tabs {
  &__tabs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 30px;
    border: unset;
    justify-content: center;
    align-items: center;
  }

  &__tab {
    @extend .button;
    @extend .button-29;
    border: unset;

    &[aria-selected=false] {
      color: #222;
      background: #f5f5f5;
    }
  }
}