// Карточка независимого рейтинга
.independent-rating-card {
	&__item {
		@extend .base-deep-shadow;
		padding: 30px 40px;
		border-radius: 20px;
		// box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@include respond-to(md) {
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; // легкая тень
		}

		@include respond-to(md) {
			padding: 25px 30px;
		}
	}

	&__more {
		color: #fff;
		font-size: 30px;
		line-height: 120%;
		font-weight: 500;
		text-align: center;
		padding: 30px 40px;
		border-radius: 20px;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background: rgb(246, 128, 129);
		background: linear-gradient(45deg, rgba(246, 128, 129, 1) 20%, rgba(250, 184, 164, 1) 80%);

		@include respond-to(md) {
			padding: 20px 30px;
		}
	}

	&__arrow-caption {
		color: #333;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		font-size: 24px;
		line-height: 120%;
		text-align: center;
		position: relative;

		@include respond-to(md) {
			font-size: 22px;
			left: -60px;
			text-align: right;
			margin-bottom: 10px;
		}

		@include respond-from(md) {
			top: 30px;
		}

		&:after {
			@include recolor($color-primary);
			content: '';
			display: block;
			background-image: url(../img/interface/arrow-1.svg);
			width: 100px;
			height: 100px;
			background-position: center;
			background-size: contain;
			position: absolute;
			top: 80px;
			right: 40px;

			@include respond-to(md) {
				top: -30px;
				right: -20px;
				transform: rotate(105deg);
			}
		}
	}

	&__logo {
		img {
			height: 35px;
			max-width: 100%;
			object-fit: contain;
			object-position: center;

			@include respond-to(md) {
				height: 25px;
				max-width: 80px;
			}
		}
	}

	&__rating {
		font-size: 32px;
		font-weight: 600;
		margin-top: 10px;

		@include respond-to(md) {
			font-size: 24px;
		}
	}

	&__description {
		color: #6b748b;
		font-size: 14px;
		font-weight: 400;
		white-space: nowrap;

		@include respond-to(md) {
			font-size: 12px;
		}
	}

	&__stars {
		display: flex;
		flex-direction: row;
		gap: 2px;
		margin-top: 15px;

		img {
			height: 15px;
			object-fit: contain;
			object-position: center;
		}
	}

	&__button {
		color: #6b748b;
		font-size: 14px;
		font-weight: 400;
		margin-top: 20px;

		&:hover {
			color: $color-primary;
		}
	}
}