// first screen
.first-screen {
	&__section {
		position: relative;
		overflow: hidden;
	}

	&__background {
		background-image: url(../img/backgrounds/stom-2.jpg);
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		z-index: 0;

		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;

		// // Отступы
		// padding: 250px 0;
		// margin: -100px 0 !important;

		// Переходы
		// mask-image: url(../img/perehod/section-cloud-mask.svg);
		mask-image: url(../img/perehod/section-cloud-bottom-mask.svg);
		mask-position: center;
		mask-size: 100% 100%;
		mask-repeat: no-repeat;

		// &:before {
		// 	content: '';
		// 	position: absolute;
		// 	top: 0;
		// 	right: 0;
		// 	left: 0;
		// 	bottom: 0;
		// 	z-index: 1;
		// 	background: linear-gradient(to left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);

		// 	// Размытие
		// 	backdrop-filter: blur(10px) brightness(1.1);

		// }
	}

	&__grid {
		display: flex;
		flex-direction: row;

		@include respond-to(md) {
			justify-content: space-between;
		}
	}

	&__left {
		margin: 80px 0;
		position: relative;
		z-index: 10;

		@include respond-to(lg) {
			margin: 40px 0;
		}

		@include respond-to(md) {
			margin: 15px;
		}
	}

	&__right {
		flex: 1;

		@include respond-to(md) {
			display: none;
		}
	}

	&__top {
		// color: #fff;
		position: relative;
		z-index: 1;
		// background-image: url(../img/first-screen/first-screen.jpg);
		padding: 150px 0 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center right;

		&>* {
			position: relative;
			z-index: 10;
		}

		@include respond-to(md) {
			padding: 130px 0 20px;
			background-image: none;
		}

		&:before {
			background-size: cover;
			background-repeat: no-repeat;
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
			max-width: 1132px;
			height: 100%;
			z-index: 0;
			// background-image:../img/employees/hachataryan.png url(../img/first-screen/first-screen-isolated.png);
			background-position: 100% 0;
			pointer-events: none;
		}

		&:after {
			@include recolor(#fff);
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			// max-width: 1132px;
			max-width: 1800px;
			height: 100%;
			z-index: -1;
			// background-image: url(../img/patterns/pattern-bagel-01.svg);
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: left 0;
			opacity: .5;

			@include respond-to(md) {
				background-size: 200%;
				background-position: -20px -100px;
				opacity: .5;
				left: unset;
				right: 0;
			}
		}

		.h1 {
			margin-top: 29px
		}
	}

	&__suptitle {
		background-color: $color-primary;
		display: inline-block;
		padding: 5px 25px;
		border-radius: 30px;
		margin-bottom: 50px;
	}

	&__title {
		color: #11263A;
		font-family: $font-decorative;
		font-size: 58px;
		font-weight: 700;
		line-height: 125%;
		text-transform: uppercase;
		letter-spacing: .5px;

		@include respond-to(lg) {
			font-size: 46px;
			// margin-bottom: 15px;
			margin: 15px 0 15px;
		}

		@include respond-to(md) {
			font-size: 26px;
			line-height: 140%;
			font-weight: 800;
			margin: 0;
			text-align: center;
			// margin-bottom: 10px;
		}

		@include respond-from(md) {
			max-width: 70%;
		}

		.lead {
			@include respond-from(md) {
				font-size: 72px;
				line-height: 120%;
				font-weight: 700;
			}

			@include respond-to(md) {
				font-size: 42px;
				font-weight: 600;
			}
		}

		&--center {
			text-align: center;
		}

		span {
			// @extend .text-gradient;
			color: $color-primary;
		}
	}

	&__subtitle {
		font-family: $font-header;
		font-weight: 400;
		font-style: normal;
		margin-top: 25px;
		font-size: 25px;
		line-height: 140%;
		color: #333;

		@include respond-to(md) {
			text-align: center;
			font-size: 20px;
			line-height: 140%;
			margin-top: 15px;
		}
	}

	&__text {
		@extend .content-area;
		@extend .indication-list;
		font-family: $font-geometria;
		font-weight: 400;
		// font-style: italic;
		flex-grow: 1;
		color: #7f8f98;
		font-size: 16px;
		line-height: 170%;
		color: #222 !important;
		margin-top: 50px;

		@include respond-to(md) {
			margin-top: 50px;
			max-width: 65%;
		}

		h2 {
			font-weight: 400;

			@include respond-to(md) {
				margin-bottom: 20px;
			}
		}

		ul {
			li {
				@include respond-to(md) {
					margin-left: 0;
					padding-left: 0;
					font-size: 14px;
					line-height: 160%;
				}
			}
		}
	}

	&__price {
		background-color: #fff;
		padding: 20px 30px;
		border-radius: 10px;
		width: max-content;
		margin-top: 20px;

		@include respond-to(md) {
			font-size: 12px;
			margin-top: 50px;
			padding: 10px 20px;
		}

		.new {
			font-size: 20px;
			font-weight: 600;
			// margin-top: 5px;
		}
	}

	&__our-pluses {
		font-family: $font-geometria;
		font-weight: 400;
		font-style: italic;
		margin-top: 40px;
		display: flex;
		gap: 30px;
		color: #7f8f98;

		&>* {
			display: flex;
			align-items: center;
		}

		&__item {
			background-color: #fff;
			padding: 20px 30px;
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
			border-radius: 15px;
		}

		&__image {
			width: 50px;
			min-width: 50px;
			margin-right: 17px;
		}
	}

	&__button-with-text {
		// margin-top: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 30px;

		margin-top: 30px;

		@include respond-to(md) {
			flex-direction: column;
			gap: 10px;
			margin-top: 15px;
		}
	}

	&__button {
		@extend .button;
		@extend .button-1;

		@include respond-to(md) {
			font-size: 14px;
		}
	}

	&__button-text {
		color: #000;
		font-size: 14px;
	}

	&__present {
		color: #333;
		background-color: #fff;
		box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		padding: 10px 30px 10px 45px;
		font-size: 14px;
		line-height: 140%;
		font-weight: 500;
		border-radius: 10px;
		position: relative;

		@include respond-to(md) {
			font-size: 12px;
			line-height: 160%;

		}

		&:before {
			content: '';
			position: absolute;
			left: -12px;
			top: 10px;
			display: block;
			background-image: url(../img/interface/gift-small.png);
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			width: 40px;
			height: 40px;
			transform: rotate(-13deg);
		}
	}

	&__small-caption {
		font-size: 12px;
		margin-top: 40px;

		@include respond-to(md) {
			text-align: center;
		}
	}

	// Изображение специалиста
	&__specialist-img {

		img {
			position: absolute;
			top: 130px;
			right: 0;
			bottom: 0;
			height: 100%;

			@include respond-from(xl) {
				// right: 200px;
			}

			// @include respond-from(lg) {
			// 	right: 200px;
			// }

			@include respond-to(xl) {
				top: unset;
				right: -110px;
				height: 750px;
				bottom: 60px;
			}

			@include respond-to(lg) {
				top: unset;
				right: -110px;
				height: 600px;
				bottom: 60px;
			}

			@include respond-to(md) {
				top: unset;
				right: -110px;
				height: 570px;
				bottom: 60px;
			}

			@include respond-to(sm) {
				top: unset;
				right: -110px;
				height: 480px;
				bottom: 60px;
			}





			// Переход
			// mask-image: url(../img/perehod/section-cloud-mask.svg);
			mask-image: url(../img/perehod/section-cloud-bottom-mask.svg);
			mask-position: center;
			mask-size: 100% 100%;
			mask-repeat: no-repeat;
		}
	}
}

.fs__grid {
	position: relative
}

.fs__rating {
	display: flex;
	align-items: center;

	&__stars {
		padding-right: 19px;

		img {
			width: 56px;
			height: 54px
		}
	}

	&__icons {
		padding-left: 25px;

		&>:not(:first-child) {
			margin-top: 5px
		}
	}
}

.fs__awards {
	display: flex;
	align-items: center;
}

.fs__award {
	border-left: 1px solid #e7e7e7;
	padding-left: 40px;
	margin-left: 40px;

	img {
		margin-right: 14px;
	}

	&>* {
		font-family: Geometria,
			sans-serif;
		font-weight: 400;
		font-style: italic;
		cursor: auto;
		display: flex;
		align-items: center;
		// text-decoration: underline;
		font-size: 12px;
		line-height: 15px;
	}
}

.fs__subheader {
	font-family: $font-header;
	font-weight: 500;
	font-style: normal;
	padding-top: 25px;
	font-size: 25px;
	line-height: 31px;
	color: #333;
}

.fs__our-pluses {
	font-family: $font-geometria;
	font-weight: 400;
	font-style: italic;
	margin-top: 40px;
	display: flex;
	color: #7f8f98;

	&>* {
		display: flex;
		align-items: center;
	}

	&>:not(:first-child) {
		margin-left: 43px;
	}

	&__image {
		width: 50px;
		min-width: 50px;
		margin-right: 17px;
	}
}

.fs__prices {
	display: flex;
	align-items: center;
	margin-top: 100px;
	color: #333;
	background: $color-primary;
	background: linear-gradient(176deg, lighten($color-primary, 20%), lighten($color-primary, 10%) 32.29%, $color-primary);
	border-radius: 10px;
	padding: 20px 20px 20px 30px;
	margin-right: 226px;
	width: fit-content;
	justify-content: space-between;
	min-width: 1064px;

	&>* {
		width: 345px;
		padding-right: 10px;
	}

	&>:last-child {
		padding-right: 0;
	}
}

// @media screen and (max-width:1919px) {
//   .fs__top:before {
//     max-width: 875px;
//     background-image: url(../static/implant_dentalway__ru/build/i/c1c9f5c12158fa4c871bbdd886e40250.png);
//     background-position: 0 0
//   }
// }