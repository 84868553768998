// имплант
.implant {
	&__section-bg {
		@extend .base-section-bg;
	}

	&__content {
		margin-left: -15px;
		margin-right: -15px;
		margin-top: 18px;

		&>* {
			flex: 0 1 56%;
			padding-left: 15px;
			padding-right: 15px;
			margin-top: 15px;
		}

		&> :nth-child(2) {
			flex-basis: 44%;
		}
	}

	&__text {
		// font-size: 18px;
		// line-height: 28px;
		// position: relative;
		// padding-left: 26px;
		// margin-top: 11px;

		// &:before {
		//   content: "";
		//   position: absolute;
		//   left: 3px;
		//   top: 8px;
		//   width: 10px;
		//   height: 10px;
		//   border-radius: 50%;
		//   background-color: $color-primary;
		// }

		p {
			font-size: 16px;
		}

		ul {
			margin-top: 5px;

			li {
				font-size: 14px;
				line-height: 160%;
				position: relative;
				padding-left: 26px;

				&+& {
					margin-top: 10px;
				}

				&:before {
					content: "";
					position: absolute;
					left: 3px;
					top: 8px;
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background-color: $color-primary;
				}
			}
		}
	}

	&__award {
		display: flex;
		align-items: center;
		background-color: $color-primary;
		border-radius: 10px;
		padding: 7px 35px 8px 117px;
		font-size: 14px;
		line-height: 22px;
		color: #fff;
		min-height: 86px;
		position: relative;
		z-index: 1;

		&>i {
			position: absolute;
			left: 37px;
			top: 5px;
		}
	}

	&__prices {
		align-items: center;
		margin-top: 17px;

		&>* {
			flex: 0 1 38.7%;
		}

		&__image-wrapper {
			flex: 0 1 22.6%;
			position: relative;
			z-index: 0;
			padding-top: 25px;
			padding-bottom: 25px;
		}

		&__image {
			margin-left: -80px;
			margin-right: -80px;
			position: relative;

			&:after {
				content: "";
				display: block;
				height: 0;
				padding-top: 100%;
			}

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
			}

			&:before {
				content: "";
				position: absolute;
				width: calc(100% + 50px);
				height: calc(100% + 50px);
				left: -25px;
				top: -25px;
				border: 2px solid $color-primary;
				border-radius: 50%;
				box-sizing: border-box;
			}

			&>i {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				display: block;
				padding: 11px 11px 19px;

				&:before {
					content: "";
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(219.35deg, $color-primary, rgba(0, 134, 210, 0));
					-webkit-transform: scaleX(-1);
					transform: scaleX(-1);
					border-radius: 50%;
				}

				img {
					max-width: 100%;
					position: relative;
					width: auto;
				}
			}
		}

		&__list {
			display: flex;
			flex-direction: row;
			gap: 40px;
			position: relative;
			z-index: 1;
			margin-bottom: 9px;
		}
	}

	&__price {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 30px;
		justify-content: space-between;
		background: #fff;
		border-radius: 20px;
		min-height: 101px;

		padding: 20px 50px;

		box-shadow: 0 30px 100px -10px rgba(15, 29, 67, .25);

		&:first-child {
			margin-top: 0;
		}

		&__img {
			img {
				height: 120px;
			}
		}

		&__flags {
			margin-left: -10px;
			margin-right: 10px;

			&>i {
				position: relative;
				background-color: rgba(0, 140, 212, .3);
				max-width: 63px;
				min-width: 63px;
				display: block;
				border-radius: 5px;
				overflow: hidden;
				margin-top: 5px;

				&:after {
					content: "";
					display: block;
					height: 0;
					padding-top: 65.07937%;
				}

				img {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
				}

				&:first-child {
					margin-top: 0;
				}
			}
		}

		&__title {
			font-weight: 700;
			font-style: normal;
			font-size: 18px;
			line-height: 130%;
			margin-bottom: 10px;
			margin-left: 0;

			&>span {
				font-weight: 400;
				font-style: italic;
				display: block;
				color: #6b6b6b;
				font-size: 14px;
				line-height: 20px;
				margin-top: 7px;
			}
		}

		&__prices {
			margin-top: 5px;

			&>* {
				white-space: nowrap;
			}

			.old {
				font-family: Geometria,
					sans-serif;
				font-style: normal;
				line-height: 20px;
				font-weight: 500;
				text-decoration: line-through;
				font-size: 18px;
				margin-right: 8px;
			}

			.new {
				font-family: Geometria,
					sans-serif;
				font-style: normal;
				line-height: 20px;
				font-weight: 700;
				color: $color-primary;
				font-size: 30px;
			}
		}
	}

	&__button {
		@extend .button;
		@extend .button-1;
	}
}

.implant {


	&.implant_one-col {

		& .implant__prices__image:after,
		& .implant__prices__image:before,
		& .implant__prices__image i:before {
			display: none;
		}

		.implant__prices {
			position: relative;

			&>* {
				flex-basis: auto;
			}
		}

		.implant__price {
			min-height: 161px;
			padding-left: 45px;

			// &:first-child {
			//   margin-top: 20px;
			//   margin-left: 25px;
			// }
		}

		.implant__prices__list {
			display: flex;
		}

		.implant__prices__image-wrapper {
			position: absolute;
			padding: 0;
			right: -120px;
			bottom: -50px;
		}

		.implant__price__title {
			margin-left: 5px;
		}

		.implant__prices__image {
			position: relative;
			margin-right: 0;
			margin-left: 0;
			width: 429px;

			img {
				position: relative;
				height: auto;
				width: auto;
			}

			i {
				position: relative;
			}
		}

		.implant__price__inner {}

		.implant__price__prices {
			margin-top: 25px;

			.low {
				font-size: 20px;
			}
		}
	}
}

.implant {
	&__button-with-phone {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		margin-top: 30px;

		@include respond-to(md) {
			flex-direction: column;
		}

		&__phone {
			p {
				font-size: 14px;
				line-height: normal;
			}

			a {
				font-size: 28px;
				font-weight: 600;
				transition: .2s ease-in-out;

				&:hover {
					color: $color-primary;
				}
			}
		}
	}
}

@media screen and (max-width:1312px) {
	.implant {
		margin-top: 48px;
		padding-top: 63px;
		padding-bottom: 37px
	}

	.implant.implant_one-col .implant__prices__image-wrapper {
		right: 0;
		bottom: -30px
	}

	.implant.implant_one-col .implant__prices__image {
		width: 340px
	}

	.implant.implant_one-col .implant__price:first-child {
		margin-top: 10px
	}

	.implant__content {
		margin-top: 0
	}

	.implant__content>* {
		flex-basis: 50%;
		margin-top: 0
	}

	.implant__content>:nth-child(2) {
		flex-basis: 50%
	}

	.implant__award {
		margin-top: 12px;
		min-height: 94px;
		padding-left: 90px;
		padding-right: 21px
	}

	.implant__award>i {
		left: 20px;
		top: 10px
	}

	.implant__prices {
		margin-top: 29px
	}

	.implant__prices>* {
		flex-basis: 39.7%
	}

	.implant__prices__image-wrapper {
		flex-basis: 20.6%;
		padding-top: 22px;
		padding-bottom: 22px
	}

	.implant__prices__image {
		margin-left: -98px;
		margin-right: -98px
	}

	.implant__prices__image:before {
		top: -22px;
		left: -22px;
		width: calc(100% + 44px);
		height: calc(100% + 44px)
	}

	.implant__prices__list {
		margin-bottom: 6px
	}

	.implant__price {
		margin-top: 10px;
		margin-left: 10px;
		padding-right: 25px
	}

	.implant__price__title {
		margin-left: 7px
	}

	.implant__price__prices {
		text-align: right;
		margin-top: 6px
	}

	.implant__price__prices .old {
		display: block;
		margin-right: 0;
		font-size: 18px;
		line-height: 25px;
		margin-bottom: 4px
	}

	.implant__price__prices .new {
		font-size: 25px;
		line-height: 25px
	}
}

@media screen and (max-width:1023px) {
	.implant {
		margin-top: 34px;
		padding-top: 45px;
		padding-bottom: 48px
	}

	.implant .h2 {
		margin-left: 3px
	}

	.implant.implant_one-col .implant__prices {
		justify-content: space-between;
		flex-wrap: nowrap
	}

	.implant.implant_one-col .implant__prices__left {
		flex: 1 0 10%
	}

	.implant.implant_one-col .implant__prices>* {
		flex: 0 1 65%
	}

	.implant.implant_one-col .implant__prices__list {
		flex-direction: column
	}

	.implant.implant_one-col .implant__prices__image-wrapper {
		position: relative;
		flex: 1 0 28%;
		bottom: 0
	}

	.implant.implant_one-col .implant__price {
		display: flex;
		justify-content: center;
		margin-left: 0;
		max-width: 300px;
		min-height: 140px;
		padding-left: 60px
	}

	.implant.implant_one-col .implant__price:first-child {
		margin-left: 0
	}

	.implant.implant_one-col .implant__prices__image {
		width: auto;
		margin-right: 20px
	}

	.implant.implant_one-col .implant__price__prices {
		margin-top: 10px
	}

	.implant.implant_one-col .implant__prices__right .implant__prices__list {
		align-items: flex-end
	}

	.implant__award {
		border-radius: 5px;
		margin-top: 7px;
		min-height: 109px
	}

	.implant__award>i {
		top: 14px
	}

	.implant__prices {
		margin-top: 17px
	}

	.implant__prices>* {
		flex-basis: 39.3%
	}

	.implant__prices__list {
		margin-bottom: 0
	}

	.implant__price {
		position: relative;
		padding-left: 70px;
		display: block;
		padding-top: 18px
	}

	.implant__price__flags {
		position: absolute;
		left: 0;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%)
	}

	.implant__price__title {
		margin-left: 0;
		font-size: 18px;
		line-height: 20px
	}

	.implant__price__title>span {
		margin-top: 2px
	}

	.implant__price__prices {
		margin-top: 3px;
		text-align: left
	}

	.implant__price__prices .old {
		display: inline;
		margin-right: 5px
	}

	.implant__price__prices .new {
		font-size: 22px
	}

	.implant__prices__image-wrapper {
		flex-basis: 21.4%
	}

	.implant__prices__image {
		margin-left: -85px;
		margin-right: -85px
	}

	.implant__prices__image:before {
		top: -19px;
		left: -19px;
		width: calc(100% + 38px);
		height: calc(100% + 38px)
	}
}

@media screen and (max-width:767px) {
	.implant {
		margin-top: 40px;
		padding-top: 35px;
		padding-bottom: 40px
	}

	.implant .h2 {
		margin-left: 0;
		padding-right: 15px
	}

	.implant.implant_one-col .implant__price {
		padding-left: 0
	}

	.implant.implant_one-col .implant__prices__left {
		flex-basis: 100%;
		margin-bottom: 9px
	}

	.implant.implant_one-col .implant__prices__image-wrapper {
		display: none
	}

	.implant.implant_one-col .implant__prices__list {
		display: flex;
		align-items: center
	}

	.implant.implant_one-col .implant__price {
		min-width: 290px
	}

	.implant.implant_one-col .implant__prices__right .implant__prices__list {
		align-items: center
	}

	.implant.implant_one-col .implant__prices {
		flex-wrap: wrap
	}

	.implant.implant_one-col .implant__prices>* {
		flex: 0 1 100%
	}

	.implant__content>*,
	.implant__content>:nth-child(2) {
		flex-basis: 100%
	}

	.implant__award {
		margin-top: 13px;
		padding-top: 10px;
		padding-left: 78px;
		padding-bottom: 10px
	}

	.implant__award>i {
		left: 10px;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%)
	}

	.implant__prices {
		margin-top: 36px
	}

	.implant__prices__image-wrapper {
		padding-top: 15px;
		padding-bottom: 15px
	}

	.implant__prices__image {
		margin-left: -102px;
		margin-right: -102px
	}

	.implant__prices__image:before {
		left: -15px;
		top: -15px;
		width: calc(100% + 30px);
		height: calc(100% + 30px)
	}

	.implant__prices__image>i {
		padding: 5px 5px 12px
	}

	.implant__price {
		padding: 28px 5px 9px 10px;
		margin-left: 0;
		margin-top: 16px;
		border-radius: 5px
	}

	.implant__price__flags {
		display: flex;
		align-items: flex-start;
		top: -6px;
		left: 10px;
		-webkit-transform: none;
		transform: none;
		margin-left: 0;
		margin-right: 0
	}

	.implant__price__flags>i {
		max-width: 40px;
		min-width: 40px;
		margin-right: 5px;
		margin-top: 0
	}

	.implant__price__flags>i:last-child {
		margin-right: 0
	}

	.implant__price__prices .old,
	.implant__price__title {
		font-size: 14px;
		line-height: 20px
	}

	.implant__price__prices .old {
		margin-bottom: 1px
	}

	.implant__price__prices .new {
		font-size: 16px;
		line-height: 20px
	}
}