// Montserrat
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

// Tenor Sans
@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');

.tenor-sans-regular {
  font-family: "Tenor Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
}