.text-gradient {
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &-primary {
    background: -webkit-linear-gradient(lighten($color-primary, 45%), $color-primary);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}