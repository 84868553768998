#section-contacts {
  position: relative;

  @include respond-from(md) {
    height: 600px;
    padding: 100px 0;
  }
}

.contacts {
  &__tile {
    @include respond-to(md) {
      margin-bottom: 30px;
    }

    @include respond-from(md) {
      background-color: #fff;
      position: relative;
      z-index: 10;
      width: 40%;
      border-radius: 20px;
      padding: 35px 40px;

      box-shadow: 0px 40px 155px rgba(15, 29, 67, 0.25);
    }
  }

  &__title {
    font-family: $font-decorative;
    font-size: 28px;
    font-weight: 600;
    // color: $color-primary;
    line-height: 140%;

    @include respond-to(lg) {
      font-size: 26px;
      // margin-bottom: 15px;
      margin: 15px 0 15px;
    }

    @include respond-to(md) {
      font-size: 24px;
      line-height: 145%;
      // margin-bottom: 10px;
      margin: 10px 0 10px;
    }

    @include respond-from(md) {
      max-width: 70%;
    }

    &--center {
      text-align: center;
    }

    span {
      color: $color-primary;
    }
  }

  &__subtitle {
    font-family: $font-header;
    font-weight: 400;
    font-style: normal;
    padding-top: 25px;
    font-size: 25px;
    line-height: 31px;
    color: #333;
  }

  &__address {
    font-size: 16px;
    line-height: 140%;
    color: #727272;
    margin-top: 15px;

    &:before {}
  }

  &__phone-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-top: 30px;

    &>div {
      display: flex;
      flex-direction: column;
    }

    &:before {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;

      width: 36px;
      height: 36px;
      border-radius: 100px;
      background: url(../img/icons/phone.svg), linear-gradient(180deg, #FFE89E 0%, #FECC3C 100%);
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50px;

    }
  }

  &__phone {
    @extend .link;
    font-weight: 600;
    font-size: 18px;
    line-height: 152%;
    color: #11263A;
    white-space: nowrap;
  }

  &__callback {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #11263A;
  }

  &__work-time {
    font-size: 16px;
    margin-top: 15px;
  }

  &__button {
    @extend .button;
    @extend .button-1;
  }

  &__map {
    @include respond-to(md) {
      height: 550px;
      margin-top: 60px;
    }

    @include respond-from(md) {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
    }

    iframe {
      width: 100%;
      height: 100%;
    }

    &>div {
      height: 100%;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__bottom {
    margin-top: 30px;
  }
}