$header-text-color-light: #959fa4;
$header-text-color-dark: #29282D;

// JS-зависимые стили липкой десктопной шапки
.js-desktop-sticky-header {
  position: sticky;
  height: 0;
  top: -100px;
  left: 0;
  right: 0;
  z-index: -10;
  opacity: 0;

  transition: .3s ease-in-out;

  &--sticky {
    top: 0;
    opacity: 1;
    height: unset;
    z-index: 999;

    // То, что должно быть скрыто
    .header__hide-on-stick {
      display: none;
    }
  }
}

// JS-зависимые стили липкой мобильной шапки
.js-mobile-sticky-header {
  opacity: 0;
  transition: .2s ease-in-out;
  position: sticky;
  height: 0;
  top: -100px;
  left: 0;
  right: 0;
  z-index: -10;

  &--sticky {
    position: sticky !important;
    opacity: 1;
    top: 0;
    z-index: 999;

    // То, что должно быть скрыто
    .hide-on-stick {
      display: none;
    }
  }
}

// Десктопная шапка
.header {
  color: $header-text-color-light;
  font-size: 14px;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  // background: rgb(255, 255, 255);
  // background: linear-gradient(45deg, rgba(255, 255, 255, 0.700717787114846) 20%, rgba(255, 255, 255, 0) 80%);
  padding: 40px 0px;

  @include respond-to(md) {
    display: none;
  }

  &__grid {
    display: flex;
    flex-direction: row;
    gap: 40px;
    // align-items: flex-start;
    align-items: center;
    justify-content: space-between;
  }

  &__col {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;

    img {
      height: 90px;
      object-fit: contain;
      object-position: center;
      background-blend-mode: darken;

      @include respond-to(lg) {
        height: 40px;
      }
    }

    &-text {
      color: #29282D;
      font-size: 14px;
      font-weight: 500;
      max-width: 300px;
    }
  }

  &__title {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
    line-height: normal;
  }

  // Адрес
  &__address {
    display: flex;
    flex-direction: row;
    gap: 15px;
    font-size: 14px;
    line-height: 140%;
    white-space: nowrap;

    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #11263A;

    &:before {
      content: '';
      width: 22px;
      height: 22px;
      background-image: url(/img/icons/time.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  // Время работы
  &__work-time {
    display: flex;
    flex-direction: row;
    gap: 15px;
    font-size: 14px;
    line-height: 140%;
    white-space: nowrap;

    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #11263A;

    @include respond-to(lg) {
      display: none;
    }

    &:before {
      content: '';
      width: 22px;
      height: 22px;
      background-image: url(/img/icons/address.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__phone-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    &>div {
      display: flex;
      flex-direction: column;
    }

    &:before {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;

      width: 36px;
      height: 36px;
      border-radius: 100px;
      // background: url(../img/icons/phone.svg), linear-gradient(180deg, #FFE89E 0%, #FECC3C 100%);
      background: url(../img/icons/phone-white.svg), $gradient-button-third;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50px;

    }
  }

  &__phone {
    @extend .link;
    font-weight: 600;
    font-size: 18px;
    line-height: 152%;
    color: #11263A;
    white-space: nowrap;
  }

  // Кнопка обратного звонка
  &__callback {
    @extend .link;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #11263A;
    white-space: nowrap
  }

  &__button {
    @extend .button;
    @extend .button-29;
    font-size: 12px;
    padding: 0px 25px;
    font-weight: 500;
    height: 30px;
  }
}

// Липкая десктопная шапка
.sticky-header {
  &__wrapper {
    padding: 20px 0;
    border-radius: 0 0 25px 25px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  &__inner {
    padding: 10px 40px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    background-color: #fff;
    border-radius: 25px;
    transition: box-shadow .8s, padding .5s !important;
  }

  &__grid {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }

  &__logo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    gap: 3px;
    height: 60px;

    img {
      height: 40px;
      object-fit: contain;
      object-position: center;
    }
  }

  &__logo-desc {
    font-family: $font-decorative;
    color: #909090;
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
    display: block;
    line-height: 130%;

    .sticky & {
      font-size: 12px;
      line-height: 120%;
    }
  }

  &__phone {
    display: flex;
    align-items: center;
    font-family: $font-decorative;
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 22px;
    color: #29282D;
    transition: .2s ease-in-out;
    letter-spacing: 0.5px;

    &:hover {
      color: $color-primary;
    }

    &:before {
      content: '';
      display: block;
      background-image: url(../img/icons/handset-green.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 17px;
      height: 17px;
      margin-right: 3px;
    }
  }

  &__social-links {
    a {
      display: block;
      cursor: pointer;
      width: 25px;
      height: 25px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &__button {
    @extend .button;
    // @include button-bg($color-blue);
    border-radius: 8px;
    // font-weight: 500;
    font-size: 14px;
    color: #fff;
    padding: 13px 25px;

    .sticky & {
      font-size: 11px;
      padding: 10px 20px;
    }
  }
}

// Мобильная шапка
.mobile-header {
  // background-color: #fff;
  padding: 13px 15px;
  position: absolute;
  top: 0;
  z-index: 100;
  left: 0;
  right: 0;

  @include respond-from(md) {
    display: none;
  }

  &__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__hamburger {
    @include recolor(#989898);
    width: 25px;
    height: 25px;
    background-image: url(../img/icons/mobile-hamburger.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  &__logo {
    img {
      object-fit: contain;
      object-position: center;
      height: 60px;
      max-width: 140px;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 3px;
    align-items: center;
    justify-content: flex-start;
  }

  &__phone-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    &>div {
      display: flex;
      flex-direction: column;
    }

    &:before {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;

      width: 36px;
      height: 36px;
      border-radius: 100px;
      // background: url(../img/icons/phone.svg), linear-gradient(180deg, #FFE89E 0%, #FECC3C 100%);
      background: url(../img/icons/phone-white.svg),
        $gradient-button-third;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50px;

    }
  }

  &__phone {
    @extend .link;
    font-weight: 700;
    font-size: 18px;
    line-height: 152%;
    color: #11263A;
    white-space: nowrap;
  }

  &__callback {
    @extend .link;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #11263A;
  }


}

// Липкая мобильная шапка
.mobile-sticky-header {
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 15px 15px;
  background-color: #fff;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  // border-bottom: 2px solid #ededed;
  // border-radius: 0 0 20px 20px;

  @include respond-from(md) {
    display: none;
  }

  &__wrapper {}

  &__grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__hamburger {
    @include recolor(#989898);
    width: 25px;
    height: 25px;
    background-image: url(../img/icons/mobile-hamburger.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  &__first-col {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__logo {
    position: relative;
    top: -3px;

    img {
      height: 35px;
    }
  }

  &__phone {
    color: $color-primary;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }

  &__address {
    font-size: 11px;
    line-height: 130%;
    margin-top: 5px;
  }

  &__button {
    // color: $color-primary-dark;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    // background-color: $btn-yellow-gradient-color-1;
    padding: 10px;
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      width: 40px;
      height: 300px;
      margin-left: 60px;
      background: fade-out($color: #ffffff, $amount: .5);
      position: absolute;
      left: -100px;
      top: -160px;
      z-index: 1;
      transform: rotate(45deg);
      transition: all 0.1s;

      animation-name: slideme;
      animation-duration: 7s;
      animation-delay: 0.05s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }
}

// Липкая шапка
.js-sticky-header {
  transition: .2s ease-in-out;
  position: sticky;
  height: 0;
  top: -100px;
  left: 0;
  right: 0;
  z-index: 999;

  &--sticky {
    top: 0;

    // То, что должно быть скрыто
    .header__hide-on-stick {
      display: none;
    }
  }
}