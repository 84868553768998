$tile-background-default: #f5f7ff;
$tile-background-green: #f5f7ff;
$tile-background-red: #fbe9e7;

.tile {
  // background: $tile-background-default;
  background-color: fade-out($color: #000000, $amount: .97);
  padding: 40px 55px;
  border-radius: 15px;

  @include respond-to(md) {
    padding: 18px 15px;
  }

  @include respond-to(lg) {
    padding: 25px 20px;
  }

  &-white {
    background-color: #fff;

    &-shadow {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }

  &--green {
    background: $tile-background-green;
  }

  &--red {
    background: $tile-background-red;
  }

  &--color-6 {
    background: $color-5;
  }

  &--color-5 {
    background: $color-5;
  }

  &--color-4 {
    background: $color-4;
  }

  &--color-3 {
    background: $color-3;
  }

  &-outline {
    background: none;
    border: 2px solid #ebe3db;

  }
}

// Цветные обводки
@each $name,
$color in $colors {
  .tile-outline-#{$name} {
    background: none;
    border: 2px solid $color;
  }
}