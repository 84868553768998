.button-with-phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;

  @include respond-to(md) {
    flex-direction: column;
  }

  &__phone {
    font-weight: 600;
    font-size: 24px;
    line-height: 133%;
    color: #11263A;
    transition: .2s ease-in-out;
    white-space: nowrap;

    &:hover {
      color: $color-primary;
    }
  }

  &__phone-caption {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #404B4F;
    margin-bottom: 5px;
  }
}

.cta-1 {
  &__section-bg {
    @extend .base-section-bg;

    margin: 0 !important;
  }

  &__info {
    position: relative;
    z-index: 10;
  }

  &__right {
    @include respond-to(md) {
      display: none;
    }
  }

  &__img {
    img {
      border-radius: 15px;
    }
  }

  &__buttons {
    margin-top: 60px;
  }
}

.cta-1-specialist {
  &__info {}

  &__photo {
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 100%;
      background: rgb(255, 255, 255);
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
      height: 100px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}