.lead-text {
  font-size: 22px;
  font-weight: 400;
  line-height: 150%;

  @include respond-to(md) {
    font-size: 16px;
  }
}

// Группа заголовков
.headings-group {
  margin-bottom: 40px;
  width: 100%;

  @include respond-to(lg) {
    margin-bottom: 30px;
  }

  @include respond-to(md) {
    margin-bottom: 20px;
  }

  &-center {
    .title {
      text-align: center;
    }

    .subtitle {
      text-align: center;
    }
  }

  .title-with-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin: 20px 0 20px;

    @include respond-to(md) {
      margin: 10px 0 10px;
    }

    .title {
      margin: 0;
    }

    .icon {
      height: 40px;
    }
  }

  // Заголовок
  .title {
    color: #11263A;
    font-family: $font-decorative;
    font-size: 36px;
    // color: #3c4061;
    font-weight: 600;
    line-height: 130%;

    @include respond-to(lg) {
      font-size: 36px;
      // margin-bottom: 15px;
      margin: 15px 0 15px;
    }

    @include respond-to(md) {
      font-size: 26px;
      line-height: 135%;
      // margin-bottom: 10px;
      margin: 10px 0 10px;
    }

    &--center {
      text-align: center;
    }

    span {
      color: $color-primary;
    }
  }

  // Подзаголовок
  .subtitle {
    font-family: $font-decorative;
    // color: #6b748b;
    color: #727272;
    font-size: 21px;
    font-weight: 400;
    line-height: 170%;
    margin-top: 20px;
    
    span {
      color: $color-primary;
    }

    @include respond-to(lg) {
      font-size: 18px;
      line-height: 160%;
    }

    @include respond-to(md) {
      font-size: 16px;
      line-height: 150%;
    }

    &--large {
      font-size: 1.5rem;

      @include respond-to(lg) {
        font-size: 26px;
      }

      @include respond-to(md) {
        font-size: 24px;
      }
    }

    &--center {
      text-align: center;
    }
  }
}

// // Анимация ссылок
// a {
//     box-shadow: inset 0 0 0 0 #54b3d6;
//     color: #54b3d6;
//     padding: 0 .25rem;
//     margin: 0 -.25rem;
//     transition: color .3s ease-in-out, box-shadow .3s ease-in-out;

//     &:hover {
//         color: #fff;
//         box-shadow: inset 200px 0 0 0 #54b3d6;
//         ;
//     }
// }