@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/base/_01_normalize.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/base/_grid.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/base/_mixins.scss";

@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/global/_fonts.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/global/_global.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/global/_svg.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/global/_vars.scss";

@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/mixins/_colors.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/mixins/_responsive.scss";

@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/_local-fonts.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/base/_01_normalize.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/base/_grid.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/base/_mixins.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/global/_fonts.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/global/_global.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/global/_svg.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/global/_vars.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/mixins/_colors.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/mixins/_responsive.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_backgrounds.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_button.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_content.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_dialog.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_footer.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_forms.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_header.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_link.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_lists.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_price.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_section.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_shadows.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_swiper.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_text.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_tile.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/scss/partials/_typography.scss";


@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/about/_styles.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/advantages-2/_style.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/advantages/_style.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/awards/_style.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/before-after/_styles.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/contacts/_styles.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/cta-1/_style.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/employees/_styles.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/first-screen-2/_styles.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/first-screen/_styles.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/implant/_styles.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/independent-ratings/_style.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/price-table-tabs/_styles.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/price-table/_styles.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/prices/_styles.scss";
@import "/Users/denisarosenko/Documents/Работа/www_med_landings/__rl-ok-allon4/src/components/landing-blocks/steps/_style.scss";


@import 'partials/typography';

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent
}

.slick-list,
.slick-slider {
  position: relative;
  display: block
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0
}

.slick-list:focus {
  outline: none
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translateZ(0);
  transform: translateZ(0)
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: ""
}

.slick-track:after {
  clear: both
}

.slick-loading .slick-track {
  visibility: hidden
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px
}

[dir=rtl] .slick-slide {
  float: right
}

.slick-slide img {
  display: block
}

.slick-slide.slick-loading img {
  display: none
}

.slick-slide.dragging img {
  pointer-events: none
}

.slick-initialized .slick-slide {
  display: block
}

.slick-loading .slick-slide {
  visibility: hidden
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent
}

.slick-arrow.slick-hidden {
  display: none
}

.mfp-bg {
  z-index: 1042;
  overflow: hidden;
  background: #0b0b0b;
  opacity: .8
}

.mfp-bg,
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed
}

.mfp-wrap {
  z-index: 1043;
  outline: none !important;
  -webkit-backface-visibility: hidden
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle
}

.mfp-align-top .mfp-container:before {
  display: none
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045
}

.mfp-ajax-holder .mfp-content,
.mfp-inline-holder .mfp-content {
  width: 100%;
  cursor: auto
}

.mfp-ajax-cur {
  cursor: progress
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: zoom-out
}

.mfp-zoom {
  cursor: pointer;
  cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
  cursor: auto
}

.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
  -webkit-user-select: none;
  user-select: none
}

.mfp-loading.mfp-figure {
  display: none
}

.mfp-hide {
  display: none !important
}

.mfp-preloader {
  color: #ccc;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -.8em;
  left: 8px;
  right: 8px;
  z-index: 1044
}

.mfp-preloader a {
  color: #ccc
}

.mfp-preloader a:hover {
  color: #fff
}

.mfp-s-error .mfp-content,
.mfp-s-ready .mfp-preloader {
  display: none
}

button.mfp-arrow,
button.mfp-close {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation
}

button::-moz-focus-inner {
  padding: 0;
  border: 0
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: .65;
  padding: 0 0 18px 10px;
  color: #fff;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace
}

.mfp-close:focus,
.mfp-close:hover {
  opacity: 1
}

.mfp-close:active {
  top: 1px
}

.mfp-close-btn-in .mfp-close {
  color: #333
}

.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
  color: #fff;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #ccc;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap
}

.mfp-arrow {
  position: absolute;
  opacity: .65;
  top: 50%;
  margin: -55px 0 0;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent
}

.mfp-arrow:active {
  margin-top: -54px
}

.mfp-arrow:focus,
.mfp-arrow:hover {
  opacity: 1
}

.mfp-arrow:after,
.mfp-arrow:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: inset transparent
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: .7
}

.mfp-arrow-left {
  left: 0
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3f3f3f
}

.mfp-arrow-right {
  right: 0
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px
}

.mfp-iframe-holder .mfp-close {
  top: -40px
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, .6);
  background: #000
}

img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  box-sizing: border-box;
  padding: 40px 0;
  margin: 0 auto
}

.mfp-figure,
img.mfp-img {
  line-height: 0
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, .6);
  background: #444
}

.mfp-figure small {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 14px
}

.mfp-figure figure {
  margin: 0
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px
}

.mfp-image-holder .mfp-content {
  max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer
}

@media screen and (max-height:300px),
screen and (max-width:800px) and (orientation:landscape) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, .6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, .6);
    position: fixed;
    text-align: center;
    padding: 0
  }
}

@media (max-width:900px) {
  .mfp-arrow {
    -webkit-transform: scale(.75);
    transform: scale(.75)
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px
  }
}

.cropper-container {
  direction: ltr;
  font-size: 0;
  line-height: 0;
  position: relative;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.cropper-container img {
  display: block;
  height: 100%;
  image-orientation: 0deg;
  max-height: none !important;
  max-width: none !important;
  min-height: 0 !important;
  min-width: 0 !important;
  width: 100%
}

.cropper-canvas,
.cropper-crop-box,
.cropper-drag-box,
.cropper-modal,
.cropper-wrap-box {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0
}

.cropper-canvas,
.cropper-wrap-box {
  overflow: hidden
}

.cropper-drag-box {
  background-color: #fff;
  opacity: 0
}

.cropper-modal {
  background-color: #000;
  opacity: .5
}

.cropper-view-box {
  display: block;
  height: 100%;
  outline: 1px solid #39f;
  outline-color: rgba(51, 153, 255, .75);
  overflow: hidden;
  width: 100%
}

.cropper-dashed {
  border: 0 dashed #eee;
  display: block;
  opacity: .5;
  position: absolute
}

.cropper-dashed.dashed-h {
  border-bottom-width: 1px;
  border-top-width: 1px;
  height: 33.33333%;
  left: 0;
  top: 33.33333%;
  width: 100%
}

.cropper-dashed.dashed-v {
  border-left-width: 1px;
  border-right-width: 1px;
  height: 100%;
  left: 33.33333%;
  top: 0;
  width: 33.33333%
}

.cropper-center {
  display: block;
  height: 0;
  left: 50%;
  opacity: .75;
  position: absolute;
  top: 50%;
  width: 0
}

.cropper-center:after,
.cropper-center:before {
  background-color: #eee;
  content: " ";
  display: block;
  position: absolute
}

.cropper-center:before {
  height: 1px;
  left: -3px;
  top: 0;
  width: 7px
}

.cropper-center:after {
  height: 7px;
  left: 0;
  top: -3px;
  width: 1px
}

.cropper-face,
.cropper-line,
.cropper-point {
  display: block;
  height: 100%;
  opacity: .1;
  position: absolute;
  width: 100%
}

.cropper-face {
  background-color: #fff;
  left: 0;
  top: 0
}

.cropper-line {
  background-color: #39f
}

.cropper-line.line-e {
  cursor: ew-resize;
  right: -3px;
  top: 0;
  width: 5px
}

.cropper-line.line-n {
  cursor: ns-resize;
  height: 5px;
  left: 0;
  top: -3px
}

.cropper-line.line-w {
  cursor: ew-resize;
  left: -3px;
  top: 0;
  width: 5px
}

.cropper-line.line-s {
  bottom: -3px;
  cursor: ns-resize;
  height: 5px;
  left: 0
}

.cropper-point {
  background-color: #39f;
  height: 5px;
  opacity: .75;
  width: 5px
}

.cropper-point.point-e {
  cursor: ew-resize;
  margin-top: -3px;
  right: -3px;
  top: 50%
}

.cropper-point.point-n {
  cursor: ns-resize;
  left: 50%;
  margin-left: -3px;
  top: -3px
}

.cropper-point.point-w {
  cursor: ew-resize;
  left: -3px;
  margin-top: -3px;
  top: 50%
}

.cropper-point.point-s {
  bottom: -3px;
  cursor: s-resize;
  left: 50%;
  margin-left: -3px
}

.cropper-point.point-ne {
  cursor: nesw-resize;
  right: -3px;
  top: -3px
}

.cropper-point.point-nw {
  cursor: nwse-resize;
  left: -3px;
  top: -3px
}

.cropper-point.point-sw {
  bottom: -3px;
  cursor: nesw-resize;
  left: -3px
}

.cropper-point.point-se {
  bottom: -3px;
  cursor: nwse-resize;
  height: 20px;
  opacity: 1;
  right: -3px;
  width: 20px
}

@media (min-width:768px) {
  .cropper-point.point-se {
    height: 15px;
    width: 15px
  }
}

@media (min-width:992px) {
  .cropper-point.point-se {
    height: 10px;
    width: 10px
  }
}

@media (min-width:1200px) {
  .cropper-point.point-se {
    height: 5px;
    opacity: .75;
    width: 5px
  }
}

.cropper-point.point-se:before {
  background-color: #39f;
  bottom: -50%;
  content: " ";
  display: block;
  height: 200%;
  opacity: 0;
  position: absolute;
  right: -50%;
  width: 200%
}

.cropper-invisible {
  opacity: 0
}

.cropper-bg {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC")
}

.cropper-hide {
  display: block;
  height: 0;
  position: absolute;
  width: 0
}

.cropper-hidden {
  display: none !important
}

.cropper-move {
  cursor: move
}

.cropper-crop {
  cursor: crosshair
}

.cropper-disabled .cropper-drag-box,
.cropper-disabled .cropper-face,
.cropper-disabled .cropper-line,
.cropper-disabled .cropper-point {
  cursor: not-allowed
}

.tippy-popper[x-placement^=top] .tippy-tooltip.light-theme .tippy-arrow {
  border-top: 7px solid #fff;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.light-theme .tippy-arrow {
  border-bottom: 7px solid #fff;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent
}

.tippy-popper[x-placement^=left] .tippy-tooltip.light-theme .tippy-arrow {
  border-left: 7px solid #fff;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent
}

.tippy-popper[x-placement^=right] .tippy-tooltip.light-theme .tippy-arrow {
  border-right: 7px solid #fff;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent
}

.tippy-tooltip.light-theme {
  color: #26323d;
  -webkit-box-shadow: 0 0 20px 4px rgba(154, 161, 177, .15), 0 4px 80px -8px rgba(36, 40, 47, .25), 0 4px 4px -2px rgba(91, 94, 105, .15);
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, .15), 0 4px 80px -8px rgba(36, 40, 47, .25), 0 4px 4px -2px rgba(91, 94, 105, .15)
}

.tippy-tooltip.light-theme .tippy-backdrop {
  background-color: #fff
}

.tippy-tooltip.light-theme .tippy-roundarrow {
  fill: #fff
}

.tippy-tooltip.light-theme[data-animatefill] {
  background-color: transparent
}

.chunk-panel-container {
  position: relative
}

.chunk-panel__dropdown {
  position: absolute;
  left: 0;
  top: 20px;
  max-width: 318px;
  width: 100%;
  background-color: #333;
  border: 1px solid #525252;
  box-shadow: -5px 10px 18px rgba(0, 0, 0, .2);
  border-radius: 0 0 10px 0;
  z-index: 100;
  color: #fff;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease;
  padding: 9px 25px 25px
}

.chunk-panel__dropdown.open {
  -webkit-transform: translateX(0);
  transform: translateX(0)
}

.chunk-panel__dropdown.open .chunk-panel__burger {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  border-radius: 6px 0 0 6px
}

.chunk-panel__burger {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-self: end;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: calc(100% - 3px);
  width: 70px;
  height: 47px;
  cursor: pointer;
  background-color: #333;
  border: 1px solid #525252;
  border-right-color: transparent;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-image: url(../static/implant_dentalway__ru/build/i/753332d7abcf0673f413697ea7e7d010.svg);
  background-position: 50%;
  background-size: 32px 18px;
  background-repeat: no-repeat
}

.chunk-panel__title {
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-style: normal;
  color: #97c32e;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 25px;
  text-align: center
}

.chunk-panel__inner span {
  line-break: anywhere
}

.chunk-panel__inner span br {
  display: none
}

.chunk-panel__subtitle {
  font-family: Inter, sans-serif;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 10px
}

.chunk-panel__elem:not(:last-child) {
  margin-bottom: 8px
}

.chunk-panel__elem-title {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 11px
}

.chunk-panel__chunk {
  position: relative;
  padding-left: 22px
}

.chunk-panel__chunk:before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 0;
  background-image: url(../static/implant_dentalway__ru/build/i/2b6d1762d4c3d318482b83350e8ee769.svg);
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat
}

.chunk-panel__chunk [data-js-module=chunk]:not([data-js-module=chunk-image]):hover {
  background-color: rgba(151, 195, 46, .77);
  -webkit-animation: none;
  animation: none
}

.chunk-panel__block:not(:last-child) {
  margin-bottom: 16px
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box
}

:focus {
  outline: 0
}

ol,
ul {
  list-style: none
}

blockquote,
q {
  quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%
}

audio:not([controls]) {
  display: none;
  height: 0
}

[hidden] {
  display: none
}

a {
  color: inherit;
  text-decoration: none
}

a:focus {
  outline: thin dotted
}

a:active,
a:hover {
  outline: 0
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic
}

figure,
form {
  margin: 0
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle
}

button,
input {
  line-height: normal
}

button,
select {
  text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible
}

button[disabled],
html input[disabled] {
  cursor: default
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px
}

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0
}

textarea {
  overflow: auto;
  vertical-align: top
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

button,
html,
input,
select,
textarea {
  color: #222
}

::selection {
  background: #08abff;
  color: #fff;
  text-shadow: none
}

img {
  vertical-align: middle
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0
}

textarea {
  resize: vertical
}

.chromeframe {
  margin: .2em 0;
  background: #ccc;
  color: #000;
  padding: .2em 0
}

body.chunk-hide-popups .mfp-wrap:not(.chunk-modal) {
  display: none
}

.content.dev:before {
  z-index: 1000
}

.row {
  max-width: 1380px
}

@-webkit-keyframes info-shadow {
  20% {
    box-shadow: 0 0 5px #333
  }

  to {
    box-shadow: 0 0 100px #fff
  }
}

#adbar {
  -webkit-transform: translateX(999px);
  transform: translateX(999px)
}

@media screen and (max-width:1312px) {
  .row {
    padding: 0 45px
  }

  .info i {
    width: 12px;
    height: 12px
  }
}

.grecaptcha-badge {
  visibility: hidden
}

.re-captcha-notice {
  color: #333;
  font-size: 12px;
  line-height: 18px
}

.re-captcha-notice .link {
  color: #333
}

@media screen and (max-width:767px) {
  .re-captcha-notice {
    font-size: 10px;
    line-height: 14px
  }
}

.affiliates__grid,
.best-price__grid,
.best__award,
.best__grid,
.consultation__grid,
.diagnostic__button,
.diagnostic__grid,
.diagnostic__text,
.diagnostic__text>*,
.employee__about,
.employee__certificates,
.employee__info,
.error-block__grid,
.faq__item.shown,
.free-diagnostic__grid,
.fs__price__price,
.fs__prices,
.get-price__grid,
.guarantee__grid,
.map-wrapper__list,
.methods__grid,
.modal__employees,
.modal__grid,
.modal__instagram__grid,
.not-pain__grid,
.order__grid,
.order__item,
.order__item>*,
.plastic__employee-wrapper,
.plastic__grid,
.plastic__total,
.problem__images,
.problems__grid,
.question__grid,
.quiz__grid,
.quiz__pages__navigation,
.quiz__radio_images,
.quiz__radio_text_column,
.quote-success__grid,
.quote__calculation__button,
.quote__calculation__images,
.quote__grid,
.rating__slide__bottom,
.rating__slide__header,
.ratings .ratings__stars,
.ratings__grid,
.ratings__platforms,
.recovery__grid,
.result__item__grid,
.socials,
.text-block__grid,
.tomography__cases,
.tomography__grid {
  justify-content: flex-start;
  flex-wrap: wrap;
  display: flex
}

.quiz__radio_images>* {
  flex: 1 0 25%
}

#CalltouchWidgetFrame:not(.shown) {
  left: -1500px !important;
  opacity: 0 !important;
  visibility: hidden !important;
  width: 0 !important;
  height: 0 !important
}

.mobile-ct-widget {
  right: calc(50% - 54px);
  z-index: 1000000001;
  background: none;
  box-shadow: none;
  border-radius: 0;
  display: none;
  border: none;
  -webkit-filter: none;
  filter: none;
  -webkit-transform: none;
  transform: none;
  position: fixed;
  width: 108px;
  height: 108px;
  margin: 0;
  padding: 0;
  bottom: 0;
  top: auto;
  left: auto;
  zoom: 1
}

.mobile-ct-widget__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  border-radius: 90px;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  position: absolute;
  background-image: linear-gradient(208deg, #ff6f03, #ffad00);
  -webkit-animation: ct-button 1s ease 0s infinite alternate none running;
  animation: ct-button 1s ease 0s infinite alternate none running
}

.mobile-ct-widget__btn svg {
  fill: #fff;
  display: block;
  width: 32px;
  height: 32px
}

@-webkit-keyframes ct-button {
  0% {
    -webkit-transform: scale(.88) translateY(15px);
    transform: scale(.88) translateY(15px)
  }

  to {
    -webkit-transform: scale(1) translate(0);
    transform: scale(1) translate(0)
  }
}

@keyframes ct-button {
  0% {
    -webkit-transform: scale(.88) translateY(15px);
    transform: scale(.88) translateY(15px)
  }

  to {
    -webkit-transform: scale(1) translate(0);
    transform: scale(1) translate(0)
  }
}

.special-error-modal {
  display: none;
  position: relative;
  width: 100%;
  max-width: 595px;
  margin-right: auto;
  margin-left: auto
}

.mfp-content .special-error-modal {
  display: block
}

.special-error-modal .mfp-close {
  position: absolute;
  top: -32px !important;
  right: 0 !important;
  width: 22px;
  height: 22px;
  font-size: 0;
  background-image: url(../static/implant_dentalway__ru/build/i/dbba2ea1e59a97ed32dfac3f618e6cb8.svg);
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat
}

.special-error-modal__wrapper {
  background-color: #fff;
  border-radius: 10px;
  padding: 36px 34px 22px
}

.special-error-modal__top {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff5f5;
  border-radius: 5px;
  padding: 10px 10px 0 12px;
  margin-bottom: 26px
}

.special-error-modal__number {
  color: #f24e4e;
  font-size: 64px;
  line-height: 70px;
  margin-right: 33px
}

.special-error-modal__number,
.special-error-modal__title {
  font-family: Proxima, sans-serif;
  font-weight: 700;
  font-style: normal
}

.special-error-modal__title {
  font-size: 20px;
  line-height: 25px;
  color: #333;
  margin-right: 17px
}

.special-error-modal__title_mobile {
  display: none
}

.special-error-modal__accent {
  color: #f24e4e;
  margin-right: 6px
}

.special-error-modal__image {
  width: 96px;
  height: 67px;
  background-image: url(../static/implant_dentalway__ru/build/i/aaf54aaff27a847280dd6cde0cb3e887.png);
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  align-self: flex-end
}

.special-error-modal__middle {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px
}

.special-error-modal__subtitle {
  font-size: 22px;
  line-height: 27px;
  color: #333;
  margin-bottom: 14px
}

.special-error-modal__button,
.special-error-modal__subtitle {
  font-family: Proxima, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center
}

.special-error-modal__button {
  display: block;
  flex-shrink: 0;
  width: 300px;
  height: 73px;
  background: #f3b216;
  box-shadow: 0 8px 65px rgba(243, 178, 22, .15);
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2px;
  color: #fff;
  padding-top: 25px;
  padding-bottom: 24px;
  border-radius: 50px
}

.special-error-modal__bottom {
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-row-gap: 23px;
  row-gap: 23px;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 20px;
  max-width: 492px;
  margin-bottom: 21px;
  margin-right: auto;
  margin-left: auto
}

.special-error-modal__list {
  display: flex;
  justify-content: space-between;
  grid-column-gap: 22px;
  -webkit-column-gap: 22px;
  column-gap: 22px
}

.special-error-modal__item {
  font-family: Proxima, sans-serif;
  font-weight: 400;
  font-style: normal;
  position: relative;
  font-size: 18px;
  line-height: 22px;
  color: #333;
  padding-left: 27px
}

.special-error-modal__item:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 15px;
  height: 12px;
  background-image: url(../static/implant_dentalway__ru/build/i/101cc210fd0b49fed1a526c19db6b9ff.svg);
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat
}

.special-error-modal__item strong {
  font-family: Proxima, sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #333
}

.special-error-modal__time {
  font-family: Proxima, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  font-size: 16px;
  line-height: 23px;
  color: #8b8b8b
}

.special-error-modal__time-value {
  text-transform: lowercase
}

@media screen and (max-width:767px) {
  .fraud-alert #CalltouchWidgetFrame {
    left: -500px !important;
    opacity: 0 !important;
    visibility: hidden !important;
    width: 0 !important;
    height: 0 !important
  }

  .fraud-alert .mobile-ct-widget.active {
    display: block
  }

  .special-error-modal {
    padding-right: 10px;
    padding-left: 10px
  }

  .special-error-modal__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    padding: 10px 10px 24px
  }

  .special-error-modal__top {
    grid-column-gap: 27px;
    -webkit-column-gap: 27px;
    column-gap: 27px;
    width: 100%;
    padding-top: 0;
    margin-bottom: 14px
  }

  .special-error-modal__number {
    margin-right: 0
  }

  .special-error-modal__title_desktop {
    display: none
  }

  .special-error-modal__title_mobile {
    display: block
  }

  .special-error-modal__image {
    flex-shrink: 0;
    width: 96px;
    height: 67px
  }

  .special-error-modal__title {
    font-size: 18px;
    line-height: 23px;
    align-self: flex-start;
    padding-left: 12px
  }

  .special-error-modal__middle {
    padding: 16px 12px 23px;
    margin-bottom: 0
  }

  .special-error-modal__subtitle {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 14px;
    text-align: left;
    align-self: flex-start
  }

  .special-error-modal__bottom,
  .special-error-modal__list {
    flex-direction: column;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
    column-gap: 0;
    grid-row-gap: 10px;
    row-gap: 10px
  }

  .special-error-modal__bottom {
    align-items: flex-start;
    width: 100%;
    margin-bottom: 12px;
    padding-top: 10px;
    padding-bottom: 10px
  }

  .special-error-modal__button {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: 300px;
    min-width: 284px
  }
}

.link:hover>span,
.link_underline:hover>span {
  border-bottom-color: transparent
}

.link_dashed>span {
  transition: border .2s ease
}

.link_dashed:hover>span {
  border-bottom-color: transparent
}

.btn[data-js-cbs]:not(.loaded) {
  position: relative;
  -webkit-animation: disable_button 3s ease-in;
  animation: disable_button 3s ease-in
}

.btn[data-js-cbs]:not(.loaded):after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(../static/implant_dentalway__ru/build/i/fb35cf5e3a04cf98502a043fd075bc1f.svg);
  background-size: auto 75%;
  background-position: 50%;
  background-repeat: no-repeat;
  z-index: 3;
  opacity: 0;
  -webkit-filter: drop-shadow(0 0 5px hsla(0, 0%, 88.2%, .4));
  filter: drop-shadow(0 0 5px rgba(225, 225, 225, .4));
  -webkit-animation: loading 3s ease-in;
  animation: loading 3s ease-in
}

@-webkit-keyframes disable_button {
  0% {
    pointer-events: none;
    font-size: 0
  }

  to {
    pointer-events: none;
    font-size: 0
  }
}

@keyframes disable_button {
  0% {
    pointer-events: none;
    font-size: 0
  }

  to {
    pointer-events: none;
    font-size: 0
  }
}

@-webkit-keyframes loading {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  95% {
    opacity: 1
  }

  to {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
}

@keyframes loading {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  95% {
    opacity: 1
  }

  to {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }
}

.p-old {
  text-decoration: line-through
}

.p-new,
.p-old {
  white-space: nowrap
}

[data-js-chunk=moderate_phones] {
  display: inline-block !important
}

[data-js-module=chunk] {
  position: relative
}

[data-js-module=chunk-image],
[data-js-module=chunk] {
  cursor: pointer;
  z-index: 5
}

[data-js-module=chunk-image]:hover,
[data-js-module=chunk]:hover {
  z-index: 6
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
  }
}

@keyframes scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  to {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
  }
}

@-webkit-keyframes drop-shadow-animation {
  0% {
    -webkit-filter: drop-shadow(0 0 1px rgba(224, 54, 54, .8));
    filter: drop-shadow(0 0 1px rgba(224, 54, 54, .8))
  }

  50% {
    -webkit-filter: drop-shadow(0 0 3px rgba(224, 54, 54, .8));
    filter: drop-shadow(0 0 3px rgba(224, 54, 54, .8))
  }

  to {
    -webkit-filter: drop-shadow(0 0 1px rgba(224, 54, 54, .8));
    filter: drop-shadow(0 0 1px rgba(224, 54, 54, .8))
  }
}

@keyframes drop-shadow-animation {
  0% {
    -webkit-filter: drop-shadow(0 0 1px rgba(224, 54, 54, .8));
    filter: drop-shadow(0 0 1px rgba(224, 54, 54, .8))
  }

  50% {
    -webkit-filter: drop-shadow(0 0 3px rgba(224, 54, 54, .8));
    filter: drop-shadow(0 0 3px rgba(224, 54, 54, .8))
  }

  to {
    -webkit-filter: drop-shadow(0 0 1px rgba(224, 54, 54, .8));
    filter: drop-shadow(0 0 1px rgba(224, 54, 54, .8))
  }
}

@-webkit-keyframes chunk-image-animation {
  0% {
    -webkit-filter: sepia(0) saturate(2) drop-shadow(0 0 1px rgba(224, 54, 54, .8));
    filter: sepia(0) saturate(2) drop-shadow(0 0 1px rgba(224, 54, 54, .8))
  }

  50% {
    -webkit-filter: sepia(1) saturate(3) drop-shadow(0 0 3px rgba(224, 54, 54, .8));
    filter: sepia(1) saturate(3) drop-shadow(0 0 3px rgba(224, 54, 54, .8))
  }

  to {
    -webkit-filter: sepia(0) saturate(2) drop-shadow(0 0 1px rgba(224, 54, 54, .8));
    filter: sepia(0) saturate(2) drop-shadow(0 0 1px rgba(224, 54, 54, .8))
  }
}

@keyframes chunk-image-animation {
  0% {
    -webkit-filter: sepia(0) saturate(2) drop-shadow(0 0 1px rgba(224, 54, 54, .8));
    filter: sepia(0) saturate(2) drop-shadow(0 0 1px rgba(224, 54, 54, .8))
  }

  50% {
    -webkit-filter: sepia(1) saturate(3) drop-shadow(0 0 3px rgba(224, 54, 54, .8));
    filter: sepia(1) saturate(3) drop-shadow(0 0 3px rgba(224, 54, 54, .8))
  }

  to {
    -webkit-filter: sepia(0) saturate(2) drop-shadow(0 0 1px rgba(224, 54, 54, .8));
    filter: sepia(0) saturate(2) drop-shadow(0 0 1px rgba(224, 54, 54, .8))
  }
}

[data-js-module=chunk]:not([data-js-module=chunk-image]):hover {
  -webkit-animation: drop-shadow-animation 1.2s infinite;
  animation: drop-shadow-animation 1.2s infinite;
  z-index: 100;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background-color: rgba(255, 0, 0, .1)
}

[data-js-module=chunk-image] {
  -webkit-transform: scale(1);
  transform: scale(1)
}

[data-js-module=chunk-image]:hover {
  -webkit-animation: chunk-image-animation 2s infinite;
  animation: chunk-image-animation 2s infinite;
  z-index: 100;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards
}

.show-chunks [data-js-module=chunk]:not([data-js-module=chunk-image]) {
  -webkit-animation: drop-shadow-animation 1.2s infinite;
  animation: drop-shadow-animation 1.2s infinite;
  z-index: 100;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  background-color: rgba(255, 0, 0, .1)
}

.show-chunks [data-js-module=chunk-image] {
  -webkit-animation: chunk-image-animation 2s infinite;
  animation: chunk-image-animation 2s infinite;
  z-index: 100;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards
}

.chunk-flash {
  -webkit-animation-name: flash-animation;
  animation-name: flash-animation;
  -webkit-animation-duration: 5s;
  animation-duration: 5s
}

@-webkit-keyframes flash-animation {
  0% {
    background: #ff0
  }

  50% {
    background: #40dc43
  }

  to {
    background: transparent
  }
}

@keyframes flash-animation {
  0% {
    background: #ff0
  }

  50% {
    background: #40dc43
  }

  to {
    background: transparent
  }
}

.chunk-textarea {
  display: none
}

#chunk-modal {
  width: 80%;
  max-width: 80%
}

#chunk-modal .modal__wrapper {
  padding: 0;
  position: relative
}

#chunk-modal .mfp-close {
  background-color: #b94848;
  background-image: none !important;
  width: 44px;
  height: 44px;
  color: #fff;
  right: -29px;
  top: 35px
}

#chunk-modal .mfp-close:before {
  content: none
}

#chunk-modal .mfp-close:hover {
  background-color: #ec0e0e;
  opacity: 1
}

.chunk-change-image,
.chunk-save {
  background-color: #28c516;
  color: #000;
  right: -29px;
  top: 90px;
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  text-decoration: none;
  text-align: center;
  font-style: normal;
  font-size: 20px;
  overflow: visible;
  cursor: pointer;
  border: 0;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  outline: none;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation
}

.chunk-change-image:hover,
.chunk-save:hover {
  background-color: #6dd585
}

.chunk-change-image {
  background-color: #1141de;
  color: #fff;
  top: 150px
}

.chunk-change-image:hover {
  background-color: #344ba6
}

.chunk-change-image-input {
  position: absolute;
  opacity: 0;
  z-index: -1
}

.ace_editor {
  font: 16px/normal Fira Code Medium, Monaco, Menlo, Ubuntu Mono, Consolas, source-code-pro, monospace;
  border: 10px solid #7ecc68;
  line-height: 22px;
  overflow: visible;
  height: 500px;
  width: 100%
}

.ace_text-input {
  overflow: visible;
  overflow: initial
}

.chunk-image-cropper img {
  display: block;
  max-width: 100%
}

.chunk-image-loader img {
  -webkit-filter: grayscale(70%);
  filter: grayscale(70%)
}

.chunk-image-loader:after {
  content: "";
  -webkit-animation: loader .8s cubic-bezier(0, 0, .03, .9) infinite;
  animation: loader .8s cubic-bezier(0, 0, .03, .9) infinite;
  background-color: rgba(0, 0, 0, .5);
  box-sizing: border-box;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10
}

@-webkit-keyframes loader {

  0%,
  44%,
  88.1%,
  to {
    -webkit-transform-origin: left;
    transform-origin: left
  }

  0%,
  88%,
  to {
    -webkit-transform: scaleX(0);
    transform: scaleX(0)
  }

  44.1%,
  88% {
    -webkit-transform-origin: right;
    transform-origin: right
  }

  33%,
  44% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

@keyframes loader {

  0%,
  44%,
  88.1%,
  to {
    -webkit-transform-origin: left;
    transform-origin: left
  }

  0%,
  88%,
  to {
    -webkit-transform: scaleX(0);
    transform: scaleX(0)
  }

  44.1%,
  88% {
    -webkit-transform-origin: right;
    transform-origin: right
  }

  33%,
  44% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

.modal.modal-error {
  max-width: 410px;
  min-height: 340px;
  padding: 0
}

.modal.modal-error .mfp-close {
  right: 10px;
  top: 8px
}

.modal.modal-error .mfp-close:before {
  background-image: url(../static/implant_dentalway__ru/build/i/bb77f42d71d5510e6fb3570b52879367.svg)
}

.modal-error__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 36px
}

.modal-error__title {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center
}

.modal-error__text {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 24px
}

.modal-error__text a {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  display: block;
  font-size: 18px;
  line-height: 23px;
  color: $color-primary;
  margin-top: 4px
}

.modal-error__icon {
  margin-bottom: 24px;
  max-width: 86px
}

.modal-error__icon svg {
  width: 100%
}

.modal-error__icon path {
  fill: $color-primary
}

@media screen and (max-width:1023px) {
  .modal.modal-error {
    max-width: 348px;
    min-height: 330px
  }

  .modal-error__icon {
    max-width: 80px;
    margin-bottom: 24px
  }

  .modal-error__title {
    font-size: 18px;
    line-height: 26px
  }

  .modal-error__text {
    margin-top: 12px
  }
}

@media screen and (max-width:767px) {
  .modal.modal-error {
    max-width: 288px;
    min-height: 285px
  }

  .modal-error__icon {
    max-width: 60px;
    margin-bottom: 12px
  }

  .modal-error__title {
    font-size: 16px;
    line-height: 22px
  }

  .modal-error__text {
    font-size: 14px;
    line-height: 20px
  }

  .modal-error__text a {
    font-size: 16px;
    line-height: 20px
  }

  .modal-error__content {
    padding-right: 15px;
    padding-left: 15px
  }
}

.mfp-wrap.chunk-modal {
  z-index: 999999
}

#chunk-modal [data-js=ace-toggle-fullscreen] {
  background-image: url(../static/implant_dentalway__ru/build/i/a3dda20063b7422650533a4596811a38.svg);
  background-size: 20px 20px;
  position: absolute;
  right: 29px;
  top: 11px;
  width: 20px;
  height: 20px;
  opacity: .5;
  z-index: 2
}

#chunk-modal [data-js=ace-toggle-fullscreen]:hover {
  opacity: 1
}

#chunk-modal.fullscreen {
  position: fixed !important;
  height: auto;
  width: 100%;
  max-width: 100%;
  border: 0;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  padding: 0 44px 0 0
}

#chunk-modal.fullscreen .modal__wrapper {
  height: 100%
}

#chunk-modal.fullscreen .chunk-save {
  right: 0;
  top: 56px
}

#chunk-modal.fullscreen .mfp-close {
  right: 0;
  top: 0
}

#chunk-modal.fullscreen .ace_editor {
  height: 100%
}

#chunk-modal.fullscreen [data-js=ace-toggle-fullscreen] {
  background-image: url(../static/implant_dentalway__ru/build/i/a6856599752e4223a3c37f651c769cf8.svg);
  position: fixed;
  right: 12px;
  top: 12px
}

#chunk-modal.fullscreen .ace-submodal {
  top: 10px;
  height: calc(100% - 35px)
}

.ace-editor__panel {
  background-color: #bec2c7;
  height: auto;
  padding: 5px 5px 2px
}

.ace-editor__panel.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0
}

.ace-panel__icon {
  border: 1px solid #abaaaa;
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 32px;
  height: 32px
}

.ace-panel__icon:hover {
  box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, .2)
}

.ace-panel__icon:before {
  content: "";
  background-size: contain;
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  top: 8px;
  left: 8px
}

.ace-panel__icon_image:before {
  background-image: url(../static/implant_dentalway__ru/build/i/e49b9ef75a62845fd323f801772359af.svg)
}

.ace-panel__icon_video:before {
  background-image: url(../static/implant_dentalway__ru/build/i/440c2af985753efd2abd1c18d97c38b0.svg)
}

.ace-panel__icon_link-to-page:before {
  background-image: url(../static/implant_dentalway__ru/build/i/d8a363c05734ca3cb1d3e9e59e7f6cae.svg)
}

.ace-submodal {
  position: absolute;
  left: 10px;
  top: 53px;
  width: 50%;
  height: calc(100% - 78px);
  z-index: 10;
  background-color: #ecf0f5;
  padding: 15px 60px 20px 20px;
  box-shadow: 5px 0 5px 2px rgba(0, 0, 0, .1)
}

.ace-submodal fieldset {
  margin-top: 5px
}

.ace-submodal label {
  display: inline-block;
  font-size: 16px;
  margin-bottom: 5px
}

.ace-submodal [type=text] {
  background-color: #fefefe;
  border: 1px solid #cacaca;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, .1);
  box-sizing: border-box;
  color: #0a0a0a;
  display: block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: .5rem;
  transition: box-shadow .5s, border-color .25s ease-in-out;
  width: 100%
}

.ace-submodal [type=text]:focus {
  border: 1px solid #8a8a8a;
  box-shadow: 0 0 5px #cacaca
}

.ace-submodal .select {
  width: 100%;
  display: block;
  height: 2.4375rem;
  margin: 0 0 1rem;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-size: 9px 6px;
  padding: .5rem 1.5rem .5rem .5rem;
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat
}

.ace-submodal .select,
.ace-submodal .select:focus {
  background-color: #fff;
  transition: box-shadow .5s, border-color .25s ease-in-out
}

.ace-submodal .select:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  box-shadow: 0 0 5px #cacaca
}

.ace-submodal .modal__button {
  background-color: #1779ba;
  border: 1px solid transparent;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  padding: .85em 1em;
  display: flex
}

.ace-submodal__close {
  position: absolute;
  right: 0;
  top: 0;
  width: 30px;
  height: 30px;
  font-size: 34px;
  line-height: 34px;
  background-color: #bec2c7;
  display: flex;
  align-content: center;
  justify-content: center
}

.ace-submodal__close:hover {
  background-color: #babcbf
}

.ace-submodal__alert {
  margin-top: 15px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 6px
}

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: pointer
}

.scroll-to-top {
  position: fixed;
  right: 35px;
  bottom: 115px;
  z-index: 1000;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  width: 59px;
  height: 59px;
  border: 1px solid #c6d8e7;
  border-radius: 50%;
  background-color: hsla(0, 0%, 100%, .2);
  background-image: url(../static/implant_dentalway__ru/build/i/5e3ab859e8031de07c7d0b9528a405b8.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  transition: all .2s ease
}

.scroll-to-top.scroll-to-top_shown {
  opacity: 1;
  visibility: visible
}

.scroll-to-top:hover {
  opacity: .7
}

@media screen and (max-width:1312px) {
  .scroll-to-top {
    width: 50px;
    height: 50px;
    right: 15px;
    bottom: 157px;
    background-size: 16px 13px
  }
}

@media screen and (max-width:767px) {
  .scroll-to-top {
    right: auto;
    left: 15px;
    bottom: 50px
  }
}

.tippy-popper[x-placement^=left] .tippy-arrow {
  width: 14px;
  height: 30px
}

.tippy-popper[x-placement^=left] [data-js-tooltip=arrow] {
  width: 14px;
  height: 30px;
  top: -7px
}

.tippy-popper[x-placement^=left] [data-js-tooltip=arrow]:before {
  border-width: 15px 0 15px 14px;
  border-color: transparent transparent transparent #fff;
  left: 0
}

.tippy-popper[x-placement^=left] [data-js-tooltip=arrow]:after {
  border-width: 16px 0 16px 15px;
  border-color: transparent transparent transparent #e0e3e4
}

.tippy-popper[x-placement^=right] .tippy-arrow {
  width: 14px;
  height: 30px
}

.tippy-popper[x-placement^=right] [data-js-tooltip=arrow] {
  width: 14px;
  height: 30px;
  top: -7px
}

.tippy-popper[x-placement^=right] [data-js-tooltip=arrow]:before {
  border-width: 15px 14px 15px 0;
  border-color: transparent #fff transparent transparent;
  right: -1px;
  top: 0
}

.tippy-popper[x-placement^=right] [data-js-tooltip=arrow]:after {
  border-width: 15px 14px 15px 0;
  border-color: transparent #e0e3e4 transparent transparent
}

.input_phone_check {
  display: none !important
}

@-webkit-keyframes shake {

  10%,
  90% {
    -webkit-transform: translateX(-1px);
    transform: translateX(-1px)
  }

  20%,
  80% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px)
  }

  30%,
  50%,
  70% {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px)
  }

  40%,
  60% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px)
  }
}

@media screen and (max-width:1312px) {
  .form__flex {
    margin-top: 18px
  }

  .form__flex .button_submit {
    max-width: 290px
  }
}

@media screen and (max-width:1023px) {
  .form__flex {
    margin-top: 13px
  }

  .form__flex .form__group {
    max-width: 189px;
    margin-right: 14px
  }

  .form__flex .form__group:nth-child(2) {
    margin-right: 0;
    max-width: 100%
  }
}

@media screen and (max-width:767px) {
  .form__flex {
    flex-wrap: wrap
  }

  .form__flex .form__group {
    max-width: 100%;
    margin-right: 0
  }

  .form__flex .input {
    height: 60px
  }

  .form__flex>* {
    flex-basis: 100% !important
  }

  .form__flex .button_submit {
    height: 60px;
    max-width: 242px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 17px
  }
}

[data-js=tabs-nav] {
  position: relative;
  z-index: 1
}

[data-js=tabs-nav]>* {
  color: #5a6263;
  font-size: 16px;
  line-height: 16px;
  transition: color .2s ease-in-out
}

[data-js=tabs-nav]>.active,
[data-js=tabs-nav]>:hover {
  color: #364344
}

[data-js=tabs-content] {
  position: relative;
  z-index: 2
}

[data-js=tabs-content]>* {
  display: none
}

[data-js=tabs-content]>.active {
  display: block
}

.discount-duration {
  font-style: italic;
  font-size: 14px;
  background-color: #e2eeff;
  box-shadow: 0 20px 30px -20px rgba(0, 0, 0, .08);
  border-radius: 20px;
  padding: 4px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content
}

.discount-duration span {
  color: #bd3625;
  font-weight: 500
}

.map-loader {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1
}

.map-loader svg {
  -webkit-animation: rotate_loader 2s linear infinite;
  animation: rotate_loader 2s linear infinite;
  height: 150px;
  width: 150px;
  margin: auto;
  position: relative;
  stroke: $color-primary;
  -webkit-transform-origin: center center;
  transform-origin: center center
}

.map-loader svg circle {
  -webkit-animation: dash_loader 1.5s ease-in-out infinite;
  animation: dash_loader 1.5s ease-in-out infinite;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  background: #fff;
  color: #fff
}

@-webkit-keyframes rotate_loader {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@keyframes rotate_loader {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}

@-webkit-keyframes dash_loader {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35
  }

  to {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124
  }
}

@keyframes dash_loader {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35
  }

  to {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124
  }
}

html {
  font-family: $font-decorative !important;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #333;
  margin-right: 0 !important
}

a {
  outline: none !important
}

img {
  max-width: 100%;
  height: auto
}

.nobr {
  white-space: nowrap
}

.info {
  display: flex;
  justify-content: center
}

.info i {
  width: 14px;
  height: 14px;
  display: block;
  border-radius: 50%
}

.info i:before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  background-size: contain;
  background-position: 50%
}

.info i:hover {
  -webkit-animation: info-shadow .5s;
  animation: info-shadow .5s
}

.grid__column {
  width: 100%;
  max-width: 630px
}

@keyframes info-shadow {
  20% {
    box-shadow: 0 0 5px #333
  }

  to {
    box-shadow: 0 0 100px #fff
  }
}

.grey-block {
  background-color: $color-primary;
  overflow: hidden
}

.lower {
  text-transform: lowercase
}

[data-js-scroll-to] {
  cursor: pointer
}

@media screen and (max-width:1312px) {
  .row {
    padding: 0 47px
  }

  .info i {
    width: 12px;
    height: 12px
  }
}

@media screen and (max-width:1023px) {
  .row {
    padding: 0 21px
  }
}

@media screen and (max-width:767px) {
  .row {
    padding: 0 16px
  }
}

// кнопка
.btn {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  border-radius: $button-default-border-radius;
  background-position: 50%;
  text-transform: uppercase;
  font-size: 16px;
  color: #fff;
  background-size: 100%;
  transition: background .3s;
  border: none;
  height: 66px;
  width: 100%;
  max-width: 353px;
  display: block;
  position: relative;
  z-index: 1;
  cursor: pointer;

  &>span {
    position: relative;
    z-index: 1;

    &.btn__bg {
      // background-image: linear-gradient(222.59deg, #e75a3c 2.98%, #f0b050 99.12%);
      background-image: $gradient-button-primary;
      z-index: 0
    }
  }
}

.btn>span.btn__bg,
.btn>span.btn__bg:after,
.btn>span.btn__bg:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: $button-default-border-radius;
}

.btn>span.btn__bg:after,
.btn>span.btn__bg:before {
  content: "";
  z-index: 1;
  transition: .2s ease;
  opacity: 0;
}

.btn {
  &>span {
    &.btn__bg {

      // цвет тиснения для кнопки при наведении
      &:before {
        background: $gradient-button-primary-embossing;
        transform: translateY(3px);
      }

      // цвет кнопки при наведении
      &:after {
        // background: linear-gradient(222.59deg, #f14721 2.98%, #f4a226 99.12%);
        background: $gradient-button-primary-hover;
      }
    }

    &.btn__flash {
      position: absolute;
      right: 60px;
      top: 50%;
      -webkit-transform: translateY(-50%) rotate(-30deg);
      transform: translateY(-50%) rotate(-30deg);
      width: 37.68px;
      height: 99px;
      background: hsla(0, 0%, 100%, .3);
      -webkit-filter: blur(25px);
      filter: blur(25px);
    }

    &:not(.btn__bg):not(.btn__flash) {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      overflow: hidden;
      padding-top: 2px;
    }

    &:not(.btn__bg):not(.btn__flash):before {
      content: "";
      position: absolute;
      top: -30%;
      right: -10%;
      bottom: -30%;
      left: -10%;
      background: hsla(0, 0%, 100%, .5);
      -webkit-filter: blur(25px);
      filter: blur(25px);
      border-radius: $button-default-border-radius;
      -webkit-transform: rotate(60deg) translate(-10em, 20em);
      transform: rotate(60deg) translate(-10em, 20em);
      transition: -webkit-transform .3s;
      transition: transform .3s;
      transition: transform .3s, -webkit-transform .3s;
      -webkit-animation: btn-blink 6s infinite;
      animation: btn-blink 6s infinite;
      z-index: -1;
    }
  }

  &:hover {
    &:after {
      -webkit-animation: btn-hover 3s infinite;
      animation: btn-hover 3s infinite;
    }
  }

  &:active {
    &>span {
      &.btn__bg {
        &:before {
          opacity: 0;
        }

        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.btn:hover>span.btn__bg:after,
.btn:hover>span.btn__bg:before {
  opacity: 1;
}

.btn[disabled] {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}


@-webkit-keyframes btn-hover {
  0% {
    -webkit-transform: rotate(60deg) translate(-10em, 16em);
    transform: rotate(60deg) translate(-10em, 16em);
    opacity: 1
  }

  20% {
    -webkit-transform: rotate(60deg) translate(5em, -17em);
    transform: rotate(60deg) translate(5em, -17em);
    opacity: 1
  }

  99% {
    -webkit-transform: rotate(60deg) translate(5em, -17em);
    transform: rotate(60deg) translate(5em, -17em)
  }

  to {
    -webkit-transform: rotate(60deg) translate(-10em, 16em);
    transform: rotate(60deg) translate(-10em, 16em);
    opacity: 0
  }
}

@keyframes btn-hover {
  0% {
    -webkit-transform: rotate(60deg) translate(-10em, 16em);
    transform: rotate(60deg) translate(-10em, 16em);
    opacity: 1
  }

  20% {
    -webkit-transform: rotate(60deg) translate(5em, -17em);
    transform: rotate(60deg) translate(5em, -17em);
    opacity: 1
  }

  99% {
    -webkit-transform: rotate(60deg) translate(5em, -17em);
    transform: rotate(60deg) translate(5em, -17em)
  }

  to {
    -webkit-transform: rotate(60deg) translate(-10em, 16em);
    transform: rotate(60deg) translate(-10em, 16em);
    opacity: 0
  }
}

@-webkit-keyframes btn-blink {
  0% {
    -webkit-transform: rotate(60deg) translate(-10em, 16em);
    transform: rotate(60deg) translate(-10em, 16em);
    opacity: 1
  }

  10% {
    -webkit-transform: rotate(60deg) translate(5em, -17em);
    transform: rotate(60deg) translate(5em, -17em);
    opacity: 1
  }

  99% {
    -webkit-transform: rotate(60deg) translate(5em, -17em);
    transform: rotate(60deg) translate(5em, -17em)
  }

  to {
    -webkit-transform: rotate(60deg) translate(-10em, 16em);
    transform: rotate(60deg) translate(-10em, 16em);
    opacity: 0
  }
}

@keyframes btn-blink {
  0% {
    -webkit-transform: rotate(60deg) translate(-10em, 16em);
    transform: rotate(60deg) translate(-10em, 16em);
    opacity: 1
  }

  10% {
    -webkit-transform: rotate(60deg) translate(5em, -17em);
    transform: rotate(60deg) translate(5em, -17em);
    opacity: 1
  }

  99% {
    -webkit-transform: rotate(60deg) translate(5em, -17em);
    transform: rotate(60deg) translate(5em, -17em)
  }

  to {
    -webkit-transform: rotate(60deg) translate(-10em, 16em);
    transform: rotate(60deg) translate(-10em, 16em);
    opacity: 0
  }
}

.btn_arrow>span:not(.btn__bg)>i {
  padding-right: 24px;
  position: relative
}

.btn_arrow>span:not(.btn__bg)>i:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 14px;
  height: 8px;
  margin-top: -5px;
  background-image: url(../static/implant_dentalway__ru/build/i/c03db5752b424ea7971159db75d3968e.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%
}

.btn_arrow.loading>span:not(.btn__bg)>i:after {
  display: none
}

.btn_play {
  display: block;
  width: 73px;
  height: 73px;
  border-radius: 50%;
  position: relative;
  z-index: 1
}

.btn_play:before {
  content: "";
  z-index: -1;
  background: linear-gradient(222.59deg, #e75a3c 2.98%, #f0b050 99.12%);
  -webkit-filter: blur(25px);
  filter: blur(25px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite
}

.btn_play:before,
.btn_play>span {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50%
}

.btn_play>span {
  background: linear-gradient(176deg, #e75a3c 2.98%, #f0b050 99.12%)
}

.btn_play>span:after,
.btn_play>span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 1;
  transition: .2s ease;
  opacity: 0
}

.btn_play>span:before {
  background: linear-gradient(222.59deg, #c47717 2.98%, #bd4816 99.12%);
  -webkit-transform: translateY(3px);
  transform: translateY(3px)
}

.btn_play>span:after {
  background: linear-gradient(222.59deg, #f14721 2.98%, #f4a226 99.12%)
}

.btn_play>span>i {
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url(../static/implant_dentalway__ru/build/i/9fe3db423b3532635582ee9b639007b2.svg);
  background-repeat: no-repeat;
  background-position: center left 28px;
  background-size: 21px 28px;
  position: relative;
  z-index: 2;
  border-radius: 50%;
  overflow: hidden
}

.btn_play>span>i:before {
  content: "";
  position: absolute;
  width: 27px;
  height: 70px;
  top: -10px;
  right: 4px;
  background: hsla(0, 0%, 100%, .5);
  -webkit-filter: blur(25px);
  filter: blur(25px);
  border-radius: 50px;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg)
}

.btn_play:hover>span:after,
.btn_play:hover>span:before {
  opacity: 1
}

.btn_play:active>span:before {
  opacity: 0
}

.btn_play:active>span:after {
  opacity: 1
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(.6);
    transform: scale(.6);
    opacity: 1
  }

  60% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
  }

  80% {
    opacity: 0
  }

  to {
    -webkit-transform: scale(.6);
    transform: scale(.6);
    opacity: 0
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(.6);
    transform: scale(.6);
    opacity: 1
  }

  60% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
  }

  80% {
    opacity: 0
  }

  to {
    -webkit-transform: scale(.6);
    transform: scale(.6);
    opacity: 0
  }
}

.btn_back {
  max-width: 150px
}

.btn_back>span.btn__bg {
  background: linear-gradient(222.59deg, #d6d6d6 2.98%, #989898 99.12%)
}

.btn_back>span.btn__bg:before {
  background: linear-gradient(222.59deg, #9c9c9c 2.98%, #5e5e5e 99.12%)
}

.btn_back>span.btn__bg:after {
  background: linear-gradient(222.59deg, #b0b0b0 2.98%, #777 99.12%)
}

.btn_back>span:not(.btn__bg)>i {
  position: relative;
  padding-left: 24px
}

.btn_back>span:not(.btn__bg)>i:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 14px;
  height: 8px;
  margin-top: -5px;
  background-image: url(../static/implant_dentalway__ru/build/i/c03db5752b424ea7971159db75d3968e.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1)
}

@media screen and (max-width:1312px) {
  .btn {
    max-width: 295px
  }

  .btn_back {
    max-width: 150px
  }
}

@media screen and (max-width:1023px) {
  .btn {
    height: 50px;
    max-width: 202px;
    font-size: 14px
  }

  .btn_back {
    max-width: 129px
  }
}

@media screen and (max-width:767px) {
  .btn {
    font-size: 12px;
    max-width: 210px
  }

  .btn_back {
    max-width: 106px
  }

  .btn_play {
    width: 60px;
    height: 60px
  }

  .btn_play>span>i {
    background-position: center left 24px;
    background-size: 17px 23px
  }
}

// H1 заголовок
.h1 {
  font-family: $font-header;
  font-weight: 700;
  font-style: normal;
  font-size: 42px;
  line-height: 120%;
  color: #333;
  position: relative;

  // // вертикальная полоска
  // padding-left: 30px;

  // &:before {
  //   content: "";
  //   position: absolute;
  //   background-color: $color-primary;
  //   left: 0;
  //   top: 3px;
  //   width: 5px;
  //   height: calc(100% - 12px);
  //   border-radius: 5px
  // }

  &>span {
    color: $color-primary
  }

  &_white {
    color: #fff
  }
}

// H2 заголовки
.h2 {
  // font-family: $font-geometria;
  font-family: $font-header;
  font-weight: 700;
  font-style: normal;
  color: #333;
  font-size: 38px;
  // line-height: 48px;
  line-height: 120%;
  margin-bottom: 15px;
  position: relative;

  // Вертикальная линия
  // padding-left: 30px;
  // &:before {
  //   content: "";
  //   position: absolute;
  //   background-color: $color-primary;
  //   left: 0;
  //   top: 3px;
  //   width: 5px;
  //   height: calc(100% - 12px);
  //   border-radius: 5px;
  // }

  &>span {
    color: $color-primary
  }

  &_center {
    text-align: center;
  }

  &_white {
    color: #fff;

    &>span {
      color: $color-primary;
    }

    &:before {
      background-color: #fff;
    }
  }

  &_bg {
    &>span {
      background: linear-gradient(176deg, $color-primary, $color-primary 32.29%, #fff);
      border-radius: 5px;
      padding: 1px 0 0 7px;
      margin-left: -7px;
      color: #fff;
    }

    &:before {
      content: none;
    }
  }

  &_bg-part {
    background: linear-gradient(176deg, $color-primary, $color-primary 32.29%, #fff);
    border-radius: 5px;
    padding: 2px 8px 0 7px;
    margin-left: -7px;
    color: #fff;
    display: inline-block;

    &>span {
      background: none;
    }
  }
}

.h3 {
  font-weight: 700;
  font-style: normal;
  font-size: 24px
}

.block-header__text,
.h3 {
  font-family: $font-geometria
}

.block-header__text {
  font-weight: 400;
  font-style: italic;
  font-size: 26px;
  line-height: 32px
}

.block-header__text_small {
  font-size: 22px;
  line-height: 28px
}

.block-header {
  position: relative;
  // padding-left: 22px;
}

.block-header .h2 {
  padding-left: 0;
  border: 0;
  margin-bottom: 0
}

.block-header .h2:before {
  content: none
}

.block-header .block-header__text {
  display: inline-block;
  // background-color: $color-primary;
  // color: #fff;
  color: #444;
  border-radius: 5px;
  margin-left: -2px;
  // padding: 3px 10px;
  padding: 3px 0;
  margin-top: 11px;
}

.block-header .block-header__text_small {
  margin-top: 6px;
  padding: 5px 10px
}

// .block-header:before {
//   content: "";
//   position: absolute;
//   left: 0;
//   top: 7px;
//   width: 5px;
//   height: calc(100% - 6px);
//   background: $color-primary;
//   border-radius: 5px
// }

.block-header .block-header__text_nobg {
  background: none;
  color: #333;
  padding: 0;
  margin-left: 0
}

.header_white {
  color: #fff
}

.h2_mobile {
  display: none
}

@media screen and (max-width:1312px) {
  .h1 {
    font-size: 32px;
    line-height: 40px
  }

  .h1>span {
    padding: 4px 5px 1px 7px
  }

  .h2 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 18px
  }

  .h2:before {
    height: calc(100% - 9px)
  }

  .h2_bg-part {
    line-height: 30px
  }

  .h2_small {
    font-size: 28px;
    line-height: 40px
  }

  .block-header__text {
    font-size: 24px;
    line-height: 30px
  }

  .block-header__text_small {
    font-size: 22px;
    line-height: 28px
  }

  .block-header {
    position: relative;
    padding-left: 19px
  }

  .block-header .block-header__text {
    font-family: $font-geometria;
    font-weight: 500;
    font-style: italic;
    margin-left: 0;
    padding: 0 7px;
    margin-top: 9px
  }

  .block-header .block-header__text_small {
    margin-top: 9px;
    padding: 0 8px 1px 7px
  }

  .block-header:before {
    top: 3px;
    height: 100%
  }
}

@media screen and (max-width:1023px) {
  .h1 {
    font-size: 32px;
    line-height: 40px
  }

  .h2 {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 13px;
    padding-left: 15px
  }

  .h2_bg-part {
    background: none
  }

  .h2_bg-part>span {
    background: linear-gradient(176deg, $color-primary, $color-primary 32.29%, #fff);
    padding-right: 7px
  }

  .h3 {
    line-height: 30px
  }

  .block-header__text,
  .h3 {
    font-size: 22px
  }

  .block-header__text_small {
    font-size: 18px;
    line-height: 23px
  }

  .block-header {
    position: relative;
    padding-left: 13px
  }

  .block-header .block-header__text {
    line-height: 23px
  }

  .block-header .block-header__text_small {
    margin-top: 9px;
    padding: 0 8px 1px 7px
  }

  .block-header:before {
    top: 3px;
    height: 100%
  }
}

@media screen and (max-width:767px) {
  .h1 {
    font-size: 22px;
    line-height: 28px
  }

  .h1>span {
    padding-left: 5px;
    margin-left: -5px
  }

  .h1:before {
    content: none
  }

  .h2 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
    padding-left: 0
  }

  .h2:before {
    content: none
  }

  .h2_center {
    text-align: left
  }

  .h2_bg-part {
    background: linear-gradient(176deg, $color-primary, $color-primary 32.29%, #fff);
    margin-left: -7px;
    padding: 0 7px 0 14px
  }

  .h2_bg-part>span {
    background: none;
    padding: 0
  }

  .h2_bg-part>span:first-child {
    margin-right: 5px
  }

  .block-header__text {
    font-size: 18px;
    line-height: 23px;
    text-align: left
  }

  .block-header__text_small {
    font-size: 14px;
    line-height: 18px
  }

  .h3 {
    font-size: 18px;
    line-height: 24px
  }

  .h2_mobile {
    display: block
  }

  .block-header {
    position: relative;
    padding-left: 0;
    margin-left: -5px
  }

  .block-header .h2 {
    margin-bottom: 9px;
    margin-left: 5px
  }

  .block-header .block-header__text {
    display: inline;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding: 0 6px 0 4px
  }

  .block-header .block-header__text_small {
    padding: 1px 6px 1px 5px
  }

  .block-header:before {
    content: none
  }
}


p {
  color: #333;
  font-size: 18px;
  line-height: 28px;
  margin-top: 18px
}

p.no-margin,
p:first-child {
  margin-top: 0
}

p strong {
  color: #333
}

p.large {
  font-size: 20px;
  line-height: 28px
}

p.accent {
  font-weight: 500;
  font-style: normal;
  color: $color-primary
}

p.accent,
p.italic {
  font-family: $font-geometria
}

p.italic {
  font-weight: 400;
  font-style: italic
}

p.strong {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  color: #333
}

@media screen and (max-width:1312px) {
  p {
    font-size: 16px;
    line-height: 28px
  }

  p.large,
  p.strong {
    font-size: 18px
  }
}

@media screen and (max-width:1023px) {
  p {
    font-size: 14px;
    line-height: 22px
  }
}

@media screen and (max-width:767px) {
  p {
    font-size: 14px;
    line-height: 22px;
    margin-top: 13px
  }

  p.italic {
    font-size: 12px;
    line-height: 20px
  }
}

.list li {
  font-family: $font-geometria;
  font-weight: 400;
  font-style: normal;
  position: relative;
  padding-left: 23px;
  font-size: 18px;
  line-height: 26px;
  margin-top: 22px
}

.list li:first-child {
  margin-top: 0
}

.list li:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: $color-primary;
  border-radius: 50%;
  left: 0;
  top: 7px
}

.list_tall>li {
  margin-top: 24px
}

.list_second>li:before {
  background-color: $color-primary;
  top: 10px
}

@media screen and (max-width:1312px) {
  .list li {
    padding-left: 23px;
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px
  }

  .list li:before {
    top: 6px
  }
}

@media screen and (max-width:1023px) {
  .list li {
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px
  }

  .list li:before {
    top: 5px
  }
}

@media screen and (max-width:767px) {
  .list li {
    font-size: 14px;
    line-height: 22px;
    padding-left: 23px;
    margin-top: 13px
  }

  .list li:before {
    top: 5px
  }
}

.mfp-bg {
  background: rgba(46, 42, 38, .5)
}

.mfp-wrap {
  overflow-y: auto
}

.mfp-close:focus,
.mfp-close:hover {
  opacity: .7
}

.mfp-close-btn-in .mfp-close {
  color: transparent;
  right: 9px;
  top: 0
}

.mfp-close,
.mfp-iframe-holder .mfp-close,
.mfp-image-holder .mfp-close {
  width: 30px;
  height: 30px;
  right: -7px;
  top: 0;
  font-size: 34px;
  color: transparent;
  opacity: 1;
  transition: opacity .2s ease
}

.mfp_video-popup .mfp-close {
  top: -35px
}

.mfp_image-popup .mfp-close {
  right: 0
}

.mfp_modal-success .mfp-close,
.mfp_modal-success .mfp-close:hover {
  right: 0;
  top: 2px
}

.modal_wide {
  max-width: 1320px
}

.modal__wrapper {
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .1)
}

.mfp_modal-success {
  max-width: 600px
}

.mfp_modal-success .modal__title {
  text-align: left
}

.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all .15s ease-out
}

.mfp-fade.mfp-bg.mfp-ready {
  opacity: .8
}

.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0
}

.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all .15s ease-out
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0
}

button.mfp-close:before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  left: 50%;
  top: 50%;
  margin-top: -7px;
  margin-left: -7px;
  background-image: url(../static/implant_dentalway__ru/build/i/bb77f42d71d5510e6fb3570b52879367.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  -webkit-filter: drop-shadow(0 15px 25px rgba(0, 0, 0, .1));
  filter: drop-shadow(0 15px 25px rgba(0, 0, 0, .1))
}

button.mfp-close:focus,
button.mfp-close:hover {
  opacity: .7
}

button.mfp-arrow {
  display: block;
  width: 45px;
  height: 90px;
  border: 0;
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -45px;
  overflow: hidden;
  z-index: 1050;
  opacity: 1
}

button.mfp-arrow:before {
  content: "";
  position: absolute;
  left: -45px;
  right: auto;
  top: 0;
  width: 90px;
  height: 90px;
  border-radius: 10px;
  background-color: #333;
  border: 0;
  margin: 0 !important;
  opacity: 1
}

button.mfp-arrow:after {
  content: "";
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -8px;
  width: 18px;
  height: 16px;
  background-image: url(../static/implant_dentalway__ru/build/i/e92a0538f25201a7afeb9e629a9d5169.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%;
  border: 0;
  margin-left: 0
}

button.mfp-arrow:active {
  margin-top: -45px
}

button.mfp-arrow-right {
  left: auto;
  right: 0
}

button.mfp-arrow-right:before {
  left: auto;
  right: -45px
}

button.mfp-arrow-right:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  left: auto;
  right: 10px
}

[data-js=tooltip-button] {
  cursor: pointer
}

[data-js=tooltip-html] {
  display: none
}

.tippy-content>div {
  max-width: 400px
}

.tippy-tooltip.light-theme {
  box-shadow: 0 10px 29px rgba(70, 81, 86, .18);
  border: 1px solid #e0e3e4;
  background-color: #fff;
  font-size: 14px
}

[data-js-tooltip=arrow] {
  position: absolute;
  width: 30px;
  height: 14px
}

[data-js-tooltip=arrow]:before {
  z-index: 1;
  left: 1px;
  top: 1px
}

[data-js-tooltip=arrow]:after,
[data-js-tooltip=arrow]:before {
  content: "";
  border-style: solid;
  position: absolute;
  display: block;
  width: 0;
  height: 0
}

[data-js-tooltip=arrow]:after {
  z-index: 0
}

.tippy-arrow {
  position: absolute
}

.tippy-popper[x-placement^=bottom] .tippy-arrow {
  width: 30px;
  height: 14px;
  top: -14px
}

.tippy-popper[x-placement^=bottom] [data-js-tooltip=arrow] {
  left: -7px;
  top: -1px
}

.tippy-popper[x-placement^=bottom] [data-js-tooltip=arrow]:before {
  border-width: 0 15px 14px;
  border-color: transparent transparent #fff
}

.tippy-popper[x-placement^=bottom] [data-js-tooltip=arrow]:after {
  border-width: 0 16px 15px;
  border-color: transparent transparent #e0e3e4
}

.tippy-popper[x-placement^=top] .tippy-arrow {
  width: 30px;
  height: 14px;
  bottom: -14px
}

.tippy-popper[x-placement^=top] [data-js-tooltip=arrow] {
  left: -7px;
  bottom: 0
}

.tippy-popper[x-placement^=top] [data-js-tooltip=arrow]:before {
  border-width: 14px 15px 0;
  border-color: #fff transparent transparent;
  top: 0
}

.tippy-popper[x-placement^=top] [data-js-tooltip=arrow]:after {
  border-width: 15px 16px 0;
  border-color: #e0e3e4 transparent transparent
}

.tippy-tooltip {
  padding: 20px 60px 20px 30px !important;
  position: relative;
  text-align: left
}

[data-js-tooltip=close] {
  pointer-events: all;
  position: absolute;
  cursor: pointer;
  width: 14px;
  height: 14px;
  top: 23px;
  right: 23px
}

[data-js-tooltip=close]>svg {
  position: absolute;
  left: 0;
  top: 0
}

[data-js-tooltip=close]>svg path {
  transition: fill .2s ease-out
}

[data-js-tooltip=close]:hover>svg path {
  fill: #8e9799;
  transition: fill .2s ease-out
}

.tooltip {
  text-align: left
}

.tooltip__title {
  margin-bottom: 20px;
  line-height: 28px;
  max-width: 260px;
  font-size: 16px
}

.tooltip__text {
  line-height: 26px;
  font-size: 14px;
  color: #989ca6
}

.tooltip__li-right {
  padding-left: 20px;
  float: right
}

@media screen and (max-width:767px) {
  .tooltip__title {
    max-width: 100%
  }

  .tippy-tooltip {
    padding-left: 20px !important;
    padding-bottom: 20px !important
  }

  .tippy-popper[data-html] {
    max-width: calc(100% - 10px)
  }

  .tooltip__li-right {
    padding-left: 0;
    display: block;
    float: none
  }
}

@media screen and (max-width:470px) {
  .tooltip__text br {
    display: none
  }

  .tippy-tooltip {
    padding-right: 45px !important
  }
}

.form__row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px
}

.form__row>* {
  flex: 0 1 50%;
  padding-left: 10px;
  padding-right: 10px
}

.form__row_triple>* {
  flex-basis: 33.33%
}

.form__row_triple .form__policy {
  margin-top: 0
}

.form__group {
  position: relative
}

.form__group label {
  font-family: $font-geometria;
  font-weight: 500;
  font-style: normal;
  color: #cbcbcb;
  font-size: 16px;
  position: absolute;
  left: 32px;
  top: 30px;
  transition: .2s ease;
  pointer-events: none
}

.form__group.focused label {
  font-size: 12px;
  top: 10px
}

.form__group.error label {
  color: red
}

.form__group.error .input,
.form__group.error label {
  -webkit-animation: shake .82s cubic-bezier(.36, .07, .19, .97) both;
  animation: shake .82s cubic-bezier(.36, .07, .19, .97) both
}

.form__group.error .input {
  border-color: red
}

.form__group.error .input::-webkit-input-placeholder {
  color: red
}

.form__group.error .input::placeholder {
  color: red
}

.form__group .error {
  position: absolute;
  right: 40px;
  bottom: 3px;
  font-size: 12px;
  color: red
}

@keyframes shake {

  10%,
  90% {
    margin-left: -1px
  }

  20%,
  80% {
    margin-left: 2px
  }

  30%,
  50%,
  70% {
    margin-left: -4px
  }

  40%,
  60% {
    margin-left: 4px
  }
}

.input {
  font-family: $font-geometria;
  font-weight: 400;
  font-style: normal;
  height: 66px;
  width: 100%;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 5px 19px;
  box-sizing: border-box;
  color: #6b6b6b;
  font-size: 16px;
  background-color: #fff;
  transition: .1s ease
}

.input::-webkit-input-placeholder {
  color: #6b6b6b
}

.input::placeholder {
  color: #6b6b6b
}

.input:focus,
.input:hover {
  border: 1px solid $color-primary
}

.input:focus {
  font-family: $font-geometria;
  font-weight: 500;
  font-style: normal;
  color: #333
}

.input_phone::-webkit-input-placeholder {
  color: #6b6b6b
}

.input_phone::placeholder {
  color: #6b6b6b
}

.input_phone:focus,
.input_phone:hover {
  border: 1px solid $color-primary
}

.input_phone:focus {
  font-family: $font-geometria;
  font-weight: 500;
  font-style: normal;
  color: #333
}

textarea {
  resize: none
}

textarea.input {
  padding-top: 31px
}

textarea+label {
  background-color: $color-primary
}

.form__group_button {
  margin-top: 20px
}

.form__policy {
  font-family: $font-geometria;
  font-weight: 400;
  font-style: italic;
  font-size: 12px;
  line-height: 20px;
  color: #7f8f98;
  margin-top: 21px;
  text-align: center;
  max-width: 280px;
  margin-left: auto;
  margin-right: auto
}

.form__policy a {
  text-decoration: underline;
  color: $color-primary
}

.form__policy a:hover {
  text-decoration: none
}

.form__flex {
  display: flex;
  margin-top: 22px
}

.form__flex .form__group {
  width: 100%;
  max-width: 370px;
  margin-right: 20px
}

.form__flex .button_submit {
  margin-top: 0;
  max-width: 370px
}

.form__title {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  font-size: 38px;
  line-height: 48px
}

.form_white .input {
  border-color: #cdcdcd
}

.form_white .input:hover {
  border: 1px solid $color-primary
}

.form_white .input:focus {
  font-family: $font-geometria;
  font-weight: 500;
  font-style: normal;
  border: 1px solid $color-primary;
  color: #333
}

.form__group_button .form__button__image {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(calc(100% + 25px), -50%);
  transform: translate(calc(100% + 25px), -50%);
  right: 0
}

@media screen and (max-width:1312px) {
  .input::-webkit-input-placeholder {
    font-size: 16px
  }

  .input::placeholder {
    font-size: 16px
  }

  .form__group label {
    font-size: 16px;
    top: 32px;
    left: 32px
  }

  .form__group.focused label {
    left: 22px;
    font-size: 10px
  }

  .form__flex {
    margin-top: 18px
  }

  .form__flex .button_submit {
    max-width: 290px
  }

  .form__policy {
    font-size: 12px;
    line-height: 20px;
    padding-left: 4px;
    margin-top: 8px
  }

  .form__title {
    font-size: 24px;
    line-height: 30px
  }

  .form__group_button {
    margin-top: 10px
  }

  .form__group_button .form__button__image {
    -webkit-transform: translate(calc(100% + 13px), -50%);
    transform: translate(calc(100% + 13px), -50%)
  }
}

@media screen and (max-width:1023px) {
  .input {
    height: 50px
  }

  .form__group label {
    top: 25px;
    left: 32px;
    font-size: 14px
  }

  .form__group.focused label {
    left: 32px;
    font-size: 10px
  }

  .form__flex {
    margin-top: 13px
  }

  .form__flex .form__group {
    max-width: 189px;
    margin-right: 14px
  }

  .form__flex .form__group:nth-child(2) {
    margin-right: 0;
    max-width: 100%
  }

  .form__policy {
    text-align: left
  }

  .form__group_button .form__button__image {
    max-width: 110px;
    -webkit-transform: translate(calc(100% + 18px), -50%);
    transform: translate(calc(100% + 18px), -50%)
  }
}

@media screen and (max-width:767px) {
  .input {
    height: 50px;
    padding: 8px 20px
  }

  .input::-webkit-input-placeholder {
    font-size: 14px
  }

  .input::placeholder {
    font-size: 14px
  }

  .form__group label {
    font-size: 12px;
    left: 21px;
    top: 22px
  }

  .form__group.focused label {
    left: 32px;
    font-size: 10px
  }

  .form__policy {
    font-size: 12px;
    line-height: 18px;
    margin-top: 15px;
    padding-right: 0;
    text-align: center
  }

  .form__flex {
    flex-wrap: wrap
  }

  .form__flex .form__group {
    max-width: 100%;
    margin-right: 0
  }

  .form__flex .input {
    height: 60px
  }

  .form__flex>* {
    flex-basis: 100% !important
  }

  .form__flex .button_submit {
    height: 60px;
    max-width: 242px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 17px
  }

  textarea.input {
    padding-top: 31px;
    height: 99px;
    border-radius: 25px
  }

  .form__group_button .form__button__image {
    display: none
  }
}

.slick-arrow {
  width: 90px;
  height: 90px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -45px;
  overflow: hidden;
  border-radius: 10px;
  border: 0;
  z-index: 2
}

.slick-arrow,
.slick-arrow>span {
  display: flex;
  justify-content: center;
  align-items: center
}

.slick-arrow>span {
  width: 50px;
  height: 50px;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative
}

.slick-arrow>span:after,
.slick-arrow>span:before {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 10px;
  opacity: 0;
  transition: .2s ease
}

.slick-arrow>span:before {
  -webkit-transform: translateY(3px);
  transform: translateY(3px)
}

.slick-arrow>span>i {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url(../static/implant_dentalway__ru/build/i/e92a0538f25201a7afeb9e629a9d5169.svg);
  background-repeat: no-repeat;
  background-size: 14px 9px;
  background-position: 50%;
  position: relative;
  z-index: 3;
  overflow: hidden;
  border-radius: 10px
}

.slick-arrow>span>i:before {
  content: "";
  position: absolute;
  width: 27px;
  height: 70px;
  top: -13px;
  right: -16px;
  background: hsla(0, 0%, 100%, .5);
  -webkit-filter: blur(25px);
  filter: blur(25px);
  border-radius: 50px;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
  opacity: 0;
  transition: .2s ease
}

.slick-arrow.prev {
  left: 0;
  background-image: linear-gradient(222.59deg, #e75a3c 2.98%, #f0b050 99.12%)
}

.slick-arrow.prev>span:before {
  background: linear-gradient(222.59deg, #c47717 2.98%, #bd4816 99.12%);
  -webkit-transform: translateY(3px);
  transform: translateY(3px)
}

.slick-arrow.prev>span:after {
  background: linear-gradient(222.59deg, #f14721 2.98%, #f4a226 99.12%)
}

.slick-arrow.next {
  right: 0;
  left: auto;
  background: linear-gradient(-222.59deg, #e75a3c 2.98%, #f0b050 99.12%)
}

.slick-arrow.next>span:before {
  background: linear-gradient(-222.59deg, #c47717 2.98%, #bd4816 99.12%);
  -webkit-transform: translateY(3px);
  transform: translateY(3px)
}

.slick-arrow.next>span:after {
  background: linear-gradient(-222.59deg, #f14721 2.98%, #f4a226 99.12%)
}

.slick-arrow.next>span>i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.slick-arrow:hover>span {
  border-color: transparent
}

.slick-arrow:hover>span:after,
.slick-arrow:hover>span:before,
.slick-arrow:hover>span>i:before {
  opacity: 1
}

.slick-arrow:active>span:before,
.slick-arrow:active>span>i:before {
  opacity: 0
}

.slick-arrow.slick-disabled {
  opacity: .7;
  cursor: default;
  background-color: $color-bg-gray
}

.slick-arrow.slick-disabled:before {
  opacity: 0 !important
}

@media screen and (max-width:1023px) {
  .slick-arrow {
    width: 62px;
    height: 62px;
    margin-top: -31px;
    padding: 0
  }

  .slick-arrow>span {
    width: 35px;
    height: 35px
  }

  .slick-arrow:after {
    content: none
  }

  .slick-arrow.next:after {
    margin-left: -9px
  }
}

@media screen and (max-width:767px) {
  .slick-arrow {
    width: 45px;
    height: 45px;
    margin-top: -23px;
    padding: 0
  }

  .slick-arrow>span {
    width: 25px;
    height: 25px
  }

  .slick-arrow:after {
    content: none
  }

  .slick-arrow.next:after {
    margin-left: -9px
  }
}

.accordion__tab__content {
  display: none
}

.accordion__tab__content.active {
  display: block
}

.bg-block {
  background: $color-bg-gray;
  overflow: hidden
}

.socials {
  margin-left: -10px;
  margin-right: -10px
}

.socials>* {
  display: block;
  flex: 0 1 30px;
  margin-left: 10px;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%
}

.socials>.ig {
  background-image: url(../static/implant_dentalway__ru/build/i/1251a97b96df662cf69e3626bebb20cd.svg)
}

.socials>.vk {
  background-image: url(../static/implant_dentalway__ru/build/i/6f9678993f2bfefa5cfbf1e5333d83ed.svg)
}

.socials>.tg {
  background-image: url(../static/implant_dentalway__ru/build/i/8fbb395317170bf872401db0ccd3ec4d.svg)
}

.socials>.fb {
  background-image: url(../static/implant_dentalway__ru/build/i/415d54d7eec63f2089b51f3e5bf0fb4b.svg)
}

.socials>.ok {
  background-image: url(../static/implant_dentalway__ru/build/i/ddf215b31db52af6c78996c69fcf44b0.svg)
}

@media screen and (max-width:767px) {
  .socials {
    justify-content: center
  }
}

.block-label {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  position: absolute;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 88, 138, .1);
  text-transform: uppercase;
  letter-spacing: .4em;
  padding-left: 99px;
  padding-right: 99px;
  -webkit-transform: translate(-41.9%, -53%) rotate(-90deg);
  transform: translate(-41.9%, -53%) rotate(-90deg);
  left: 0;
  top: 50%;
  pointer-events: none;
  display: inline-block;
  -webkit-user-select: none;
  user-select: none
}

.block-label:after,
.block-label:before {
  content: "";
  position: absolute;
  width: 79px;
  height: 1px;
  background-color: #7f8f98;
  top: 50%;
  margin-top: -1px
}

.block-label:before {
  left: 0
}

.block-label:after {
  right: 4px
}

.block-label_left {
  background: linear-gradient(-219.35deg, #0086d2, rgba(0, 134, 210, 0))
}

.block-label_right {
  left: auto;
  right: 0;
  -webkit-transform: translate(41%, -75%) rotate(-90deg);
  transform: translate(41%, -75%) rotate(-90deg);
  background: linear-gradient(219.35deg, #0086d2, rgba(0, 134, 210, 0))
}

.block-label_right>span>span {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-bottom: 70px
}

.block-label_light {
  color: #fff
}

.block-label_light:before {
  background-color: #fff
}

.block-label_large {
  -webkit-transform: none;
  transform: none;
  width: 357px;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 0;
  font-size: 134px;
  line-height: 120px;
  color: rgba(0, 88, 138, .2);
  text-align: center;
  letter-spacing: 0
}

.block-label_large:after,
.block-label_large:before {
  content: none
}

.block-label_large>span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-47%, -50%) rotate(-90deg);
  transform: translate(-47%, -50%) rotate(-90deg);
  height: 1000px;
  width: 1000px
}

.block-label_large>span>span {
  text-align: center
}

.block-label_large.block-label_right {
  right: 0;
  left: auto
}

.block-label_medium {
  width: 198px;
  font-size: 94px;
  line-height: 85px
}

.block-label_medium>span {
  -webkit-transform: translate(-48.5%, -50%) rotate(-90deg);
  transform: translate(-48.5%, -50%) rotate(-90deg)
}

@media screen and (max-width:1450px) {
  .block-label {
    display: none
  }

  .block-label_large {
    display: block;
    width: 289px;
    font-size: 158px;
    line-height: 142px
  }

  .block-label_large>span {
    -webkit-transform: translate(-48%, -50%) rotate(-90deg);
    transform: translate(-48%, -50%) rotate(-90deg)
  }
}

@media screen and (max-width:1023px) {
  .block-label_large {
    display: none
  }
}

// карточка специалиста
.employee {
  &__info {
    align-items: center;
    margin-left: 31px;
  }

  &__info-photo {
    position: relative;
    max-width: 227px;
    width: 100%;
    background-color: $color-bg-gray;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 30px;

    &:after {
      content: "";
      display: block;
      height: 0;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__info-content {
    flex: 0 1 calc(100% - 257px);

    .quote__employee & {
      margin-top: 10px
    }
  }

  &__info-fio {
    font-family: Geometria,
      sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 140%;
  }

  &__info-profession {
    font-family: Geometria,
      sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    line-height: 150%;
    margin-top: 16px;
    color: #7f8f98;
  }

  &__info-experience {
    font-family: $font-geometria;
    font-weight: 500;
    font-style: normal;
    background-color: $color-primary;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    border-radius: 5px;
    padding: 4px 13px 5px 14px;
    margin-top: 15px;
  }
}

@media screen and (max-width:1312px) {
  .employee__info {
    margin-left: 0
  }

  .employee__info-photo {
    max-width: 193px;
    margin-right: 18px
  }

  .employee__info-content {
    flex-basis: calc(100% - 211px)
  }

  .employee__info-profession {
    margin-top: 15px
  }

  .employee__info-experience {
    margin-top: 16px
  }
}

@media screen and (max-width:767px) {
  .employee__info {
    margin-top: 30px
  }

  .employee__info-photo {
    max-width: 129px;
    margin-right: 19px
  }

  .employee__info-content {
    margin-top: 0
  }

  .employee__info-fio {
    font-size: 14px;
    line-height: 20px
  }

  .employee__info-profession {
    line-height: 18px;
    margin-top: 1px
  }

  .employee__info-experience {
    font-size: 12px;
    min-width: 110px;
    text-align: center;
    margin-top: 5px
  }
}

.quote__text {
  padding-left: 40px;
  position: relative;
  font-size: 18px;
  line-height: 28px;
  margin-top: 35px;
  margin-bottom: 36px
}

.quote__text:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 24px;
  height: 16px;
  background-image: url(../static/implant_dentalway__ru/build/i/6a1c5d95fc8bdb293685d1da3a6fbd0b.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%
}

@media screen and (max-width:1312px) {
  .quote__text {
    font-size: 16px;
    line-height: 24px;
    padding-left: 44px;
    margin-top: 22px;
    max-width: 435px
  }
}

@media screen and (max-width:1023px) {
  .quote__text {
    font-size: 14px;
    line-height: 22px;
    padding-left: 30px;
    margin-top: 20px;
    max-width: 460px
  }

  .quote__text:before {
    width: 17px;
    height: 12px;
    top: 4px
  }
}

@media screen and (max-width:767px) {
  .quote__text {
    padding-right: 0;
    padding-left: 25px;
    max-width: unset
  }
}


.quote__calculation-block {
  padding-bottom: 100px;
}

.quote__calculation {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  color: #333;
  z-index: 1;
  padding: 40px 375px 40px 50px;
  margin-top: -116px;
  background-image: url(../static/implant_dentalway__ru/build/i/75723faa2446e32e59961a90ebf7eb2b.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
}

.quote__calculation__title {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  font-size: 38px;
  line-height: 48px;
  color: #333;

  &>span {
    color: $color-primary;
  }
}

.quote__calculation__images {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 58px;
  top: 88px;
  min-height: 142px;
  font-size: 30px;
  line-height: 38px;
  color: $color-primary;

  &>.loaded {
    &+span {
      display: block;
    }
  }

  &>span {
    margin-left: 13px;
    margin-right: 26px;
    display: none;
  }
}

.quote__calculation__button {
  align-items: center;
  margin-top: 23px;

  .btn {
    margin-right: 40px;
  }

  &>div {
    font-size: 14px;
    line-height: 20px;
    max-width: 260px;
    color: #333;
  }
}


@media screen and (max-width:1312px) {
  .quote__calculation-block {
    padding-bottom: 55px
  }

  .quote__calculation {
    margin-top: -81px;
    padding: 45px 238px 54px 47px;
    overflow: visible
  }

  .quote__calculation__title {
    font-size: 30px;
    line-height: 38px
  }

  .quote__calculation__title span {
    display: block
  }

  .quote__calculation__button {
    margin-top: 32px
  }

  .quote__calculation__button .btn {
    margin-right: 34px;
    max-width: 300px;
    height: 66px
  }

  .quote__calculation__button>div {
    max-width: 310px
  }

  .quote__calculation__images {
    flex-wrap: wrap;
    max-width: 120px;
    justify-content: center;
    top: 0;
    -webkit-transform: none;
    transform: none;
    font-size: 25px
  }

  .quote__calculation__images>span {
    margin-right: 14px;
    line-height: 24px
  }
}

@media screen and (max-width:1023px) {
  .quote__calculation-block {
    padding-bottom: 50px
  }

  .quote__calculation {
    margin-top: -160px;
    padding: 28px 149px 34px 30px
  }

  .quote__calculation__title {
    font-size: 24px;
    line-height: 30px
  }

  .quote__calculation__button {
    margin-top: 22px
  }

  .quote__calculation__button .btn {
    margin-right: 22px;
    max-width: 202px;
    height: 50px
  }

  .quote__calculation__button>div {
    max-width: 310px
  }

  .quote__calculation__images {
    max-width: 90px;
    font-size: 19px;
    right: 36px;
    top: -6px
  }

  .quote__calculation__images>span {
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .quote__calculation__images img:first-child {
    max-width: 90px
  }

  .quote__calculation__images img:last-child {
    max-width: 82px
  }
}

@media screen and (max-width:767px) {
  .quote__calculation-block {
    padding-bottom: 36px
  }

  .quote__calculation {
    padding: 26px 60px 16px 23px;
    margin-top: -114px;
    overflow: hidden;
    background-image: none;
    background-color: $color-bg-gray
  }

  .quote__calculation__title {
    font-size: 16px;
    line-height: 22px
  }

  .quote__calculation__title span {
    display: inline
  }

  .quote__calculation__button {
    margin-top: 12px;
    display: flex;
    align-items: center
  }

  .quote__calculation__button .btn {
    margin-right: 15px
  }

  .quote__calculation__button>div {
    font-size: 11px;
    line-height: 16px;
    max-width: calc(100% - 217px)
  }

  .quote__calculation__images {
    max-width: 40px;
    right: 13px;
    top: 10px;
    bottom: 10px;
    min-height: unset;
    display: flex;
    justify-content: space-between
  }

  .quote__calculation__images>span {
    line-height: 6px
  }

  .quote__calculation__images img:first-child {
    max-width: 40px
  }

  .quote__calculation__images img:last-child {
    max-width: 36px
  }
}

@media screen and (max-width:575px) {
  .quote__calculation {
    padding: 26px 15px 16px 23px;
    margin-top: -114px;
    overflow: hidden
  }

  .quote__calculation__title {
    max-width: 425px
  }

  .quote__calculation__button {
    margin-top: 12px;
    flex-direction: column;
    align-items: flex-start
  }

  .quote__calculation__button .btn {
    margin-right: 15px
  }

  .quote__calculation__button>div {
    font-size: 11px;
    line-height: 16px;
    max-width: 100%;
    margin-top: 13px
  }

  .quote__calculation__images {
    max-width: 40px;
    right: 13px;
    top: 66px;
    bottom: 48px
  }

  .quote__calculation__images>span {
    font-size: 8px;
    line-height: 6px;
    margin-top: -3px
  }

  .quote__calculation__images img:first-child {
    max-width: 36px
  }

  .quote__calculation__images img:last-child {
    max-width: 30px
  }
}

.quote-offers {
  display: flex;
  position: relative;
  z-index: 2
}

.quote-offers>* {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch
}

.quote-offer__wrapper {
  padding: 5px;
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-height: 50%
}

.quote-offer__wrapper--big {
  max-height: unset
}

.quote-offer {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between
}

.quote-offer,
.quote-offer>* {
  position: relative
}

.quote-offer--small {
  width: 230px;
  // background: $color-primary-light;
  background-color: #fff;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .1);
  padding: 50px 40px;
  text-align: center;
}

.quote-offer--small .quote-offer__title {
  color: $color-primary
}

.quote-offer--big {
  width: 440px;
  // background: $color-bg-gray;
  background-color: #fff;
  box-shadow: none;
  padding: 35px 50px 30px;
  text-align: left;
}

.quote-offer--big .quote-offer__title {
  text-align: left
}

.quote-offer__bg {
  position: absolute;
  left: 25px
}

.quote-offer__bg--implant {
  top: -19px
}

.quote-offer__bg--like {
  top: -14px
}

.quote-offer__icon {
  padding-bottom: 10px
}

.quote-offer__title {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  color: #333;
  font-size: 28px;
  line-height: 35px;
  text-align: center
}

.quote-offer__title span {
  color: $color-primary
}

.quote-offer__text {
  font-size: 14px;
  line-height: 18px
}

.quote-offer__desc,
.quote-offer__text {
  font-family: $font-geometria;
  font-weight: 400;
  font-style: italic;
  color: #333
}

.quote-offer__desc {
  padding-top: 11px;
  font-size: 16px;
  line-height: 24px;
  display: flex
}

.quote-offer__desc:before {
  content: "";
  height: 10px;
  width: 10px;
  min-width: 10px;
  border-radius: 50%;
  background-color: $color-primary;
  margin-right: 13px;
  margin-top: 7px
}

.quote-offer__items {
  padding-top: 25px
}

.quote-offer__item {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px
}

.quote-offer__plus {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  color: $color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 42px;
  font-size: 30px;
  text-align: center
}

.quote-offer__plus:last-child {
  display: none
}

.quote-offer__item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  min-width: 42px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 19px
}

.quote-offer__item-label {
  font-family: $font-geometria;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000
}

.quote-offer__button {
  padding-top: 30px;
  padding-bottom: 14px
}

.quote-offer__action {
  display: flex;
  align-items: center;
  font-family: $font-geometria;
  font-weight: 400;
  font-style: italic;
  color: #7f8f98;
  font-size: 14px;
  line-height: 18px
}

.quote-offer__action:before {
  content: "!";
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  color: $color-primary;
  font-size: 42px;
  line-height: 53px;
  margin-right: 9px
}

.quote-offer__action br {
  display: none
}

@media screen and (max-width:1312px) {
  .quote-offers {
    flex-direction: column
  }

  .quote-offers>* {
    flex-direction: row
  }

  .quote-offer--small {
    width: 215px;
    padding: 10px 8px 15px;
    box-shadow: none;
  }

  .quote-offer--small .quote-offer__title {
    font-size: 22px;
    line-height: 28px
  }

  .quote-offer--big {
    padding: 30px 50px 15px
  }

  .quote-offer__items {
    display: flex
  }

  .quote-offer__item {
    flex-direction: column;
    text-align: center;
    width: auto
  }

  .quote-offer__bg {
    left: 0
  }

  .quote-offer__bg img {
    margin-right: 0;
    margin-left: auto;
    height: 100%;
    width: auto
  }

  .quote-offer__bg--implant {
    padding-left: 20px;
    top: -12px;
    height: 72px
  }

  .quote-offer__bg--like {
    top: -8px;
    height: 68px
  }

  .quote-offer__item-icon {
    margin-right: 0;
    margin-bottom: 15px
  }

  .quote-offer__plus {
    height: 100%;
    align-items: flex-start;
    min-width: 62px;
    width: 62px;
    padding-top: 20px
  }

  .quote-offer__icon {
    padding-bottom: 4px
  }

  .quote-offer__icon img {
    max-width: 45px
  }

  .quote-offer__text {
    font-size: 13px
  }

  .quote-offer__button {
    padding-top: 25px;
    padding-bottom: 8px
  }
}

@media screen and (max-width:1023px) {
  .quote-offer--big {
    width: 348px;
    padding: 25px 20px 20px
  }

  .quote-offer--small {
    width: 171px;
    padding: 16px 4px
  }

  .quote-offer--small .quote-offer__title {
    font-size: 20px;
    line-height: 25px
  }

  .quote-offer__title {
    font-size: 22px;
    line-height: 28px
  }

  .quote-offer__desc {
    font-size: 14px;
    line-height: 20px;
    padding-top: 8px
  }

  .quote-offer__items {
    flex-direction: column;
    padding-top: 10px
  }

  .quote-offer__item {
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 5px
  }

  .quote-offer__plus {
    width: 31px;
    min-width: 31px;
    padding-top: 0;
    height: 13px;
    align-items: center;
    font-size: 20px
  }

  .quote-offer__item-icon {
    margin-bottom: 0;
    margin-right: 13px;
    width: 31px;
    min-width: 31px;
    height: 31px
  }

  .quote-offer__item-icon img {
    max-width: 28px;
    max-height: 40px
  }

  .quote-offer__item-label {
    font-size: 12px;
    line-height: 15px
  }

  .quote-offer__button {
    padding-top: 12px
  }

  .quote-offer__button .btn {
    max-width: 265px
  }

  .quote-offer__action {
    font-size: 12px;
    line-height: 15px
  }

  .quote-offer__action:before {
    font-size: 30px;
    line-height: 38px
  }

  .quote-offer__icon {
    padding-bottom: 8px
  }

  .quote-offer__icon img {
    max-width: 35px
  }

  .quote-offer__text {
    font-size: 11px;
    line-height: 16px
  }
}

@media screen and (max-width:767px) {
  .quote-offers {
    max-width: 298px;
    margin-left: auto;
    margin-right: auto
  }

  .quote-offer {
    width: 100%
  }

  .quote-offer--small {
    padding: 15px 8px 17px;
    justify-content: flex-start
  }

  .quote-offer--small .quote-offer__title,
  .quote-offer__title {
    font-size: 18px;
    line-height: 23px
  }

  .quote-offer__desc {
    font-size: 12px;
    line-height: 16px;
    padding-top: 10px
  }

  .quote-offer__items {
    padding-top: 18px
  }

  .quote-offer__item {
    padding-top: 8px;
    padding-bottom: 8px
  }

  .quote-offer__item-icon {
    width: 36px;
    height: 36px;
    min-width: 36px
  }

  .quote-offer__item-icon img {
    max-height: 46px;
    max-width: 31px
  }

  .quote-offer__item-label {
    font-size: 14px;
    line-height: 18px;
    text-align: left
  }

  .quote-offer__plus {
    width: 36px;
    min-width: 36px;
    height: 25px
  }

  .quote-offer__action br {
    display: unset
  }

  .quote-offer__icon {
    padding-bottom: 12px
  }

  .quote-offer__icon img {
    max-width: 48px
  }

  .quote-offer__text {
    font-size: 12px;
    line-height: 18px
  }
}

.quote {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding-bottom: 80px
}

.quote:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  left: 60%;
  height: 100%;
  background: linear-gradient(219.35deg, $color-primary, rgba(0, 134, 210, 0))
}

.quote .h2 {
  max-width: 740px
}

.quote .block-label_medium {
  width: 212px;
  font-size: 76px;
  line-height: 69px
}

.quote__image {
  position: absolute;
  top: 287px;
  bottom: 0;
  background-image: url(../static/implant_dentalway__ru/build/i/89cc01f87321922a147413ef76ff75a8.png);
  background-repeat: no-repeat;
  background-position: 100% 0
}

.quote__image img {
  height: 100%
}

.quote__image--right {
  right: 0;
  left: 60%;
  display: flex;
  justify-content: flex-end
}

.quote__grid {
  align-items: center;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  flex-wrap: nowrap
}

.quote__grid>* {
  flex: 0 1 calc(100% - 730px);
  padding-left: 15px;
  padding-right: 15px
}

.quote__grid>:nth-child(2) {
  flex-basis: 680px;
  padding-left: 10px;
  padding-right: 10px
}

.quote__employee {
  margin-left: 0;
  // max-width: 460px
}

.quote__employee .employee__info-photo {
  max-width: 190px
}

.quote__employee .employee__info-profession {
  color: #6b6b6b
}



.quote__employee .employee__info-experience {
  background: $color-primary
}

@media screen and (max-width:1312px) {
  .quote {
    padding-top: 54px;
    padding-bottom: 52px
  }

  .quote .block-label {
    display: none
  }

  .quote__image {
    left: 50%
  }

  .quote__grid>* {
    flex-basis: calc(100% - 470px)
  }

  .quote__grid>:nth-child(2) {
    flex-basis: 470px
  }

  .quote__employee .employee__info-content {
    margin-top: 0;
    margin-bottom: -6px
  }

  .quote__employee .employee__info-experience {
    margin-top: 26px
  }
}

@media screen and (max-width:1023px) {
  .quote {
    padding-top: 37px;
    padding-bottom: 39px
  }

  .quote:before {
    width: 300px;
    left: auto
  }

  .quote .h2 {
    padding-right: 15px
  }

  .quote__image {
    display: none
  }

  .quote__grid {
    margin-left: -10px;
    margin-right: -10px
  }

  .quote__grid>* {
    flex-basis: calc(100% - 368px)
  }

  .quote__grid>:nth-child(2) {
    flex-basis: 368px;
    padding-left: 10px;
    padding-right: 10px
  }

  .quote__grid .h2 {
    max-width: 460px
  }

  .quote__grid .h2 br {
    display: none
  }

  .quote__employee {
    padding-right: 0
  }

  .quote__employee .employee__info-photo {
    max-width: 165px
  }

  .quote__employee .employee__info-content {
    margin-bottom: 0
  }

  .quote__employee .employee__info-experience,
  .quote__employee .employee__info-profession {
    margin-top: 5px
  }
}

@media screen and (max-width:767px) {
  .quote {
    padding-top: 35px;
    padding-bottom: 40px
  }

  .quote .h2 {
    padding-right: 0
  }

  .quote:before {
    left: 0;
    top: auto;
    bottom: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    height: 554px;
    width: 100%
  }

  .quote__grid {
    flex-direction: column
  }

  .quote__grid>* {
    flex-basis: 100%;
    width: 100%
  }

  .quote__grid>:nth-child(2) {
    flex-basis: 100%;
    margin-top: 40px
  }

  .quote__grid .h2 {
    max-width: unset
  }

  .quote__employee {
    margin-top: 25px;
    max-width: unset
  }

  .quote__employee .employee__info-photo {
    max-width: 130px
  }

  .quote__employee .employee__info-content {
    margin-bottom: 10px;
    flex-basis: calc(100% - 150px)
  }
}

.quote-success {
  position: relative;
  background-image: url(../static/implant_dentalway__ru/build/i/d51cad7e01054b64c4f76dc7a3701b1b.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 80px;
  padding-bottom: 80px
}

.quote-success .h2 {
  max-width: 740px
}

.quote-success .block-label_medium {
  width: 212px;
  font-size: 76px;
  line-height: 69px
}

.quote-success .row {
  margin-right: auto
}

.quote-success__grid {
  align-items: center;
  justify-content: space-between;
  margin-left: -15px;
  margin-right: -15px;
  position: relative
}

.quote-success__grid>* {
  flex: 0 1 calc(100% - 520px);
  padding-left: 15px;
  padding-right: 15px
}

.quote-success__grid>:nth-child(2) {
  flex-basis: 520px
}

.quote-success__text {
  padding-left: 55px;
  position: relative;
  font-size: 18px;
  line-height: 26px;
  margin-top: 38px
}

.quote-success__text:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 31px;
  height: 21px;
  background-image: url(../static/implant_dentalway__ru/build/i/6a1c5d95fc8bdb293685d1da3a6fbd0b.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%
}

.quote-success__employee {
  margin-left: 0;
  padding-right: 15px
}

.quote-success__employee .get-price__employee__content {
  margin-top: 10px
}

@media screen and (max-width:1312px) {
  .quote-success {
    padding-top: 54px;
    padding-bottom: 52px
  }

  .quote-success .block-label {
    display: none
  }

  .quote-success:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 47px;
    height: 100%;
    background: linear-gradient(176deg, $color-primary, $color-primary 32.29%, #fff)
  }

  .quote-success .row {
    margin-right: auto
  }

  .quote-success__grid>* {
    flex-basis: calc(100% - 463px)
  }

  .quote-success__grid>:nth-child(2) {
    flex-basis: 463px
  }

  .quote-success__text {
    font-size: 16px;
    line-height: 24px;
    padding-left: 50px;
    margin-top: 22px;
    padding-right: 40px
  }

  .quote-success__employee .get-price__employee__content {
    margin-top: 0;
    margin-bottom: -6px
  }

  .quote-success__employee .get-price__employee__experience {
    margin-top: 26px
  }
}

@media screen and (max-width:1023px) {
  .quote-success {
    padding-top: 37px;
    padding-bottom: 39px
  }

  .quote-success:after {
    content: none
  }

  .quote-success .h2 {
    padding-right: 15px
  }

  .quote-success__grid {
    margin-left: -10px;
    margin-right: -10px
  }

  .quote-success__grid>* {
    flex-basis: calc(100% - 368px);
    padding-left: 10px;
    padding-right: 10px
  }

  .quote-success__grid>:nth-child(2) {
    flex-basis: 368px
  }

  .quote-success__employee {
    padding-right: 0
  }

  .quote-success__employee .get-price__employee__content {
    margin-bottom: 0
  }

  .quote-success__employee .get-price__employee__experience,
  .quote-success__employee .get-price__employee__profession {
    margin-top: 5px
  }

  .quote-success__text {
    font-size: 14px;
    line-height: 22px;
    padding-left: 30px;
    margin-top: 20px
  }

  .quote-success__text:before {
    width: 17px;
    height: 12px;
    top: 4px
  }
}

@media screen and (max-width:767px) {
  .quote-success {
    padding-top: 35px;
    padding-bottom: 32px
  }

  .quote-success .h2 {
    padding-right: 0
  }

  .quote-success:after {
    content: none
  }

  .quote-success__grid>* {
    flex-basis: 100%
  }

  .quote-success__text {
    padding-right: 0;
    padding-left: 25px
  }

  .quote-success__employee {
    margin-top: 25px
  }

  .quote-success__employee .get-price__employee__content {
    margin-bottom: 10px;
    flex-basis: calc(100% - 150px)
  }
}

// методы
.methods {
  margin-top: 97px;
  margin-bottom: 96px;

  &__grid {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 16px;

    &>* {
      flex: 0 1 50%;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 30px;
    }
  }

  &__image {
    position: relative;
    background-color: rgba(0, 140, 212, .3);
    border-radius: 10px;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      height: 0;
      padding-top: 71.42857%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__note {
    font-family: $font-geometria;
    font-weight: 400;
    font-style: italic;
    display: flex;
    align-items: center;
    position: relative;
    background-color: $color-primary-light;
    border-radius: 10px;
    overflow: hidden;
    max-width: 520px;
    margin-top: 20px;
    min-height: 105px;
    padding: 10px 27px 10px 130px;
    font-size: 14px;
    line-height: 22px;
    color: #333;
    z-index: 1;

    &>i {
      position: absolute;
      left: 38px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &:before {
      content: "";
      position: absolute;
      left: 6px;
      top: 0;
      width: 75px;
      height: 82px;
      background-image: url(../static/implant_dentalway__ru/build/i/633b541001a736af5f80dca94130d12c.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
      z-index: -1;
    }
  }

  &__content {
    padding-left: 60px;
    margin-top: 3px;

    p {
      &.strong {
        margin-top: 29px;
      }
    }

    .list {
      margin-top: 15px;

      &>li {
        margin-top: 15px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &__button {
    margin-top: 40px;
    position: relative;
    max-width: 540px;
    padding-right: 160px;

    &__image {
      position: absolute;
      right: 4px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &-after {
      font-family: $font-geometria;
      font-weight: 400;
      font-style: italic;
      color: #7f8f98;
      font-size: 16px;
      line-height: 22px;
      margin-top: 28px;
    }

    .btn {
      max-width: 362px;
    }
  }
}


@media screen and (max-width:1312px) {
  .methods {
    margin-top: 72px;
    margin-bottom: 72px
  }

  .methods__grid {
    margin-top: 9px
  }

  .methods__image-wrapper {
    padding-right: 50px
  }

  .methods__note {
    min-height: 146px
  }

  .methods__content {
    padding-left: 0;
    margin-left: -30px;
    margin-top: -7px
  }

  .methods__content p.strong {
    margin-top: 15px
  }

  .methods__content .list {
    margin-top: 14px
  }

  .methods__content .list>li {
    margin-top: 16px
  }

  .methods__button {
    margin-top: 23px;
    padding-right: 151px
  }

  .methods__button__image {
    right: 0
  }
}

@media screen and (max-width:1023px) {
  .methods {
    margin-top: 47px;
    margin-bottom: 44px
  }

  .methods .h2 {
    font-size: 27px
  }

  .methods__grid {
    margin-top: 6px
  }

  .methods__image-wrapper {
    padding-right: 33px
  }

  .methods__image {
    border-radius: 5px
  }

  .methods__note {
    padding-left: 101px;
    min-height: 160px
  }

  .methods__note>i {
    max-width: 58px;
    left: 21px
  }

  .methods__content p.strong {
    margin-top: 22px
  }

  .methods__content .list>li {
    margin-top: 13px
  }

  .methods__button {
    padding-right: 93px
  }

  .methods__button__image {
    max-width: 78px
  }

  .methods__button-after {
    font-size: 14px;
    line-height: 22px;
    margin-top: 21px
  }
}

@media screen and (max-width:767px) {
  .methods {
    margin-top: 35px;
    margin-bottom: 40px
  }

  .methods .h2 {
    font-size: 24px;
    line-height: 30px
  }

  .methods__grid>* {
    flex-basis: 100%;
    margin-top: 24px
  }

  .methods__grid>:nth-child(2) {
    order: -1
  }

  .methods__content {
    margin-left: 0
  }

  .methods__content p.strong {
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px
  }

  .methods__content .list {
    margin-top: 11px
  }

  .methods__content .list>li {
    margin-top: 8px
  }

  .methods__button {
    padding-right: 0
  }

  .methods__button__image {
    left: 0;
    top: auto;
    -webkit-transform: none;
    transform: none;
    bottom: -60px
  }

  .methods__button-after {
    font-size: 12px;
    line-height: 18px;
    padding-left: 85px;
    margin-top: 16px
  }

  .methods__image-wrapper {
    padding-right: 0
  }

  .methods__note {
    font-size: 12px;
    line-height: 20px;
    padding-left: 91px;
    padding-right: 13px;
    min-height: 146px
  }
}

@media screen and (max-width:414px) {
  .methods__button__image {
    bottom: -74px
  }
}

.problems {
  position: relative;
  margin-top: 92px
}

.problems__grid {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 27px
}

.problems__grid>* {
  flex: 0 1 50%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 34px
}

.problem__images-wrapper {
  padding: 30px 34px 58px 40px;
  background-color: $color-bg-gray;
  border-radius: 10px
}

.problem__images {
  margin-left: -11px;
  margin-right: -11px
}

.problem__images>* {
  flex: 0 1 50%;
  padding-left: 11px;
  padding-right: 11px
}

.problem__images i {
  position: relative;
  display: block;
  max-width: 267px;
  border-radius: 10px;
  overflow: hidden
}

.problem__images i:after {
  content: "";
  display: block;
  height: 0;
  padding-top: 71.91011%
}

.problem__images i img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%
}

.problem__text {
  font-family: $font-geometria;
  font-weight: 400;
  font-style: italic;
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-right: 34px;
  max-width: 520px;
  min-height: 54px;
  padding: 10px 25px 10px 58px;
  font-size: 14px;
  line-height: 18px;
  border-radius: 10px;
  overflow: hidden;
  background-color: $color-primary-light;
  margin-top: -28px;
  position: relative
}

.problem__text br {
  display: none
}

.problem__text:before {
  content: "";
  position: absolute;
  left: 12px;
  top: -9px;
  width: 50px;
  height: 55px;
  background-image: url(../static/implant_dentalway__ru/build/i/633b541001a736af5f80dca94130d12c.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top
}

.problem__text>span {
  position: relative;
  padding-left: 19px
}

.problem__text>span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 10px;
  height: 10px;
  background-color: $color-primary;
  border-radius: 50%
}

.problem__button {
  margin-left: 40px;
  margin-top: 21px
}

.problem__button .btn {
  max-width: 295px
}

@media screen and (max-width:1312px) {
  .problems {
    margin-top: 75px
  }

  .problems__grid {
    margin-top: 9px
  }

  .problems__grid>* {
    margin-top: 31px
  }

  .problem__images-wrapper {
    padding: 18px 20px 50px
  }

  .problem__images {
    margin-left: -5px;
    margin-right: -5px
  }

  .problem__images>* {
    padding-left: 5px;
    padding-right: 5px
  }

  .problem__text {
    margin-left: 20px;
    max-width: 380px;
    padding-right: 50px;
    min-height: 66px;
    margin-top: -30px;
    padding-top: 12px
  }

  .problem__text br {
    display: block
  }

  .problem__button {
    margin-left: 20px
  }

  .problem__button .btn {
    max-width: 244px
  }
}

@media screen and (max-width:1023px) {
  .problems {
    margin-top: 46px
  }

  .problems__grid {
    margin-top: 0
  }

  .problems__grid>* {
    margin-top: 30px
  }

  .problem__images-wrapper {
    padding: 10px 10px 30px;
    border-radius: 5px
  }

  .problem__images i,
  .problem__text {
    border-radius: 5px
  }

  .problem__text {
    margin-left: 10px;
    margin-top: -19px;
    padding-left: 31px;
    padding-top: 8px;
    padding-bottom: 7px;
    min-height: 54px
  }

  .problem__text br {
    display: none
  }

  .problem__button {
    margin-left: 10px;
    margin-top: 15px
  }

  .problem__button .btn {
    max-width: 202px
  }
}

@media screen and (max-width:767px) {
  .problems {
    margin-top: 36px
  }

  .problems__grid>* {
    flex-basis: 100%;
    margin-top: 40px
  }

  .problems__grid>:first-child {
    margin-top: 22px
  }

  .problem__text {
    width: 100%;
    max-width: 255px;
    padding-left: 21px;
    padding-right: 10px;
    margin-top: -21px
  }

  .problem__button {
    margin-top: 20px
  }
}

.plastic {
  position: relative;
  background-image: url(../static/implant_dentalway__ru/build/i/204612106d6a4f73bd9ddd4cde9696c7.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  margin-top: 100px;
  padding-top: 84px;
  padding-bottom: 92px
}

.plastic .block-label_large {
  width: 392px;
  font-size: 134px;
  line-height: 121px
}

.plastic .block-label_large>span {
  -webkit-transform: translate(-57.3%, -50%) rotate(-90deg);
  transform: translate(-57.3%, -50%) rotate(-90deg)
}

.plastic__grid {
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  z-index: 2
}

.plastic__grid>* {
  flex: 0 1 50%;
  padding-left: 15px;
  padding-right: 15px
}

.plastic__employee-wrapper {
  align-items: center;
  position: relative;
  margin-right: -15px
}

.plastic__employee__info {
  max-width: 198px;
  position: relative;
  z-index: 1;
  margin-top: -15px;

  @include respond-to(md) {
    position: absolute;
  }
}

.plastic__employee {
  box-shadow: 0 15px 25px rgba(0, 0, 0, .1);
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  padding: 20px 26px 3px
}

.plastic__employee-name {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 24px
}

.plastic__employee-profession {
  font-family: $font-geometria;
  font-weight: 400;
  font-style: italic;
  color: #7f8f98;
  font-size: 12px;
  line-height: 20px;
  margin-top: 9px
}

.plastic__employee-experience {
  font-family: $font-geometria;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  background-color: $color-primary;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  display: inline-block;
  padding: 5px 15px;
  -webkit-transform: translateY(19px);
  transform: translateY(19px)
}

.plastic__employee__image {
  position: relative;
  width: 100%;
  max-width: 459px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 120px;
}

.plastic__employee__image:after {
  content: "";
  display: block;
  height: 0;
  padding-top: 100%
}

.plastic__employee__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  width: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
  top: auto
}

.plastic__content {
  padding-left: 67px
}

.plastic__text {
  position: relative;
  padding-left: 23px;
  margin-top: 30px
}

.plastic__text:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $color-primary
}

.plastic__button {
  margin-top: 24px
}

.plastic__button .btn {
  max-width: 383px
}

.plastic__total {
  align-items: center;
  color: #fff;
  background-color: $color-primary;
  max-width: 410px;
  padding: 10px 40px 10px 168px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 40px;
  min-height: 122px;
  position: relative;
  z-index: 1
}

.plastic__total__bg-value {
  font-weight: 500;
  left: 6px;
  top: -37px;
  font-size: 100px;
  line-height: 125px;
  color: #00a1d3;
  z-index: -1
}

.plastic__total-value,
.plastic__total__bg-value {
  font-family: $font-geometria;
  font-style: normal;
  position: absolute
}

.plastic__total-value {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  left: 49px;
  top: 50%;
  -webkit-transform: translateY(-47%);
  transform: translateY(-47%)
}

.plastic__total-value b {
  font-size: 61px;
  line-height: 61px;
  color: #fff
}

.plastic__total-text {
  font-size: 16px;
  line-height: 26px
}

.plastic__video {
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  min-height: 198px;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .1);
  padding: 16px 8px 12px;
  margin-bottom: 30px
}

.plastic__video:before {
  content: "";
  position: absolute;
  left: 12px;
  top: 4px;
  width: 60px;
  height: 60px;
  background-image: url(../static/implant_dentalway__ru/build/i/633b541001a736af5f80dca94130d12c.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top
}

.plastic__video .btn_play {
  margin-left: auto;
  margin-right: auto
}

.plastic__video-title {
  font-weight: 500;
  font-style: normal;
  margin-top: 17px
}

.plastic__video-duration,
.plastic__video-title {
  font-family: $font-geometria;
  font-size: 14px;
  line-height: 18px
}

.plastic__video-duration {
  font-weight: 400;
  font-style: italic;
  color: #7f8f98;
  margin-top: 7px
}

@media screen and (max-width:1312px) {
  .plastic {
    margin-top: 77px;
    padding-top: 74px;
    padding-bottom: 88px
  }

  .plastic>.block-label_large {
    width: 290px;
    font-size: 120px;
    line-height: 108px
  }

  .plastic>.block-label_large>span {
    -webkit-transform: translate(-53.3%, -50%) rotate(-90deg);
    transform: translate(-53.3%, -50%) rotate(-90deg)
  }

  .plastic__employee-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    max-width: 390px
  }

  .plastic__employee__image {
    order: -1;
    max-width: 320px;
    margin-left: 15px
  }

  .plastic__employee__image img {
    max-width: 90%
  }

  .plastic__employee__info {
    display: flex;
    align-items: flex-start;
    max-width: calc(100% + 10px);
    justify-content: center;
    margin-left: -5px;
    margin-right: -5px;
    margin-top: -74px
  }

  .plastic__employee__info>* {
    max-width: 190px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px
  }

  .plastic__employee {
    margin-top: 0
  }

  .plastic__content {
    margin-left: -30px;
    padding-left: 0
  }

  .plastic__text {
    margin-top: 22px
  }

  .plastic__button {
    margin-top: 16px
  }

  .plastic__video {
    margin-bottom: 0
  }

  .plastic__video br {
    display: none
  }
}

@media screen and (max-width:1023px) {
  .plastic {
    margin-top: 59px;
    padding-top: 40px;
    padding-bottom: 46px
  }

  .plastic>.block-label_large {
    display: block;
    width: 180px
  }

  .plastic>.block-label_large>span {
    display: none
  }

  .plastic__grid>* {
    flex-basis: 41.66%
  }

  .plastic__grid>:nth-child(2) {
    flex-basis: 58.33%
  }

  .plastic__employee-wrapper {
    margin-right: 0
  }

  .plastic__employee__image {
    margin-left: 0
  }

  .plastic__employee__info {
    margin-left: 0;
    margin-right: 0;
    flex-wrap: wrap;
    margin-top: -52px
  }

  .plastic__employee__info>* {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0
  }

  .plastic__employee {
    order: -1;
    text-align: left;
    padding: 14px 16px 20px
  }

  .plastic__employee-name {
    font-size: 14px;
    line-height: 24px
  }

  .plastic__employee-profession {
    line-height: 18px;
    margin-top: 3px
  }

  .plastic__employee-experience {
    padding: 5px 13px;
    -webkit-transform: none;
    transform: none;
    margin-top: 4px
  }

  .plastic__content {
    margin-left: 0;
    margin-top: -10px
  }

  .plastic__text {
    margin-top: 18px
  }

  .plastic__text:before {
    top: 5px
  }

  .plastic__button {
    margin-top: 22px
  }

  .plastic__button .btn {
    max-width: 315px
  }

  .plastic__total {
    margin-top: 30px;
    min-height: 102px;
    padding-left: 154px;
    padding-right: 30px;
    border-radius: 5px
  }

  .plastic__total-value {
    left: 36px
  }

  .plastic__total-text {
    font-size: 14px;
    line-height: 22px
  }

  .plastic__video {
    margin-top: 10px;
    position: relative;
    text-align: left;
    padding: 10px 7px 9px 94px;
    min-height: 93px
  }

  .plastic__video .btn_play {
    position: absolute;
    left: 10px;
    top: 10px
  }

  .plastic__video-title {
    margin-top: 0
  }

  .plastic__video-duration {
    margin-top: 3px
  }
}

@media screen and (max-width:767px) {
  .plastic {
    margin-top: 40px;
    padding-top: 46px;
    background-image: none;
    background-color: $color-bg-gray
  }

  .plastic>.block-label_large {
    display: none
  }

  .plastic:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 205px;
    background: linear-gradient(219.35deg, $color-primary, rgba(0, 134, 210, 0));
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
  }

  .plastic__grid>* {
    flex-basis: 100%
  }

  .plastic__grid>:nth-child(2) {
    order: -1;
    flex-basis: 100%
  }

  .plastic__text {
    padding-left: 18px
  }

  .plastic__total {
    min-height: 84px;
    padding-left: 119px;
    padding-right: 30px;
    margin-top: 10px
  }

  .plastic__total-text {
    font-size: 12px;
    line-height: 18px
  }

  .plastic__total-value {
    left: 33px
  }

  .plastic__total-value b {
    font-size: 50px;
    line-height: 49px
  }

  .plastic__employee-wrapper {
    margin-right: auto;
    margin-left: auto
  }

  .plastic__employee__image {
    max-width: 176px;
    margin-top: 30px
  }

  .plastic__employee__image img {
    max-width: 90%
  }

  .plastic__employee__info {
    margin-top: -31px
  }

  .plastic__employee-experience {
    font-size: 12px;
    line-height: 20px;
    padding: 5px 20px
  }

  .plastic__video {
    padding-left: 89px;
    padding-right: 28px;
    padding-top: 8px;
    min-height: 82px
  }

  .plastic__video-title {
    font-size: 12px;
    line-height: 16px
  }

  .plastic__video-duration {
    font-size: 12px;
    line-height: 18px;
    margin-top: 0
  }
}

// томография
.tomography {
  margin-top: 93px;

  &__grid {
    margin-left: -15px;
    margin-right: -15px;

    &>* {
      flex: 0 1 50%;
      max-width: 50%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__text {
    position: relative;
    font-size: 18px;
    line-height: 140%;
    padding-left: 23px;
    max-width: 560px;
    margin-top: 30px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 8px;
      width: 10px;
      height: 10px;
      background-color: $color-primary;
      border-radius: 50%;
    }
  }

  &__note {
    font-family: $font-geometria;
    font-weight: 700;
    font-style: normal;
    display: flex;
    align-items: center;
    position: relative;
    min-height: 160px;
    font-size: 22px;
    line-height: 28px;
    max-width: 570px;
    padding: 10px 165px 10px 40px;
    z-index: 1;
    margin-top: 30px;

    &>div {
      span {
        color: $color-primary;
      }
    }

    &>i {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% - 52px);
      height: 100%;
      background-color: $color-primary-light;
      border-radius: 10px;
      z-index: -1;
    }
  }

  &__button {
    margin-top: 40px;

    .btn {
      max-width: 330px;
    }
  }

  &__cases {
    margin-left: -15px;
    margin-right: -15px;

    &>* {
      flex: 0 1 50%;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 19px;
    }
  }

  &__case {
    &__image {
      position: relative;
      background-color: rgba(0, 140, 212, .3);
      max-width: 300px;
      border-radius: 10px;
      overflow: hidden;

      &:after {
        content: "";
        display: block;
        height: 0;
        padding-top: 130.66667%;
      }

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
      }
    }

    &__note {
      font-family: $font-geometria;
      font-weight: 500;
      font-style: normal;
      display: flex;
      align-items: center;
      position: relative;
      z-index: 1;
      font-size: 14px;
      line-height: 18px;
      color: #fff;
      background-color: $color-primary;
      border-radius: 10px;
      overflow: hidden;
      max-width: 229px;
      min-height: 88px;
      margin-left: 20px;
      margin-top: -35px;
      padding: 10px 25px 10px 92px;

      &>i {
        position: absolute;
        left: 10px;
        width: 76px;
        text-align: center;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }

      &:before {
        content: "";
        position: absolute;
        left: 5px;
        top: 0;
        width: 42px;
        height: 51px;
        background-image: url(../static/implant_dentalway__ru/build/i/0be3c47fcfbf0251a3c369c823232c2b.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
        z-index: -1;
      }
    }
  }
}

@media screen and (max-width:1312px) {
  .tomography {
    margin-top: 75px
  }

  .tomography .h2 {
    display: none
  }

  .tomography .h2_mobile {
    display: block
  }

  .tomography__content {
    padding-right: 30px
  }

  .tomography__text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 34px
  }

  .tomography__note {
    max-width: 400px;
    margin-top: 33px;
    font-size: 18px;
    line-height: 23px;
    padding-left: 25px;
    padding-right: 155px
  }

  .tomography__note:before {
    width: calc(100% - 80px)
  }

  .tomography__button .btn {
    max-width: 264px
  }

  .tomography__cases {
    margin-left: -41px;
    margin-right: -11px
  }

  .tomography__cases>* {
    padding-left: 11px;
    padding-right: 11px;
    margin-top: 9px
  }

  .tomography__case__note {
    margin-left: 0;
    margin-top: 10px
  }
}

@media screen and (max-width:1023px) {
  .tomography {
    margin-top: 44px
  }

  .tomography__content {
    padding-right: 33px
  }

  .tomography__text {
    font-size: 14px;
    line-height: 22px
  }

  .tomography__note {
    font-size: 14px;
    line-height: 18px;
    margin-top: 22px;
    padding-left: 20px;
    min-height: 124px;
    padding-top: 11px;
    padding-right: 115px
  }

  .tomography__note>i {
    max-width: 102px
  }

  .tomography__note:before {
    border-radius: 5px;
    width: calc(100% - 63px)
  }

  .tomography__button {
    margin-top: 30px
  }

  .tomography__cases {
    margin-left: -35px;
    margin-right: -5px
  }

  .tomography__cases>* {
    padding-left: 5px;
    padding-right: 5px
  }

  .tomography__case__note {
    padding-left: 65px;
    padding-right: 10px;
    min-height: 68px;
    border-radius: 5px
  }

  .tomography__case__note>i {
    -webkit-transform: scale(.65) translateY(-50%);
    transform: scale(.65) translateY(-50%);
    -webkit-transform-origin: top left;
    transform-origin: top left
  }

  .tomography__case__image {
    border-radius: 5px
  }
}

@media screen and (max-width:767px) {
  .tomography {
    margin-top: 34px
  }

  .tomography__grid>* {
    flex-basis: 100%;
    max-width: 100%
  }

  .tomography__content {
    padding-right: 0
  }

  .tomography__text {
    font-size: 12px;
    line-height: 18px;
    padding-left: 18px;
    margin-top: 9px
  }

  .tomography__text:before {
    top: 3px
  }

  .tomography__note {
    font-size: 12px;
    line-height: 15px;
    margin-top: 14px;
    min-height: 109px;
    padding-right: 122px
  }

  .tomography__note:before {
    width: calc(100% - 36px);
    background-color: $color-bg-gray
  }

  .tomography__button {
    margin-top: 20px
  }

  .tomography__button .btn {
    max-width: 235px
  }

  .tomography__cases {
    margin-left: -4px;
    margin-right: -4px;
    margin-top: 20px
  }

  .tomography__cases>* {
    padding-left: 4px;
    padding-right: 4px;
    margin-top: 0
  }

  .tomography__case__note {
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 42px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-top: 6px
  }

  .tomography__case__note>i {
    left: 50%;
    top: 8px;
    -webkit-transform: translateX(-50%) scale(.55);
    transform: translateX(-50%) scale(.55);
    -webkit-transform-origin: top center;
    transform-origin: top center
  }

  .tomography__case__note>i.second {
    top: 11px
  }
}

// best price
.best-price {
  margin-top: 100px;
  position: relative;
  padding-top: 96px;
  padding-bottom: 103px;
  background-image: url(../static/implant_dentalway__ru/build/i/f5017ebd6714dc65ed074480a4ff8b41.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  .block-label_large {
    width: 386px;
    font-size: 165px;
    line-height: 149px;

    &>span {
      -webkit-transform: translate(-43%, -50%) rotate(-90deg);
      transform: translate(-43%, -50%) rotate(-90deg);
    }
  }

  // сетка
  &__grid {
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;

    &>* {
      flex: 0 1 58.33%;
      padding-left: 15px;
      padding-right: 15px;
    }

    &> :nth-child(2) {
      flex-basis: 41.66%;
    }
  }

  &__content {
    .list {
      margin-top: 20px;

      &>li {
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  &__research {
    display: flex;
    align-items: center;
    min-height: 70px;
    font-size: 14px;
    line-height: 20px;
    color: #8e8e8e;
    position: relative;
    padding-left: 81px;
    margin-top: 22px;

    &>i {
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    a {
      ont-family: Geometria,
        sans-serif;
      font-style: normal;
      font-weight: 700;
      color: #8e8e8e;
    }
  }

  &__installment {
    font-family: Geometria,
      sans-serif;
    font-style: normal;
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    padding: 16px 35px 15px 106px;
    background-color: $color-primary;
    border-radius: 10px;
    overflow: hidden;
    color: #fff;
    max-width: 450px;
    min-height: 60px;
    margin-top: 34px;
    font-size: 18px;

    &>i {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      left: 26px;
    }
  }

  &__video {
    display: flex;
    align-items: center;
    max-width: 550px;
    // background-color: $color-primary-light;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    min-height: 105px;
    margin-top: 30px;
    padding: 10px 17px 10px 141px;
    z-index: 1;

    .btn_play {
      position: absolute;
      left: 48px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .best-price__video-text {
      font-family: $font-geometria;
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      line-height: 18px;
      color: #7f8f98;

      b {
        font-family: $font-geometria;
        font-weight: 500;
        font-style: normal;
        display: block;
        margin-bottom: 8px;
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: 8px;
      top: -8px;
      width: 70px;
      height: 66px;
      background-image: url(../static/implant_dentalway__ru/build/i/5bfc831ff2d251e4e4dc30c595e42caf.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
      z-index: -1;
    }
  }

  &__quote-wrapper {
    position: relative;
    // max-width: 410px;
    margin-left: auto;
    margin-right: 0;
    margin-top: 24px;
  }

  &__image {
    position: relative;
    max-width: 359px;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;

    &:after {
      content: "";
      display: block;
      height: 0;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__quote {
    position: relative;
    // background-color: $color-primary-light;
    background-color: #fff;
    z-index: 1;
    padding: 45px 40px 48px 90px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: -50px;

    &:before {
      content: "";
      position: absolute;
      left: 6px;
      top: -13px;
      width: 97px;
      height: 64px;
      background-image: url(../static/implant_dentalway__ru/build/i/c2b0b8a85e7a38fee0795d3786854775.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
      z-index: -1;
    }

    .best-price__quote-text {
      position: relative;
      font-size: 16px;
      line-height: 26px;

      &:before {
        content: "";
        position: absolute;
        left: -50px;
        top: 0;
        width: 30px;
        height: 20px;
        background-image: url(../static/implant_dentalway__ru/build/i/6a1c5d95fc8bdb293685d1da3a6fbd0b.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
      }
    }

    .best-price__quote-author {
      margin-top: 24px;
      font-size: 18px;
      line-height: 20px;

      br {
        display: none;
      }

      &>span {
        font-family: $font-geometria;
        font-weight: 400;
        font-style: italic;
        font-size: 12px;
        line-height: 20px;
        color: #7f8f98;
        margin-top: 10px;
        display: block;
      }
    }
  }
}


@media screen and (max-width:1312px) {
  .best-price {
    margin-top: 82px;
    padding-top: 64px;
    padding-bottom: 72px
  }

  .best-price>.block-label_large {
    width: 293px;
    font-size: 141px;
    line-height: 127px
  }

  .best-price>.block-label_large>span {
    -webkit-transform: translate(-45.5%, -50%) rotate(-90deg);
    transform: translate(-45.5%, -50%) rotate(-90deg)
  }

  .best-price__grid>* {
    flex-basis: 66.66%
  }

  .best-price__grid>:nth-child(2) {
    flex-basis: 33.33%
  }

  .best-price__research {
    padding-left: 58px;
    font-size: 14px;
    line-height: 20px;
    min-height: 50px;
    margin-top: 19px
  }

  .best-price__research>i {
    max-width: 47px
  }

  .best-price__content {
    max-width: 550px
  }

  .best-price__content .list {
    margin-top: 14px
  }

  .best-price__content .list>li {
    margin-top: 17px
  }

  .best-price__installment {
    max-width: 388px;
    font-size: 16px;
    line-height: 18px;
    padding-left: 87px;
    padding-right: 25px;
    margin-top: 27px;
    min-height: 53px
  }

  .best-price__installment>i {
    max-width: 54px;
    left: 16px
  }

  .best-price__video {
    margin-top: 10px
  }

  .best-price__quote-wrapper {
    margin-top: -6px
  }

  .best-price__image {
    max-width: 272px
  }

  .best-price__quote {
    margin-top: -42px;
    padding: 23px 20px
  }

  .best-price__quote:before {
    width: 83px;
    height: 55px
  }

  .best-price__quote .best-price__quote-text {
    padding-left: 0;
    padding-top: 25px;
    line-height: 24px
  }

  .best-price__quote .best-price__quote-text:before {
    left: 0;
    top: 0;
    width: 24px;
    height: 16px
  }

  .best-price__quote .best-price__quote-author {
    padding-right: 15px;
    margin-top: 14px;
    font-size: 16px;
    line-height: 20px
  }

  .best-price__quote .best-price__quote-author>span {
    margin-top: 7px
  }
}

@media screen and (max-width:1023px) {
  .best-price {
    margin-top: 50px;
    padding-top: 44px;
    padding-bottom: 41px
  }

  .best-price>.block-label_large {
    display: block;
    width: 180px
  }

  .best-price>.block-label_large>span {
    display: none
  }

  .best-price__grid {
    align-items: center
  }

  .best-price__research {
    margin-top: 14px
  }

  .best-price__content {
    padding-right: 33px
  }

  .best-price__content .list {
    margin-top: 7px
  }

  .best-price__content .list>li {
    margin-top: 8px
  }

  .best-price__installment {
    max-width: 348px;
    padding-left: 87px;
    padding-right: 10px;
    font-size: 14px;
    border-radius: 5px;
    margin-top: 24px
  }

  .best-price__video {
    padding-left: 98px;
    max-width: 315px;
    min-height: 93px
  }

  .best-price__video .btn_play {
    left: 10px
  }

  .best-price__video .best-price__video-text {
    margin-top: 4px
  }

  .best-price__video .best-price__video-text b {
    margin-bottom: 6px
  }

  .best-price__quote-wrapper {
    margin-left: -30px;
    margin-top: 4px
  }

  .best-price__image {
    max-width: 170px
  }

  .best-price__quote {
    margin-top: -20px;
    padding: 15px 20px;
    border-radius: 5px
  }

  .best-price__quote .best-price__quote-text {
    font-size: 14px;
    line-height: 22px
  }

  .best-price__quote .best-price__quote-author {
    font-size: 14px;
    line-height: 20px;
    margin-top: 9px
  }

  .best-price__quote .best-price__quote-author br {
    display: block
  }

  .best-price__quote .best-price__quote-author>span {
    margin-top: 0
  }
}

@media screen and (max-width:767px) {
  .best-price {
    margin-top: 41px;
    padding-top: 35px;
    z-index: 1;
    background-image: none;
    background-color: $color-bg-gray
  }

  .best-price>.block-label_large {
    display: none
  }

  .best-price:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 174px;
    background: linear-gradient(219.35deg, $color-primary, rgba(0, 134, 210, 0));
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    z-index: -1
  }

  .best-price__grid>*,
  .best-price__grid>:nth-child(2) {
    flex-basis: 100%
  }

  .best-price__research {
    font-size: 14px;
    line-height: 18px;
    padding-left: 55px
  }

  .best-price__content {
    padding-right: 0
  }

  .best-price__content .list {
    margin-top: 11px
  }

  .best-price__content .list>li {
    margin-top: 13px
  }

  .best-price__installment {
    padding: 7px 44px 6px 91px
  }

  .best-price__installment>i {
    left: 20px
  }

  .best-price__video {
    min-height: 80px;
    padding-left: 84px;
    padding-right: 10px
  }

  .best-price__video .best-price__video-text {
    font-size: 12px;
    line-height: 18px
  }

  .best-price__video .best-price__video-text b {
    font-size: 14px;
    margin-bottom: 2px
  }

  .best-price__quote-wrapper {
    margin-left: 0;
    margin-top: 32px
  }

  .best-price__quote {
    margin-top: -29px;
    padding: 15px 23px 17px
  }

  .best-price__quote .best-price__quote-text {
    font-size: 12px;
    line-height: 20px;
    padding-top: 20px
  }

  .best-price__quote .best-price__quote-text:before {
    width: 19px;
    height: 13px
  }

  .best-price__quote .best-price__quote-author {
    margin-top: 5px
  }

  .best-price__quote .best-price__quote-author br {
    display: none
  }

  .best-price__quote .best-price__quote-author>span {
    margin-top: 5px
  }
}

// диагностика
.diagnostic {
  margin-top: 94px;

  &__grid {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 17px;

    &>* {
      flex: 0 1 50%;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 30px;
    }
  }

  &__image {
    position: relative;
    background-color: rgba(0, 140, 212, .3);
    max-width: 630px;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;

    &__icons_adaptive {
      display: none;
    }

    &:after {
      content: "";
      display: block;
      height: 0;
      padding-top: 71.42857%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__title {
    margin-top: 33px;
  }

  &__text {
    margin-top: 27px;
    margin-right: -344px;
    position: relative;
    z-index: 2;

    &>* {
      align-items: center;
      flex-basis: calc(100% - 275px);
      min-height: 164px;
      border-radius: 10px;
      background-color: $color-primary-light;
    }

    &> :nth-child(2) {
      margin-left: 10px;
      flex-basis: 265px;
      background-color: $color-primary;
    }

    .text {
      font-size: 18px;
      line-height: 28px;
      padding: 40px 28px 40px 40px;

      &>span {
        position: relative;
        padding-left: 23px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 8px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $color-primary;
        }
      }
    }

    .icons {
      font-family: $font-geometria;
      font-weight: 700;
      font-style: normal;
      font-size: 26px;
      color: #fff;
      padding: 10px;
      position: relative;
      overflow: hidden;
      z-index: 1;

      .plus {
        display: inline-block;
        margin-top: 5px;

        &:nth-child(4) {
          margin-left: -16px;
        }

        &:last-child {
          display: none;
        }
      }

      img {
        &:nth-child(3) {
          margin-left: -3px;
        }

        &:last-child {
          margin-left: -14px;
        }
      }

      &:before {
        content: "";
        position: absolute;
        right: 10px;
        top: -8px;
        width: 68px;
        height: 64px;
        background-image: url(../static/implant_dentalway__ru/build/i/fb0a1f37ad88d81c9e11df3c400020bf.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top;
        z-index: -1;
      }
    }
  }

  &__button {
    align-items: center;
    margin-top: 30px;

    &__text {
      font-family: $font-geometria;
      font-weight: 400;
      font-style: italic;
      font-size: 16px;
      line-height: 22px;
      color: #6b6b6b;
    }

    &>* {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .btn {
      max-width: 298px;
      margin-right: 20px;
    }

    .diagnostic__button__text {
      flex: 0 1 calc(100% - 318px);
    }
  }
}

.step {
  &__count {
    display: flex;
    align-items: center;
    position: relative;

    &>span {
      font-family: Geometria,
        sans-serif;
      display: inline-block;
      line-height: 28px;
      font-weight: 500;
      font-style: italic;
      color: #fff;
      font-size: 22px;
      background-color: $color-primary;
      padding: 11px 19px 10px 20px;
      border-radius: 10px;
    }

    &:after {
      font-family: Geometria,
        sans-serif;
      display: inline-block;
      line-height: 28px;
      font-weight: 700;
      font-style: normal;
      content: attr(data-number);
      font-size: 60px;
      color: #f2f0ed;
      margin-left: 24px;
      margin-bottom: -8px;
    }
  }
}

@media screen and (max-width:1312px) {
  .diagnostic {
    margin-top: 60px
  }

  .diagnostic .h2 {
    margin-right: -24px
  }

  .diagnostic__grid {
    align-items: center
  }

  .step__count {
    margin-top: 2px
  }

  .step__count>span {
    font-size: 18px;
    line-height: 23px;
    border-radius: 5px;
    padding: 7px 16px 5px 11px
  }

  .step__count:after {
    font-size: 50px;
    margin-left: 8px
  }

  .diagnostic__title {
    padding-left: 0;
    margin-top: 17px
  }

  .diagnostic__title:beforE {
    content: none
  }

  .diagnostic__text {
    margin-top: 19px;
    margin-right: -256px
  }

  .diagnostic__text>* {
    min-height: 124px;
    flex-basis: calc(100% - 240px)
  }

  .diagnostic__text>:nth-child(2) {
    flex-basis: 231px;
    margin-left: 9px
  }

  .diagnostic__text .text {
    font-size: 16px;
    line-height: 24px;
    padding: 16px 24px 12px 17px
  }

  .diagnostic__text .icons {
    padding-top: 14px
  }

  .diagnostic__text .icons>:first-child {
    max-width: 67px
  }

  .diagnostic__text .icons>:nth-child(3) {
    max-width: 74px
  }

  .diagnostic__text .icons>:nth-child(5) {
    max-width: 52px
  }

  .diagnostic__button {
    flex-wrap: wrap;
    margin-top: 19px
  }

  .diagnostic__button .diagnostic__button__text {
    flex-basis: 100%;
    max-width: 445px
  }

  .diagnostic__image {
    margin-top: 0;
    margin-left: 50px
  }
}

@media screen and (max-width:1023px) {
  .diagnostic {
    margin-top: 42px
  }

  .diagnostic .h2 {
    margin-right: 0
  }

  .diagnostic__grid {
    margin-top: 0
  }

  .diagnostic__grid>* {
    margin-top: 26px
  }

  .step__count>span {
    font-size: 16px;
    line-height: 20px
  }

  .step__count:after {
    font-size: 40px
  }

  .diagnostic__title {
    margin-top: 22px
  }

  .diagnostic__text {
    margin-right: -210px
  }

  .diagnostic__text>* {
    flex-basis: calc(100% - 136px);
    border-radius: 5px;
    min-height: 124px
  }

  .diagnostic__text>:nth-child(2) {
    flex-basis: 126px;
    margin-left: 10px
  }

  .diagnostic__text .text {
    font-size: 14px;
    line-height: 22px;
    padding: 12px 24px 12px 11px
  }

  .diagnostic__text .text>span:before {
    top: 4px
  }

  .diagnostic__text .icons>:first-child {
    max-width: 56px;
    position: absolute;
    left: 11px;
    bottom: 15px
  }

  .diagnostic__text .icons>:nth-child(2) {
    position: absolute;
    left: 34px;
    top: 44px
  }

  .diagnostic__text .icons>:nth-child(3) {
    max-width: 61px;
    position: absolute;
    z-index: 1;
    top: 14px;
    left: 45px
  }

  .diagnostic__text .icons>:nth-child(4) {
    position: absolute;
    right: 30px;
    top: 44px;
    margin-left: 0
  }

  .diagnostic__text .icons>:nth-child(5) {
    max-width: 45px;
    position: absolute;
    right: 9px;
    bottom: 25px;
    margin-left: 0
  }

  .diagnostic__text .icons .plus {
    font-size: 18px
  }

  .diagnostic__button {
    margin-top: 21px;
    margin-right: -30px
  }

  .diagnostic__button .btn {
    max-width: 267px
  }

  .diagnostic__button .diagnostic__button__text {
    font-size: 14px;
    line-height: 22px
  }

  .diagnostic__image {
    margin-left: 33px;
    border-radius: 5px
  }
}

@media screen and (max-width:767px) {
  .diagnostic {
    margin-top: 37px
  }

  .diagnostic__grid>* {
    flex-basis: 100%;
    margin-top: 0
  }

  .diagnostic__content {
    margin-top: 13px
  }

  .diagnostic__title {
    margin-top: 24px
  }

  .diagnostic__text {
    margin-right: 0;
    margin-top: 13px
  }

  .diagnostic__text>* {
    flex-basis: 100%
  }

  .diagnostic__text>:nth-child(2) {
    flex-basis: 100%;
    margin-left: 0;
    display: none
  }

  .diagnostic__text .text {
    padding-right: 20px
  }

  .diagnostic__text .text>span {
    padding-left: 17px
  }

  .diagnostic__button {
    margin-top: 7px;
    margin-right: 0
  }

  .diagnostic__button .diagnostic__button__text {
    font-size: 12px;
    line-height: 20px;
    margin-top: 5px
  }

  .diagnostic__image__icons_adaptive {
    font-family: $font-geometria;
    font-weight: 700;
    font-style: normal;
    display: flex;
    align-items: center;
    background-color: $color-primary;
    border-radius: 5px;
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    height: 63px;
    position: relative;
    z-index: 2;
    font-size: 20px;
    color: #fff;
    padding: 0 18px;
    margin-top: 5px
  }

  .diagnostic__image__icons_adaptive>* {
    margin-top: 8px
  }

  .diagnostic__image__icons_adaptive>:first-child {
    max-width: 61px
  }

  .diagnostic__image__icons_adaptive>:nth-child(2) {
    margin-left: 9px
  }

  .diagnostic__image__icons_adaptive>:nth-child(3) {
    max-width: 70px;
    margin-left: 1px
  }

  .diagnostic__image__icons_adaptive>:nth-child(4) {
    margin-left: -2px
  }

  .diagnostic__image__icons_adaptive>:nth-child(5) {
    max-width: 50px;
    margin-left: -10px
  }

  .diagnostic__image {
    margin-left: 0;
    margin-top: -13px
  }
}

// заявка
.order {
  padding-top: 100px;
  padding-bottom: 62px;
  background-color: $color-bg-gray;

  &__grid {
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;

    &>* {
      flex: 0 1 50%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__image {
    position: relative;
    background-color: rgba(0, 140, 212, .3);
    max-width: 630px;
    border-radius: 10px;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      height: 0;
      padding-top: 71.42857%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__item {
    margin-right: -344px;
    position: relative;
    z-index: 6;
    margin-top: 40px;

    &>* {
      align-items: center;
      flex-basis: calc(100% - 275px);
      min-height: 134px;
      border-radius: 10px;
      background-color: $color-primary-light;
    }

    &>.order__item-note {
      margin-left: 10px;
      flex-basis: 265px;
      background-color: $color-primary;
    }

    .order__item-text {
      font-size: 18px;
      line-height: 28px;
      padding: 40px 28px 40px 40px;

      &>span {
        position: relative;
        padding-left: 23px;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 8px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $color-primary;
        }
      }
    }

    .order__item-note {
      display: flex;
      align-items: center;
      font-family: $font-geometria;
      font-weight: 400;
      font-style: italic;
      color: #fff;
      font-size: 14px;
      line-height: 20px;
      padding: 10px 19px 10px 115px;
      position: relative;

      &>i {
        position: absolute;
        top: 50%;
        left: 13px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }

  &__button {
    margin-top: 40px;

    .btn {
      max-width: 298px;
    }
  }
}

@media screen and (max-width:1312px) {
  .order {
    padding-top: 70px;
    padding-bottom: 67px
  }

  .order .h2 br {
    display: none
  }

  .order__grid {
    align-items: flex-start
  }

  .order__content {
    margin-right: -40px
  }

  .order__image {
    margin-left: 50px
  }

  .order__item {
    margin-top: 30px;
    margin-right: -216px
  }

  .order__item>* {
    flex-basis: calc(100% - 240px);
    min-height: 124px
  }

  .order__item>.order__item-note {
    flex-basis: 231px;
    margin-left: 9px
  }

  .order__item .order__item-text {
    padding: 16px 25px 12px 17px;
    font-size: 16px;
    line-height: 24px
  }

  .order__item .order__item-note {
    padding-left: 87px;
    padding-right: 13px
  }

  .order__item .order__item-note>i {
    max-width: 70px;
    left: 9px
  }

  .order__button {
    margin-top: 30px
  }
}

@media screen and (max-width:1023px) {
  .order {
    padding-top: 47px;
    padding-bottom: 50px
  }

  .order__grid {
    align-items: center
  }

  .order__item {
    margin-top: 20px;
    margin-right: -170px
  }

  .order__item>* {
    border-radius: 5px;
    flex-basis: calc(100% - 168px)
  }

  .order__item>.order__item-note {
    flex-basis: 158px
  }

  .order__item .order__item-text {
    font-size: 14px;
    line-height: 22px;
    padding: 14px 25px 12px 11px
  }

  .order__item .order__item-text>span:before {
    top: 3px
  }

  .order__item .order__item-note {
    padding: 48px 7px 12px;
    text-align: center;
    font-size: 12px;
    line-height: 16px
  }

  .order__item .order__item-note>i {
    max-width: 38px;
    top: 6px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }

  .order__button .btn {
    max-width: 267px
  }

  .order__image {
    margin-left: 33px;
    border-radius: 5px;
    margin-top: 9px
  }
}

@media screen and (max-width:767px) {
  .order {
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    z-index: 1
  }

  .order:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 175px;
    background: linear-gradient(219.35deg, $color-primary, rgba(0, 134, 210, 0));
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    z-index: -1
  }

  .order__grid>* {
    flex-basis: 100%
  }

  .order__content,
  .order__item {
    margin-right: 0
  }

  .order__item {
    margin-top: 12px
  }

  .order__item>*,
  .order__item>.order__item-note {
    flex-basis: 100%
  }

  .order__item .order__item-text {
    padding: 12px 20px 11px 10px;
    min-height: 111px
  }

  .order__item .order__item-text>span {
    padding-left: 18px
  }

  .order__item .order__item-note {
    margin-left: 0;
    margin-top: 10px;
    min-height: 111px;
    text-align: left;
    font-size: 14px;
    line-height: 22px;
    padding-left: 88px;
    padding-top: 10px;
    padding-right: 12px
  }

  .order__item .order__item-note>i {
    max-width: 62px;
    left: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }

  .order__button {
    margin-top: 20px
  }

  .order__button .btn {
    max-width: 225px
  }

  .order__image {
    margin-left: 0;
    margin-top: 30px
  }
}

// без боли
.not-pain {
  padding-top: 97px;
  padding-bottom: 104px;
  position: relative;

  &>.block-label_large {
    width: 392px;
    font-size: 134px;
    line-height: 121px;

    &>span {
      -webkit-transform: translate(-57.5%, -50%) rotate(-90deg);
      transform: translate(-57.5%, -50%) rotate(-90deg);
    }
  }

  &__composition {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    min-width: 883px;

    &__one {
      position: absolute;
      left: 23px;
      bottom: 0;
      z-index: 0;
    }

    &__two {
      position: absolute;
      left: -7px;
      bottom: 332px;
    }
  }

  &__image {
    position: absolute;
    left: 183px;
    bottom: 0;
    z-index: 1;
  }

  &__grid {
    margin-left: -15px;
    margin-right: -15px;
    position: relative;
    z-index: 2;

    &>* {
      flex: 0 1 41.66%;
      padding-left: 15px;
      padding-right: 15px;
    }

    &> :nth-child(2) {
      flex-basis: 58.33%;
    }
  }

  &__content {
    padding-left: 41px;

    .h2 {
      margin-top: 32px;
    }
  }

  &__text {
    background-color: $color-primary-light;
    border-radius: 10px;
    padding: 30px 40px;
    font-size: 18px;
    line-height: 28px;
    margin-top: 28px;

    &>span {
      position: relative;
      display: block;
      padding-left: 23px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $color-primary;
      }
    }
  }

  &__note {
    font-family: $font-geometria;
    font-weight: 400;
    font-style: italic;
    display: flex;
    align-items: center;
    background-color: $color-primary;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 10px;
    min-height: 128px;
    padding: 10px 40px 12px 156px;
    font-size: 14px;
    line-height: 22px;
    color: #fff;
    position: relative;

    &>i {
      position: absolute;
      left: 40px;
      top: 50%;
      -webkit-transform: translateY(-51%);
      transform: translateY(-51%);
    }
  }
}

@media screen and (max-width:1312px) {
  .not-pain {
    padding-top: 66px;
    padding-bottom: 69px
  }

  .not-pain>.block-label_large {
    width: 207px;
    font-size: 104px;
    line-height: 94px
  }

  .not-pain>.block-label_large>span {
    -webkit-transform: translate(-50.5%, -51.5%) rotate(-90deg);
    transform: translate(-50.5%, -51.5%) rotate(-90deg)
  }

  .not-pain__composition__one {
    left: -114px;
    max-width: 605px
  }

  .not-pain__composition__two {
    left: -136px;
    max-width: 262px;
    bottom: 252px
  }

  .not-pain__image {
    max-width: 532px;
    left: -3px
  }

  .not-pain__grid>*,
  .not-pain__grid>:nth-child(2) {
    flex-basis: 50%
  }

  .not-pain__content {
    padding-left: 0;
    margin-left: -16px
  }

  .not-pain__content .h2 {
    margin-top: 17px
  }

  .not-pain__text {
    margin-top: 21px;
    font-size: 16px;
    line-height: 24px;
    padding: 16px 24px 12px 17px
  }

  .not-pain__note {
    padding: 14px 16px 14px 125px
  }

  .not-pain__note>i {
    left: 13px
  }
}

@media screen and (max-width:1023px) {
  .not-pain {
    padding-top: 48px;
    padding-bottom: 50px
  }

  .not-pain>.block-label_large {
    display: block;
    width: 141px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1)
  }

  .not-pain>.block-label_large>span {
    display: none
  }

  .not-pain__image {
    max-width: 504px;
    left: -83px
  }

  .not-pain__composition__one {
    max-width: 506px;
    left: -102px
  }

  .not-pain__composition__two {
    max-width: 219px;
    margin-left: -120px
  }

  .not-pain__grid>* {
    flex-basis: 41.66%
  }

  .not-pain__grid>:nth-child(2) {
    flex-basis: 58.33%
  }

  .not-pain__content {
    margin-left: -30px
  }

  .not-pain__content .h2 {
    margin-top: 22px
  }

  .not-pain__text {
    border-radius: 5px;
    font-size: 14px;
    line-height: 22px;
    padding: 16px 13px 19px 11px
  }

  .not-pain__text>span:before {
    top: 4px
  }

  .not-pain__note {
    padding-left: 101px;
    border-radius: 5px
  }

  .not-pain__note>i {
    max-width: 76px
  }
}

@media screen and (max-width:767px) {
  .not-pain {
    padding-top: 38px;
    padding-bottom: 315px;
    z-index: 1
  }

  .not-pain>.block-label_large {
    display: none
  }

  .not-pain:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(219.35deg, $color-primary, rgba(0, 134, 210, 0));
    width: 100%;
    height: 219px;
    z-index: -1;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
  }

  .not-pain__composition {
    min-width: 100%
  }

  .not-pain__composition__one {
    width: 350px;
    left: -29px
  }

  .not-pain__composition__two {
    width: 152px;
    margin-left: 0;
    left: -46px;
    bottom: 145px
  }

  .not-pain__image {
    width: 327px;
    left: 71px
  }

  .not-pain__grid>*,
  .not-pain__grid>:nth-child(2) {
    flex-basis: 100%
  }

  .not-pain__content {
    margin-left: 0
  }

  .not-pain__text {
    margin-top: 15px;
    padding: 11px 13px 10px 10px
  }

  .not-pain__text>span {
    padding-left: 18px
  }

  .not-pain__note {
    width: 100%;
    padding: 72px 15px 12px 16px;
    font-size: 12px;
    line-height: 20px
  }

  .not-pain__note>i {
    max-width: 65px;
    left: 11px;
    top: 15px;
    -webkit-transform: none;
    transform: none
  }
}

// восстановление
.recovery {
  background-color: $color-bg-gray;
  padding-top: 100px;
  padding-bottom: 100px;

  &__grid {
    margin-left: -15px;
    margin-right: -15px;

    &>* {
      flex: 0 1 50%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__image {
    position: relative;
    max-width: 630px;
    border-radius: 10px;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      height: 0;
      padding-top: 71.42857%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__content {
    margin-top: 25px;
    position: relative;
    z-index: 1;
  }

  &__text {
    background-color: $color-primary-light;
    border-radius: 10px;
    overflow: hidden;
    padding: 26px 28px 26px 40px;
    margin-right: -69px;
    margin-top: 33px;
    font-size: 18px;
    line-height: 28px;

    &>div {
      position: relative;
      padding-left: 23px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $color-primary;
      }
    }

    strong {
      display: block;
      margin-top: 28px;
    }
  }

  &__note {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-style: normal;
    font-size: 22px;
    color: #fff;
    background-color: $color-primary;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    min-height: 164px;
    padding: 15px 25px 15px 255px;
    margin-right: -69px;
    margin-top: 10px;
    z-index: 1;

    &>i {
      position: absolute;
      left: 58px;
      top: 33px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 8px;
      top: -33px;
      width: 97px;
      height: 96px;
      background-image: url(../static/implant_dentalway__ru/build/i/7a5c4e9b92f196fe1a6af7186fafc6da.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
      z-index: -1;
    }
  }
}

@media screen and (max-width:1312px) {
  .recovery {
    padding-top: 59px;
    padding-bottom: 75px
  }

  .recovery__grid>* {
    flex-basis: 58.33%
  }

  .recovery__grid>:nth-child(2) {
    flex-basis: 41.66%
  }

  .recovery__content {
    margin-top: 0
  }

  .recovery__text {
    margin-top: 19px;
    margin-right: -41px;
    font-size: 16px;
    line-height: 28px;
    padding: 20px 28px 20px 17px
  }

  .recovery__text strong {
    margin-top: 0
  }

  .recovery__note {
    margin-right: -41px;
    font-size: 18px;
    padding-left: 191px;
    min-height: 99px
  }

  .recovery__note>i {
    max-width: 120px;
    left: 24px;
    top: 14px
  }

  .recovery__note:before {
    top: -28px;
    left: 10px
  }

  .recovery__image {
    margin-left: -30px;
    margin-top: 18px;
    border-radius: 5px
  }
}

@media screen and (max-width:1023px) {
  .recovery {
    padding-top: 46px;
    padding-bottom: 63px
  }

  .recovery__grid {
    align-items: flex-start
  }

  .recovery__image {
    margin-top: 40px
  }

  .recovery__text {
    margin-top: 22px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 22px;
    margin-right: -93px;
    padding: 15px 28px 14px 11px
  }

  .recovery__text>div:before {
    top: 4px
  }

  .recovery__note {
    font-family: $font-geometria;
    font-weight: 400;
    font-style: italic;
    border-radius: 5px;
    min-height: 95px;
    margin-right: -93px;
    font-size: 16px;
    line-height: 20px;
    padding-left: 171px
  }

  .recovery__note>i {
    top: 11px
  }
}

@media screen and (max-width:767px) {
  .recovery {
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    z-index: 1
  }

  .recovery:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 175px;
    background: linear-gradient(219.35deg, $color-primary, rgba(0, 134, 210, 0));
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    z-index: -1
  }

  .recovery__grid>*,
  .recovery__grid>:nth-child(2) {
    flex-basis: 100%
  }

  .recovery__text {
    margin-right: 0;
    margin-top: 12px;
    padding-top: 12px;
    padding-right: 15px;
    padding-bottom: 8px
  }

  .recovery__text>div {
    padding-left: 18px
  }

  .recovery__note {
    margin-right: 0;
    padding-left: 148px;
    min-height: 111px
  }

  .recovery__note>i {
    left: 11px;
    top: 16px
  }

  .recovery__note:before {
    width: 82px;
    height: 82px;
    top: -35px
  }

  .recovery__image {
    margin-left: 0;
    margin-top: 20px
  }
}

// гарантии
.guarantee {
  position: relative;
  padding-top: 115px;
  padding-bottom: 85px;
  background-image: url(../static/implant_dentalway__ru/build/i/3ad3e285c862c3cda82f53045a800a5b.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  &>.block-label_large {
    width: 392px;
    font-size: 134px;
    line-height: 121px;

    &>span {
      -webkit-transform: translate(-57.5%, -50%) rotate(-90deg);
      transform: translate(-57.5%, -50%) rotate(-90deg);
    }
  }

  &__grid {
    margin-left: -15px;
    margin-right: -15px;

    &>* {
      flex: 0 1 50%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__content {
    margin-top: 4px;
    position: relative;
    z-index: 1;

    .h2 {
      margin-left: 60px;
    }
  }

  &__image {
    position: relative;
    max-width: 630px;
    border-radius: 10px;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      height: 0;
      padding-top: 71.42857%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__text {
    background-color: $color-primary-light;
    border-radius: 10px;
    overflow: hidden;
    padding: 26px 28px 26px 40px;
    margin-left: -69px;
    margin-top: 48px;
    font-size: 18px;
    line-height: 28px;

    &>div {
      position: relative;
      padding-left: 23px;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 8px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $color-primary;
      }
    }

    strong {
      display: block;
    }

    span {
      display: block;
      margin-top: 28px;
    }
  }

  &__note {
    display: flex;
    align-items: center;
    font-family: $font-geometria;
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    color: #fff;
    background-color: $color-primary;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    min-height: 164px;
    padding: 15px 45px 15px 215px;
    margin-left: -69px;
    margin-top: 10px;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      left: 4px;
      top: -19px;
      width: 77px;
      height: 90px;
      background-image: url(../static/implant_dentalway__ru/build/i/a5b929370488f5b84daf3ee69d7bc05e.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
      z-index: -1;
    }

    &-image {
      position: absolute;
      left: 53px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &-text {
      &>span {
        font-family: $font-geometria;
        font-weight: 400;
        font-style: italic;
        display: block;
        font-size: 14px;
        line-height: 22px;
        margin-top: 14px;
      }
    }
  }
}

@media screen and (max-width:1312px) {
  .guarantee {
    padding-top: 70px;
    padding-bottom: 55px
  }

  .guarantee>.block-label_large {
    width: 287px;
    font-size: 95px;
    line-height: 85px
  }

  .guarantee>.block-label_large>span {
    -webkit-transform: translate(-55.5%, -47.5%) rotate(-90deg);
    transform: translate(-55.5%, -47.5%) rotate(-90deg)
  }

  .guarantee__grid>* {
    flex-basis: 41.66%
  }

  .guarantee__grid>:nth-child(2) {
    flex-basis: 58.33%
  }

  .guarantee__image {
    margin-right: -30px
  }

  .guarantee__content {
    margin-top: 0
  }

  .guarantee__content .h2 {
    margin-left: 65px
  }

  .guarantee__text {
    margin-left: -30px;
    margin-top: 19px;
    font-size: 16px;
    line-height: 24px;
    padding: 15px 28px 13px 24px
  }

  .guarantee__text>div>span {
    margin-top: 0
  }

  .guarantee__note {
    margin-left: -30px;
    font-size: 14px;
    line-height: 18px;
    padding-top: 17px;
    padding-left: 105px;
    padding-right: 23px;
    min-height: 96px
  }

  .guarantee__note-text>span {
    font-size: 14px;
    line-height: 18px;
    margin-top: 9px
  }

  .guarantee__note-image {
    max-width: 68px;
    left: 18px
  }
}

@media screen and (max-width:1023px) {
  .guarantee {
    padding-top: 46px;
    padding-bottom: 50px
  }

  .guarantee>.block-label_large {
    display: block;
    width: 180px
  }

  .guarantee>.block-label_large>span {
    display: none
  }

  .guarantee__image {
    margin-top: 30px;
    border-radius: 5px
  }

  .guarantee__text {
    margin-left: -93px;
    margin-top: 22px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 22px;
    padding: 15px 28px 14px 11px
  }

  .guarantee__text>div:before {
    top: 4px
  }

  .guarantee__note {
    border-radius: 5px;
    margin-left: -93px;
    padding-left: 108px
  }

  .guarantee__note-image {
    left: 21px
  }
}

@media screen and (max-width:767px) {
  .guarantee {
    padding-top: 34px;
    padding-bottom: 57px;
    z-index: 1
  }

  .guarantee>.block-label_large {
    display: none
  }

  .guarantee:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 206px;
    background: linear-gradient(219.35deg, $color-primary, rgba(0, 134, 210, 0));
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    z-index: -1
  }

  .guarantee__grid>* {
    flex-basis: 100%
  }

  .guarantee__grid>:nth-child(2) {
    order: -1;
    flex-basis: 100%
  }

  .guarantee__content .h2 {
    margin-left: 0
  }

  .guarantee__text {
    margin-left: 0;
    margin-top: 12px;
    padding: 12px 20px 8px 11px
  }

  .guarantee__text>div {
    padding-left: 18px
  }

  .guarantee__text strong {
    font-family: $font-geometria;
    font-weight: 400;
    font-style: normal
  }

  .guarantee__note {
    margin-left: 0;
    padding: 68px 23px 19px 28px
  }

  .guarantee__note:before {
    left: auto;
    right: 4px;
    top: -13px
  }

  .guarantee__note-text>span {
    margin-top: 5px
  }

  .guarantee__note-image {
    left: 28px;
    top: 35px
  }

  .guarantee__image {
    margin-right: 0;
    margin-top: 10px
  }
}

// результаты
.results {
  padding-top: 95px;
  padding-bottom: 90px;
  position: relative
}

.results .employees__header .header__text {
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
  padding: 6px;
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  background-color: $color-primary
}

.results__subtitle {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  color: #333;
  padding-left: 21px;
  margin-top: 22px;
  margin-left: 3px
}

.results__subtitle:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 0;
  top: 8px;
  border-radius: 50%;
  background-color: $color-primary
}

.results__slider-wrapper {
  position: relative;
  padding-left: 190px;
  padding-right: 190px;
  margin-top: 32px
}

.results__slider .slick-list {
  margin-left: -15px;
  margin-right: -15px
}

.results__slider .slick-slide {
  margin-left: 15px;
  margin-right: 15px
}

.result__item__grid {
  align-items: flex-end;
  margin-left: -24px;
  margin-right: -24px
}

.result__item__grid>* {
  flex: 0 1 55.22%;
  padding-left: 24px;
  padding-right: 24px
}

.result__item__grid>:nth-child(2) {
  flex-basis: 44.78%
}

.result__item__image-wrapper {
  position: relative;
  padding-left: 118px
}

.result__item__image__left {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2
}

.result__item__image {
  width: 100%;
  max-width: 364px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 1
}

.result__item__image:after {
  content: "";
  display: block;
  height: 0;
  padding-top: 123.07692%
}

.result__item__image img,
.result__item__image video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%
}

.result__item__image video {
  display: block
}

.result__item__recommend {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  width: 198px;
  height: 194px;
  text-align: center;
  padding-top: 114px;
  font-size: 18px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 20px;
  background: $color-primary;
  border-radius: 10px
}

.result__item__recommend>span {
  color: $color-primary
}

.result__item__recommend:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 21px;
  width: 80px;
  height: 80px;
  margin-left: -40px;
  background-image: url(../static/implant_dentalway__ru/build/i/37801c9489c67d5ad6dcda54c70ff609.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%
}

.result__item__video {
  width: 198px;
  height: 198px;
  background-color: $color-primary-light;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 112px;
  position: relative
}

.result__item__video>div {
  font-family: $font-geometria;
  font-weight: 400;
  font-style: italic;
  color: #7f8f98;
  font-size: 14px;
  line-height: 18px
}

.result__item__video>div b {
  font-family: $font-geometria;
  font-weight: 500;
  font-style: normal;
  color: #333;
  display: block;
  margin-bottom: 6px
}

.result__item__video .btn_play {
  position: absolute;
  left: 50%;
  top: 22px;
  margin-left: -37px
}

.result__item__video:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 74px;
  height: 71px;
  background-image: url(../static/implant_dentalway__ru/build/i/633b541001a736af5f80dca94130d12c.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%
}

.result__item__title {
  font-family: $font-geometria;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  line-height: 20px;
  color: #6b6b6b
}

.result__item__text {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 26px;
  color: #333;
  padding-left: 55px;
  position: relative;
  margin-top: 25px
}

.result__item__text:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 31px;
  height: 21px;
  background-image: url(../static/implant_dentalway__ru/build/i/6a1c5d95fc8bdb293685d1da3a6fbd0b.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0
}

.result__item__result {
  margin-top: 58px
}

.result__item__result-title {
  font-family: $font-geometria;
  font-weight: 500;
  font-style: normal;
  background: $color-primary;
  padding: 4px 14px 4px 16px;
  display: inline-block;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  color: #fff
}

.result__item__result-image {
  border-radius: 5px;
  overflow: hidden;
  margin-top: 9px
}

.result__item__header_adaptive,
.result__item__image__left_adaptive {
  display: none
}

@media screen and (max-width:1312px) {
  .results {
    padding-top: 64px;
    padding-bottom: 70px
  }

  .results .employees__header .header__text {
    padding: 1px 5px
  }

  .results__subtitle {
    font-size: 16px;
    margin-top: 19px
  }

  .results__subtitle:before {
    top: 6px
  }

  .results__slider-wrapper {
    padding-left: 130px;
    padding-right: 130px;
    margin-top: 32px
  }

  .result__item__grid {
    margin-left: -15px;
    margin-right: -15px
  }

  .result__item__grid>* {
    padding-left: 15px;
    padding-right: 15px;
    flex-basis: 57.28%
  }

  .result__item__grid>:nth-child(2) {
    flex-basis: 42.72%
  }

  .result__item__image-wrapper {
    padding-left: 0;
    display: flex;
    align-items: flex-end;
    padding-bottom: 39px
  }

  .result__item__image__left {
    position: relative;
    -webkit-transform: none;
    transform: none;
    margin-right: -66px;
    margin-bottom: -39px;
    top: 0;
    max-width: 166px
  }

  .result__item__image__left>* {
    max-width: 100%
  }

  .result__item__recommend {
    font-size: 16px;
    line-height: 20px;
    position: relative
  }

  .result__item__content {
    padding-bottom: 39px
  }

  .result__item__text {
    font-size: 20px;
    line-height: 26px;
    margin-top: 10px
  }

  .result__item__result {
    margin-top: 38px
  }

  .result__item__result-title {
    padding: 5px 11px 5px 13px
  }

  .result__item__result-image {
    margin-top: 5px
  }

  .result__item__image video {
    position: relative
  }

  .result__item__image:after {
    content: none
  }

  .result__item__image img {
    position: relative
  }
}

@media screen and (max-width:1023px) {
  .results {
    padding-top: 45px;
    padding-bottom: 46px
  }

  .results .employees__header .header__text {
    font-size: 18px;
    line-height: 23px
  }

  .results__subtitle {
    font-size: 14px;
    line-height: 22px;
    padding-left: 20px;
    margin-top: 15px
  }

  .results__subtitle:before {
    top: 5px
  }

  .results__slider-wrapper {
    padding-left: 0;
    padding-right: 0;
    margin-top: 24px
  }

  .results__slider-wrapper .slick-arrow {
    margin-top: 34px
  }

  .result__item__image__left {
    display: none
  }

  .result__item__image__left_adaptive {
    display: flex;
    max-width: calc(100% + 30px);
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 0
  }

  .result__item__image__left_adaptive>* {
    flex: 0 1 50%;
    margin-left: 15px;
    margin-right: 15px
  }

  .result__item__image__left_adaptive>:nth-child(2) {
    order: -1
  }

  .result__item__recommend {
    height: 93px;
    padding-left: 108px;
    padding-top: 30px;
    text-align: left;
    font-size: 14px;
    line-height: 20px
  }

  .result__item__recommend:before {
    left: 20px;
    top: 50%;
    margin-left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 73px;
    height: 73px
  }

  .result__item__video {
    height: 93px;
    padding-top: 27px;
    padding-bottom: 5px;
    padding-left: 110px;
    text-align: left
  }

  .result__item__video .btn_play {
    left: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: 0
  }

  .result__item__grid {
    padding-left: 96px;
    padding-right: 96px;
    margin-top: 21px
  }

  .result__item__grid>*,
  .result__item__grid>:nth-child(2) {
    flex-basis: 50%
  }

  .result__item__content,
  .result__item__image-wrapper {
    padding-bottom: 0
  }

  .result__item__result-image {
    margin-top: 9px
  }

  .result__item__text {
    font-size: 16px;
    line-height: 26px;
    padding-left: 34px
  }

  .result__item__text:before {
    width: 19px;
    height: 13px;
    top: 4px
  }
}

@media screen and (max-width:833px) {
  .results .employees__header .header__text {
    background: none
  }

  .results .employees__header .header__text span {
    background-color: $color-primary;
    border-radius: 5px;
    padding: 1px 5px;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content
  }

  .results .employees__header .header__text span:not(:first-child) {
    margin-top: 5px
  }
}

@media screen and (max-width:767px) {
  .results {
    padding-top: 35px;
    padding-bottom: 39px
  }

  .results .h2 {
    padding-right: 38px
  }

  .results .employees__header .header__text {
    font-size: 14px;
    line-height: 18px
  }

  .results__subtitle {
    padding-left: 15px;
    margin-top: 17px
  }

  .results__slider-wrapper {
    margin-top: 13px
  }

  .results__slider-wrapper .slick-arrow {
    margin-top: 119px
  }

  .result__item__image__left_adaptive {
    flex-wrap: wrap
  }

  .result__item__image__left_adaptive>* {
    flex-basis: 100%
  }

  .result__item__image__left_adaptive>:nth-child(2) {
    order: 2
  }

  .result__item__recommend {
    margin-bottom: 10px;
    height: 80px;
    padding-left: 81px;
    padding-top: 0;
    display: flex;
    align-items: center
  }

  .result__item__recommend:before {
    width: 60px;
    height: 60px;
    left: 10px
  }

  .result__item__video {
    height: 80px;
    padding-top: 21px;
    padding-left: 84px
  }

  .result__item__video>div {
    font-size: 12px
  }

  .result__item__video>div b {
    font-size: 14px
  }

  .result__item__video .btn_play,
  .result__item__video:before {
    left: 10px
  }

  .result__item__text,
  .result__item__title {
    display: none
  }

  .result__item__header_adaptive {
    display: block;
    margin-top: 27px
  }

  .result__item__header_adaptive .result__item__title {
    display: block;
    font-size: 12px;
    line-height: 20px
  }

  .result__item__header_adaptive .result__item__text {
    display: block;
    font-size: 14px;
    line-height: 22px;
    padding-left: 29px
  }

  .result__item__grid {
    padding-left: 53px;
    padding-right: 53px;
    margin-top: 14px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto
  }

  .result__item__grid>* {
    flex-basis: 100%;
    padding-left: 0;
    padding-right: 0
  }

  .result__item__grid>:nth-child(2) {
    flex-basis: 100%
  }

  .result__item__result {
    margin-top: 10px
  }

  .result__item__result-title {
    font-size: 12px;
    padding-right: 17px
  }

  .result__item__result-image {
    margin-top: 5px
  }
}

// рейтинги
.ratings {
  margin-top: 116px;
  margin-bottom: 118px;

  .ratings__stars {
    margin-top: 20px;

    &>* {
      display: block;
      background-image: url(../static/implant_dentalway__ru/build/i/c232c5c545a73e044490a1221fa4d303.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50%;
      width: 24px;
      height: 24px;
      margin-right: 11px;
    }
  }

  &__grid {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 50px;

    &>* {
      flex: 0 1 33.33%;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 20px;
    }

    &> :nth-child(2) {
      flex-basis: 66.66%;
      max-width: 66.66%;
    }
  }

  &__value {
    background-color: $color-primary;
    position: relative;
    color: #fff;
    max-width: 358px;
    border-radius: 10px;
    overflow: hidden;
    padding: 47px 57px 51px;
    z-index: 2;

    .ratings__stars {
      margin-top: 20px;
    }

    &:before {
      content: "";
      position: absolute;
      right: 24px;
      top: -9px;
      width: 93px;
      height: 102px;
      background-image: url(../static/implant_dentalway__ru/build/i/eee4e19430ae3abdc2a933e2eebc8944.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
    }

    &__value {
      font-size: 14px;
      line-height: 18px;
      position: relative;
      max-width: 200px;

      &>b {
        font-family: $font-geometria;
        font-weight: 700;
        font-style: normal;
        display: block;
        font-size: 30px;
        line-height: 37px;
        color: #fff;
        margin-top: 32px;

        &>span {
          font-size: 50px;
        }
      }
    }
  }

  &__slider {
    .slick-list {
      margin-left: -15px;
      margin-right: -15px;
      overflow: visible;
    }

    .slick-slide {
      margin-left: 15px;
      margin-right: 15px;
      opacity: 0;
      transition: opacity .2s;

      &>* {
        height: 240px;
      }
    }

    .slick-active {
      opacity: 1;
    }

    &-wrapper {
      position: relative;
      padding-left: 110px;
      padding-right: 110px;
      z-index: 1;
    }
  }

  &__platforms {
    align-items: flex-start;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 55px;

    &>* {
      flex: 0 1 25%;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 20px;
    }
  }

  &__value_adaptive {
    display: none;
  }
}

.rating {
  &__slide {
    min-height: 235px;
    background-color: $color-bg-gray;
    border-radius: 5px;
    overflow: hidden;
    padding: 36px 40px;

    &__header {
      align-items: center;
      justify-content: space-between;
    }

    &__author {
      position: relative;
      padding-left: 50px;
      font-size: 16px;
      line-height: 20px;
      color: #333;

      &:before {
        content: "";
        position: absolute;
        width: 24px;
        height: 16px;
        left: 0;
        top: 2px;
        background-image: url(../static/implant_dentalway__ru/build/i/6a1c5d95fc8bdb293685d1da3a6fbd0b.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0 0;
      }
    }

    &__date {
      font-family: $font-geometria;
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      line-height: 18px;
      margin-top: 3px;
      color: #7f8f98;
    }

    &__body {
      margin-top: 30px;

      &.active {
        .rating__slide__text {
          &.expanded {
            height: 100%;
          }
        }
      }
    }

    &__text {
      font-family: $font-geometria;
      font-weight: 500;
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      height: 62px;
      position: relative;

      &.expanded {
        overflow: hidden;
        height: 62px;
        position: relative;

        &+.rating__slide__bottom {
          .rating__slide__show-more {
            display: block;
          }
        }
      }
    }

    &__bottom {
      align-items: center;
      justify-content: space-between;
      margin-top: 26px;
    }

    &__show-more {
      font-family: $font-geometria;
      font-weight: 500;
      font-style: normal;
      color: $color-primary;
      font-size: 16px;
      line-height: 20px;
      display: none;
    }

    &__source {
      font-family: $font-geometria;
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      line-height: 18px;
      margin-top: 2px;
      color: #7f8f98;

      .link {
        color: inherit;
      }
    }
  }

  &__item {
    .ratings__stars {
      justify-content: center;
      margin-top: 15px;

      &>* {
        width: 24px;
        height: 24px;
        margin-right: 11px;
      }

      &> :last-child {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width:1312px) {
  .ratings {
    margin-top: 81px;
    margin-bottom: 74px
  }

  .ratings__header {
    display: flex;
    align-items: center
  }

  .ratings__header .h2 {
    margin-bottom: 0
  }

  .ratings__header>* {
    flex-basis: calc(100% - 402px)
  }

  .ratings__header>:nth-child(2) {
    flex-basis: 402px;
    max-width: 402px
  }

  .ratings__value {
    display: none;
    padding: 19px 48px 8px
  }

  .ratings__value:before {
    right: 5px;
    top: -13px
  }

  .ratings__value__value {
    max-width: unset
  }

  .ratings__value__value>b {
    margin-top: 14px
  }

  .ratings__value__stars {
    position: absolute;
    bottom: 26px;
    right: 26px
  }

  .ratings__value_adaptive {
    display: block
  }

  .ratings__grid {
    margin-top: 30px
  }

  .ratings__grid>* {
    flex-basis: 100%
  }

  .ratings__grid>:first-child {
    display: none
  }

  .ratings__grid>:nth-child(2) {
    flex-basis: 100%;
    max-width: 100%
  }

  .ratings__slider .slick-slide>* {
    height: 212px
  }

  .rating__slide {
    padding: 35px 30px;
    min-height: 212px
  }

  .rating__slide__text.expanded {
    height: 42px
  }

  .ratings__platforms {
    margin-top: 22px
  }

  .rating__item .ratings__stars {
    margin-top: 6px
  }
}

@media screen and (max-width:1023px) {
  .ratings {
    margin-top: 50px;
    margin-bottom: 58px
  }

  .ratings__header>* {
    flex-basis: calc(100% - 315px)
  }

  .ratings__header>:nth-child(2) {
    flex-basis: 315px;
    max-width: 315px
  }

  .ratings__value {
    padding-left: 39px;
    padding-right: 39px;
    padding-top: 12px
  }

  .ratings__value__value>b {
    font-size: 20px;
    margin-top: 8px
  }

  .ratings__value__value>b span {
    font-size: 40px
  }

  .ratings__value__stars .ratings__stars>* {
    width: 19px;
    height: 19px;
    margin-right: 4px
  }

  .ratings__grid {
    margin-top: 10px
  }

  .ratings__slider-wrapper {
    padding-left: 96px;
    padding-right: 96px
  }

  .ratings__slider .slick-slide>* {
    height: 194px
  }

  .rating__slide {
    padding: 29px 30px 20px;
    min-height: 194px
  }

  .rating__slide__author {
    font-size: 14px;
    line-height: 18px
  }

  .rating__slide__date {
    font-size: 12px;
    line-height: 15px;
    margin-top: 6px
  }

  .rating__slide__show-more {
    font-size: 14px;
    line-height: 18px
  }

  .rating__slide__source {
    font-size: 12px;
    line-height: 15px;
    margin-top: 5px
  }

  .ratings__platforms {
    margin-top: 11px
  }

  .rating__item .ratings__stars {
    margin-top: 13px
  }

  .rating__item .ratings__stars>* {
    width: 19px;
    height: 19px;
    margin-right: 4px
  }
}

@media screen and (max-width:767px) {
  .ratings {
    margin-top: 35px;
    margin-bottom: 46px
  }

  .ratings__header {
    flex-wrap: wrap
  }

  .ratings__header>*,
  .ratings__header>:nth-child(2) {
    flex-basis: 100%
  }

  .ratings__value {
    margin-top: 22px;
    margin-left: auto;
    padding: 9px 22px 4px
  }

  .ratings__value:before {
    right: 1px
  }

  .ratings__value__value {
    font-size: 12px
  }

  .ratings__value__value>b {
    margin-top: 4px
  }

  .ratings__value__stars {
    right: 22px;
    bottom: 15px
  }

  .ratings__grid {
    margin-top: 0
  }

  .ratings__slider-wrapper {
    padding-left: 53px;
    padding-right: 53px
  }

  .ratings__slider .slick-slide>* {
    height: 211px
  }

  .rating__slide {
    padding: 20px;
    min-height: 211px
  }

  .rating__slide__author {
    font-size: 12px;
    line-height: 15px;
    padding-left: 22px
  }

  .rating__slide__author:before {
    width: 17px;
    height: 12px;
    top: 0
  }

  .rating__slide__date {
    font-size: 10px;
    line-height: 13px
  }

  .rating__slide__body {
    margin-top: 13px
  }

  .rating__slide__text {
    font-size: 12px;
    line-height: 15px
  }

  .rating__slide__text.expanded {
    height: 75px
  }

  .rating__slide__bottom {
    margin-top: 14px
  }

  .rating__slide__show-more {
    font-size: 12px;
    line-height: 15px
  }

  .rating__slide__source {
    font-size: 10px;
    line-height: 13px;
    margin-top: 8px
  }

  .ratings__platforms {
    margin-top: -5px
  }

  .ratings__platforms>* {
    flex-basis: 50%;
    margin-top: 30px
  }

  .rating__item .ratings__stars {
    margin-top: 13px
  }
}

.best {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px
}

.best__grid {
  margin-left: -15px;
  margin-right: -15px
}

.best__grid>* {
  flex: 0 1 50%;
  padding-left: 15px;
  padding-right: 15px
}

.best__content {
  margin-top: 8px
}

.best__text {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  max-width: 555px;
  padding-left: 23px;
  margin-top: 20px
}

.best__text:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $color-primary
}

.best__image {
  position: relative;
  background-color: rgba(0, 140, 212, .3);
  max-width: 630px;
  border-radius: 10px;
  overflow: hidden
}

.best__image:after {
  content: "";
  display: block;
  height: 0;
  padding-top: 100.47619%
}

.best__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%
}

.best__awards-wrapper {
  margin-right: -97px;
  position: relative;
  z-index: 2;
  margin-top: 10px
}

.best__awards {
  display: grid;
  grid-template-columns: repeat(3, 33.33%);
  margin-left: -10px;
  margin-right: -10px
}

.best__awards>* {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px
}

.best__awards>:first-child,
.best__awards>:nth-child(4) {
  grid-column: 1
}

.best__awards>:nth-child(2),
.best__awards>:nth-child(5) {
  grid-column: 2
}

.best__awards>:first-child,
.best__awards>:nth-child(2) {
  grid-row: 1
}

.best__awards>:nth-child(4),
.best__awards>:nth-child(5) {
  grid-row: 2
}

.best__awards>:nth-child(3) {
  grid-row: 1/span 2;
  grid-column: 3
}

.best__awards .tall .best__award {
  align-items: center;
  padding-top: 0
}

.best__award {
  justify-content: center;
  font-family: $font-geometria;
  font-weight: 400;
  font-style: italic;
  background-color: $color-primary-light;
  border-radius: 10px;
  height: 100%;
  color: #333;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  min-height: 200px;
  overflow: hidden;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 27px;
  z-index: 1
}

.best__award:before {
  content: "";
  position: absolute;
  left: 25px;
  top: 0;
  width: 78px;
  height: 81px;
  background-image: url(../static/implant_dentalway__ru/build/i/633b541001a736af5f80dca94130d12c.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  z-index: -1
}

.best__award-title {
  margin-top: 15px
}

@media screen and (max-width:1312px) {
  .best {
    padding-top: 69px;
    padding-bottom: 84px
  }

  .best__text {
    font-size: 16px;
    line-height: 24px
  }

  .best__image {
    margin-left: 50px;
    margin-top: 15px
  }

  .best__awards-wrapper {
    margin-right: -235px;
    margin-left: 5px;
    margin-top: 24px
  }

  .best__awards {
    margin-left: -5px;
    margin-right: -5px
  }

  .best__awards>* {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 10px
  }

  .best__award {
    min-height: 190px
  }
}

@media screen and (max-width:1023px) {
  .best {
    padding-top: 52px;
    padding-bottom: 58px;
    z-index: 1
  }

  .best__grid>* {
    flex-basis: 58.33%
  }

  .best__grid>:nth-child(2) {
    flex-basis: 41.66%
  }

  .best__text {
    font-size: 14px;
    line-height: 22px;
    margin-top: 16px;
    padding-left: 25px;
    padding-right: 40px
  }

  .best__text>span {
    display: block;
    margin-top: 22px
  }

  .best__image {
    margin-top: 8px;
    margin-left: -28px
  }

  .best__awards-wrapper {
    margin-right: -160px;
    margin-left: 0;
    margin-top: 18px
  }

  .best__awards .tall .best__award {
    padding-bottom: 10px
  }

  .best__award {
    min-height: 162px;
    padding-top: 20px;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px
  }

  .best__award-image {
    display: inline-block;
    max-width: 50px
  }

  .best__award-image.icon-large {
    max-width: 80px
  }

  .best__award-title {
    margin-top: 12px
  }
}

@media screen and (max-width:767px) {
  .best {
    padding-top: 29px;
    padding-bottom: 40px
  }

  .best__grid>*,
  .best__grid>:nth-child(2) {
    flex-basis: 100%
  }

  .best__text {
    margin-top: 17px;
    padding-left: 18px;
    padding-right: 0
  }

  .best__text>span {
    margin-top: 0
  }

  .best__awards-wrapper {
    margin-right: 0;
    margin-top: 17px
  }

  .best__awards {
    display: flex;
    flex-wrap: wrap
  }

  .best__awards>* {
    margin-top: 5px;
    flex-basis: 100%
  }

  .best__awards .tall {
    order: 5
  }

  .best__awards .tall .best__award {
    padding-bottom: 0
  }

  .best__awards .tall .best__award-image.icon-large {
    height: 40px
  }

  .best__award {
    min-height: 57px;
    padding-top: 0;
    padding-left: 0;
    font-size: 14px;
    line-height: 18px;
    display: block;
    text-align: left
  }

  .best__award:before {
    content: none
  }

  .best__award>div {
    padding-left: 62px;
    display: flex;
    align-items: center;
    height: 100%
  }

  .best__award-image {
    max-width: 37px;
    width: 37px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    text-align: center
  }

  .best__award-image.icon-large {
    max-width: 37px
  }

  .best__award-image img {
    max-height: 100%;
    width: auto
  }

  .best__award-title {
    margin-top: 0
  }

  .best__image {
    margin-left: auto;
    margin-right: auto;
    margin-top: 21px
  }
}

// специалисты
.employees {
  position: relative;
  background-image: url(../static/implant_dentalway__ru/build/i/2fe8c158c41f5f86c451e48d360ab47b.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding-top: 103px;
  padding-bottom: 110px;

  &__inner {
    position: relative;
  }

  &__header {
    border-left: 5px solid $color-primary;
    padding-left: 15px;

    .h2 {
      border-left: none;
      padding-left: 0;
    }
  }

  &__slider {
    .slick-list {
      margin-left: -16px;
      margin-right: -16px;
    }

    .slick-slide {
      margin-left: 16px;
      margin-right: 16px;
    }

    &-wrapper {
      position: relative;
      padding-left: 143px;
      padding-right: 145px;
      margin-top: 49px;
    }
  }
}

.employee {
  background: #fff;
  border-radius: 5px;
  padding: 30px 0;

  &__photo {
    max-width: 251px;
    border-radius: 50%;
    background-color: $color-bg-gray;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      height: 0;
      padding-top: 100%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__video {
    display: flex;
    align-items: center;
    color: #fff;
    border-radius: 5px;
    background-color: $color-primary-light;
    min-height: 76px;
    width: 100%;
    max-width: 300px;
    padding-left: 88px;
    position: absolute;
    left: -10px;
    bottom: -10px;

    .btn_play {
      position: absolute;
      width: 56px;
      height: 56px;
      left: 18px;
      top: 50%;
      margin-top: -28px;

      &>span {
        &>i {
          background-position: center left 22px;
          background-size: 15px 20px;
        }
      }
    }

    &>div {
      font-family: $font-geometria;
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      line-height: 18px;
      color: #7f8f98;

      b {
        display: inline-block;
        color: #333;
        margin-bottom: 6px;
      }
    }
  }

  &__about {
    flex-wrap: nowrap;
    padding-left: 13px;
    padding-right: 5px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 21px;

    &>* {
      flex: 0 1 auto;
      border-radius: 5px;
      margin-right: 5px;
      padding: 5px 0;
    }

    &> :last-child {
      margin-right: 0;
    }
  }

  &__content {
    padding-left: 23px;
    padding-right: 23px;
    margin-top: 16px;
  }

  &__fio {
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: 25px;
    color: #333;
  }
}

.employee,
.employee__photo,
.employee__photo-wrapper {
  position: relative;
}

.employee__about,
.employee__video>div b {
  font-family: $font-geometria;
  font-weight: 500;
  font-style: normal;
}

.employee__about-experience {
  background: $color-primary;
  color: #fff;
  padding-left: 10px;
  padding-right: 11px;
}

.employee__about-feature {
  color: #333;
  background-color: $color-primary-light;
  padding-left: 15px;
  padding-right: 20px;

  &>span {
    color: $color-primary;
  }
}

.employee__fio,
.employee__profession {
  font-family: $font-geometria;
  text-align: center;
}

.employee__profession {
  font-weight: 400;
  font-style: italic;
  color: #7f8f98;
  font-size: 14px;
  line-height: 18px;
  margin-top: 6px;
}

.employee__certificates {
  justify-content: flex-start;
  margin-top: 16px;

  &>* {
    margin-left: -10px;
  }

  &> :first-child {
    margin-left: 0;
  }
}


@media screen and (max-width:1312px) {
  .employees {
    padding-top: 57px;
    padding-bottom: 40px
  }

  .employees .h2 {
    max-width: 630px;
    margin-bottom: 14px
  }

  .employees__slider-wrapper {
    padding-left: 140px;
    padding-right: 140px;
    margin-top: 33px
  }

  .employees__slider-wrapper .slick-arrow {
    margin-top: -55px
  }

  .employees__slider {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto
  }
}

@media screen and (max-width:1023px) {
  .employees {
    padding-top: 44px;
    padding-bottom: 49px
  }

  .employees .h2 {
    margin-bottom: 0
  }

  .employees .h2 br {
    display: none
  }

  .employees__slider-wrapper {
    padding-left: 42px;
    padding-right: 42px;
    margin-top: 21px;
    padding-bottom: 83px
  }

  .employees__slider-wrapper .slider__arrows {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .employees__slider-wrapper .slider__arrows>* {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0
  }

  .employees__slider .slick-list {
    margin-left: -10px;
    margin-right: -10px
  }

  .employees__slider .slick-slide {
    margin-left: 10px;
    margin-right: 10px
  }

  .employee {
    padding: 20px 0 25px
  }

  .employee__photo {
    max-width: 202px
  }

  .employee__content {
    margin-top: 9px
  }

  .employee__fio {
    font-size: 16px;
    line-height: 20px
  }

  .employee__profession {
    margin-top: 4px
  }

  .employee__certificates {
    margin-top: 14px
  }
}

@media screen and (max-width:767px) {
  .employees {
    padding-top: 37px;
    padding-bottom: 40px;
    z-index: 1
  }

  .employees:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 174px;
    background: linear-gradient(176deg, $color-primary, $color-primary 32.29%, #fff);
    z-index: -1
  }

  .employees .h2 {
    font-size: 21px;
    line-height: 26px
  }

  .employees .header__text {
    margin-top: 9px
  }

  .employees__header {
    border-left: none;
    padding-left: 0
  }

  .employees__slider-wrapper {
    padding-left: 0;
    padding-right: 0;
    margin-top: 34px;
    padding-bottom: 65px
  }

  .employee {
    padding: 20px 0
  }

  .employee__photo {
    max-width: 186px
  }

  .employee__about {
    justify-content: center;
    font-size: 12px;
    margin-top: 11px
  }

  .employee__about>* {
    margin-right: 10px
  }

  .employee__about>:last-child {
    margin-right: 0
  }

  .employee__about-feature {
    padding-left: 11px;
    padding-right: 11px
  }

  .employee__content {
    margin-top: 7px
  }

  .employee__fio {
    font-size: 14px;
    line-height: 20px
  }

  .employee__profession {
    margin-left: auto;
    margin-right: auto;
    max-width: 270px;
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px
  }

  .employee__certificates {
    margin-top: 8px
  }

  .page_success .employees .h2 {
    font-size: 23px;
    line-height: 30px
  }

  .page_success .employees .header__text_small {
    display: block;
    font-size: 18px
  }

  .page_success .employees__slider-wrapper {
    margin-top: 21px
  }
}

.get-price {
  margin-top: 95px;
  margin-bottom: 96px
}

.get-price__grid {
  align-items: center;
  margin-left: -15px;
  margin-right: -15px
}

.get-price__grid>* {
  flex: 0 1 33.33%;
  padding-left: 15px;
  padding-right: 15px
}

.get-price__grid>:nth-child(2) {
  flex-basis: 25%
}

.get-price__grid>:nth-child(3) {
  flex-basis: 41.66%
}

.get-price__info {
  position: relative;
  padding-left: 107px;
  font-size: 16px;
  line-height: 24px;
  color: #333;
  max-width: 340px;
  margin-top: 17px
}

.get-price__info>i {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.get-price__form {
  margin-left: -40px;
  max-width: 330px
}

.get-price__form .form__group_button {
  margin-top: 10px
}

.get-price__form .form__policy {
  margin-top: 15px
}

.get-price__form .input {
  padding-left: 30px;
  font-size: 18px
}

@media screen and (max-width:1312px) {
  .get-price {
    margin-top: 59px;
    margin-bottom: 57px
  }

  .get-price__grid {
    padding-right: 425px;
    position: relative;
    margin-left: 0;
    margin-right: 0
  }

  .get-price__grid>* {
    padding-left: 0;
    padding-right: 0;
    flex-basis: 100%
  }

  .get-price__grid>:nth-child(2) {
    flex-basis: 100%
  }

  .get-price__grid>:nth-child(3) {
    position: absolute;
    max-width: 412px;
    right: 9px;
    flex-basis: 100%;
    width: 100%
  }

  .get-price__info {
    max-width: 485px;
    margin-top: 23px;
    padding-left: 109px
  }

  .get-price__form {
    margin-left: 0;
    max-width: 450px;
    margin-top: 29px
  }

  .get-price__form .form__group_button {
    margin-top: 0
  }

  .get-price__form .form__policy {
    max-width: 100%;
    margin-top: 17px
  }

  .get-price__form .input {
    padding-left: 19px
  }

  .get-price__form .form__group-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px
  }

  .get-price__form .form__group-wrapper>* {
    flex-basis: 240px;
    padding-left: 5px;
    padding-right: 5px
  }

  .get-price__form .form__group-wrapper>:first-child {
    flex-basis: calc(100% - 240px)
  }

  .get-price__form .form__group-wrapper .form__policy {
    flex-basis: 100%
  }
}

@media screen and (max-width:1023px) {
  .get-price {
    margin-top: 44px;
    margin-bottom: 46px
  }

  .get-price .h2 {
    margin-right: -100px;
    min-height: 70px
  }

  .get-price__grid {
    padding-right: 390px
  }

  .get-price__grid>:first-child,
  .get-price__grid>:nth-child(2) {
    max-width: 100%
  }

  .get-price__grid>:nth-child(3) {
    max-width: 348px;
    right: 0
  }

  .get-price__info {
    font-size: 14px;
    line-height: 22px;
    padding-left: 80px;
    margin-top: 17px;
    max-width: 360px
  }

  .get-price__info>i {
    max-width: 69px
  }

  .get-price__form {
    padding-right: 0;
    margin-top: 25px;
    max-width: 100%
  }

  .get-price__form .input {
    font-size: 12px
  }

  .get-price__form .input::-webkit-input-placeholder {
    font-size: 12px
  }

  .get-price__form .input::placeholder {
    font-size: 12px
  }

  .get-price__form .form__group-wrapper>*,
  .get-price__form .form__group-wrapper>:first-child {
    flex-basis: 50%;
    max-width: 50%
  }

  .get-price__form .form__group-wrapper .form__policy {
    text-align: center;
    margin-top: 10px;
    max-width: 100%
  }

  .get-price__employee__photo {
    max-width: 165px;
    margin-right: 23px
  }

  .get-price__employee__content {
    flex-basis: calc(100% - 188px);
    margin-top: 10px
  }

  .get-price__employee__fio {
    font-size: 16px;
    line-height: 20px
  }

  .get-price__employee__profession {
    margin-top: 8px
  }

  .get-price__employee__experience {
    margin-top: 11px
  }
}

@media screen and (max-width:767px) {
  .get-price {
    margin-top: 36px
  }

  .get-price .h2 {
    margin-right: 0;
    min-height: unset;
    margin-bottom: 0;
    padding-bottom: 10px
  }

  .get-price .h2 nobr {
    white-space: normal
  }

  .get-price__grid {
    padding-right: 0
  }

  .get-price__grid>:nth-child(2) {
    max-width: 50%
  }

  .get-price__grid>:nth-child(3) {
    position: relative;
    bottom: 0;
    max-width: 50%
  }

  .get-price__info {
    padding-left: 89px;
    margin-top: 0;
    min-height: 70px;
    max-width: 100%
  }

  .get-price__form {
    padding-right: 0;
    max-width: 265px;
    margin-top: 23px
  }

  .get-price__form .form__group-wrapper {
    margin-left: 0;
    margin-right: 0
  }

  .get-price__form .form__group-wrapper>* {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0
  }

  .get-price__form .form__group-wrapper>:first-child {
    flex-basis: 100%;
    max-width: 100%
  }

  .get-price__form .form__group_button {
    margin-top: 10px
  }

  .get-price__form .form__policy {
    display: none
  }

  .get-price__form .btn {
    max-width: 100%
  }
}

@media screen and (max-width:575px) {

  .get-price__grid>:nth-child(2),
  .get-price__grid>:nth-child(3) {
    max-width: 100%
  }

  .get-price__form {
    margin-left: auto;
    margin-right: auto
  }
}

// вопросы ответы
.faq {
  background-color: $color-bg-gray;
  position: relative;
  padding-top: 95px;
  padding-bottom: 100px;

  &__container {
    margin-top: 50px;
  }

  &__item {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 30px;
    display: none;
    opacity: 0;
    transition: .2s ease;

    &.shown {
      align-items: flex-start;
    }

    &.visible {
      opacity: 1;
    }

    &>* {
      flex: 0 1 35.6%;
      padding-left: 15px;
      padding-right: 15px;
    }

    &> :nth-child(2) {
      flex-basis: 64.4%;
    }
  }

  // вопрос
  &__question {
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    padding: 30px 30px 20px 103px;

    &__author {
      font-family: $font-geometria;
      font-weight: 700;
      font-style: normal;
      font-size: 18px;
      line-height: 20px;

      &>i {
        position: absolute;
        left: 30px;
        top: 30px;
      }
    }

    &__text {
      font-family: $font-geometria;
      font-weight: 400;
      // font-style: italic;
      font-size: 16px;
      line-height: 140%;
      margin-top: 10px;
    }
  }

  // ответ
  &__answer {
    background-color: $color-primary-light;
    border-radius: 10px;
    position: relative;
    padding: 30px 30px 24px 103px;

    &:before {
      content: "";
      position: absolute;
      left: -15px;
      top: 20px;
      width: 0;
      height: 0;
      border-color: transparent $color-primary-light transparent transparent;
      border-style: solid;
      border-width: 7.5px 15px 7.5px 0;
    }

    &__author {
      font-family: $font-geometria;
      font-weight: 700;
      font-style: normal;
      color: $color-primary;
      font-size: 18px;
      line-height: 20px;

      &>i {
        position: absolute;
        left: 31px;
        top: 30px;
        width: 51px;
        height: 51px;
        border-radius: 50%;
        overflow: hidden;
        background-color: #fff;
      }

      br {
        display: none;
      }
    }

    &__text {
      font-family: $font-geometria;
      font-weight: 400;
      // font-style: italic;
      font-size: 16px;
      line-height: 140%;
      margin-top: 10px;
    }
  }

  &__show-more {
    max-width: 290px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
  }
}

@media screen and (max-width:1312px) {
  .faq {
    padding-top: 64px;
    padding-bottom: 74px
  }

  .faq__container {
    margin-top: 31px
  }

  .faq__item {
    margin-top: 20px
  }

  .faq__item>* {
    flex-basis: 36.6%
  }

  .faq__item>:nth-child(2) {
    flex-basis: 63.4%
  }

  .faq__question {
    padding-left: 30px
  }

  .faq__question__author {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 51px;
    padding-left: 70px
  }

  .faq__question__author>i {
    left: 0;
    top: 0
  }

  .faq__question__text {
    margin-top: 17px
  }

  .faq__answer {
    padding-left: 30px
  }

  .faq__answer__author {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 51px;
    padding-left: 70px
  }

  .faq__answer__author>i {
    left: 0;
    top: 0
  }

  .faq__answer__text {
    margin-top: 17px
  }

  .faq__show-more {
    margin-top: 40px
  }
}

@media screen and (max-width:1023px) {
  .faq {
    padding-top: 45px;
    padding-bottom: 50px
  }

  .faq .h2 i {
    display: none
  }

  .faq__container {
    margin-top: 0
  }

  .faq__item {
    margin-top: 30px
  }

  .faq__item>*,
  .faq__item>:nth-child(2) {
    flex-basis: 100%
  }

  .faq__item:first-child {
    margin-top: 20px
  }

  .faq__question {
    padding-top: 20px;
    padding-bottom: 13px;
    border-radius: 5px
  }

  .faq__question__author {
    font-size: 16px
  }

  .faq__question__text {
    font-size: 14px;
    line-height: 22px;
    margin-top: 12px
  }

  .faq__answer {
    margin-top: 21px
  }

  .faq__answer:before {
    border-width: 0 6.5px 12px;
    border-color: transparent transparent $color-primary-light;
    top: -12px;
    left: 48px
  }

  .faq__answer {
    padding-top: 20px;
    padding-bottom: 16px;
    border-radius: 5px
  }

  .faq__answer__author {
    font-size: 16px
  }

  .faq__answer__text {
    font-size: 14px;
    line-height: 22px
  }

  .faq__show-more {
    max-width: 252px;
    margin-top: 30px
  }
}

@media screen and (max-width:767px) {
  .faq {
    padding-top: 35px;
    padding-bottom: 39px
  }

  .faq__item,
  .faq__item:first-child {
    margin-top: 22px
  }

  .faq__question {
    padding: 15px 15px 11px
  }

  .faq__question__text {
    margin-top: 7px
  }

  .faq__answer {
    margin-top: 16px;
    padding: 15px 15px 12px
  }

  .faq__answer__author br {
    display: block
  }

  .faq__answer__text {
    margin-top: 7px
  }
}

// вопрос врачу
.question {
  position: relative;
  background-image: url(../static/implant_dentalway__ru/build/i/b6c46c108b4b5452438740dfaea52be3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  padding-top: 91px;
  padding-bottom: 76px;

  .block-header {
    &:before {
      top: -2px;
    }
  }

  .block-header__text {
    font-family: $font-geometria;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    padding: 1px 6px;
    margin-top: 0;
  }

  .h2 {
    margin-top: 15px;
  }

  .block-label_large {
    font-size: 130px;
    line-height: 117px;
    width: 388px;

    &>span {
      -webkit-transform: translate(-57%, -50%) rotate(-90deg);
      transform: translate(-57%, -50%) rotate(-90deg);
    }
  }

  &__grid {
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;
    position: relative;

    &>* {
      flex-basis: 50%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__content {
    padding-left: 62px;
  }

  &__text {
    p {
      margin-top: 18px;
    }
  }

  &__form {
    max-width: 458px;
    margin-top: 32px;

    .input {
      padding: 19px 30px;
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 28px;
      border-color: #fff;

      &:focus,
      &:hover {
        border: 1px solid $color-primary;
        box-shadow: 0 0 20px rgba(0, 140, 212, .5);
      }
    }

    .textarea {
      padding: 19px 30px;
      min-height: 92px;
    }

    .form__group_button {
      margin-top: 30px;

      .btn {
        max-width: 100%;
      }
    }

    .form__policy {
      margin-top: 15px;
      max-width: 386px;
    }
  }

  &__image {
    position: relative;
    background-color: rgba(0, 140, 212, .3);
    max-width: 630px;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;

    &:after {
      content: "";
      display: block;
      height: 0;
      padding-top: 71.42857%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
}

@media screen and (max-width:1312px) {
  .question {
    padding-top: 65px;
    padding-bottom: 64px
  }

  .question .header__text {
    font-size: 22px
  }

  .question .h2 {
    margin-top: 8px
  }

  .question .block-label_large {
    font-size: 105px;
    line-height: 94px;
    width: 288px
  }

  .question .block-label_large>span {
    -webkit-transform: translate(-54.5%, -50%) rotate(-90deg);
    transform: translate(-54.5%, -50%) rotate(-90deg)
  }

  .question__grid {
    justify-content: center
  }

  .question__content {
    padding-left: 0;
    margin-left: -30px
  }

  .question__form {
    margin-top: 27px
  }

  .question__form .input {
    font-size: 16px;
    margin-bottom: 5px;
    padding-left: 20px
  }

  .question__form .textarea {
    padding-left: 20px
  }

  .question__form .form__group_button {
    margin-top: 0
  }

  .question__form .btn {
    max-width: 100%;
    margin-top: 5px
  }

  .question__text p {
    margin-top: 10px
  }

  .question__text .list {
    margin-top: 5px
  }

  .question__text .list li {
    margin-top: 7px
  }

  .question__text .list li:first-child {
    margin-top: 0
  }

  .question__image {
    margin-bottom: 10px;
    margin-right: 50px
  }
}

@media screen and (max-width:1023px) {
  .question {
    padding-top: 48px;
    padding-bottom: 45px
  }

  .question .block-label_large {
    display: block;
    width: 180px
  }

  .question .block-label_large>span {
    display: none
  }

  .question .header__text {
    font-size: 18px;
    line-height: 28px
  }

  .question__image {
    border-radius: 5px;
    margin-right: 33px
  }

  .question__form {
    margin-top: 20px
  }

  .question__form .input {
    font-size: 14px;
    padding-left: 20px;
    padding-top: 11px;
    padding-bottom: 11px
  }

  .question__form .input::-webkit-input-placeholder {
    font-size: 14px
  }

  .question__form .input::placeholder {
    font-size: 14px
  }

  .question__form .textarea {
    min-height: 76px
  }

  .question__form .form__group_button {
    margin-top: 10px
  }

  .question__form .form__group_button .btn {
    max-width: 263px
  }

  .question__form .form__policy {
    margin-left: 0;
    padding-left: 0;
    max-width: 100%
  }
}

@media screen and (max-width:767px) {
  .question {
    padding-top: 40px;
    padding-bottom: 31px
  }

  .question .block-label_large {
    bottom: 0;
    top: auto;
    left: 0;
    width: 100%;
    height: 173px;
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1)
  }

  .question .header__text {
    font-size: 16px;
    padding-top: 2px
  }

  .question__grid>* {
    flex-basis: 100%;
    order: 2
  }

  .question__grid>:nth-child(2) {
    order: 1
  }

  .question__content {
    padding-left: 0;
    margin-left: 0;
    padding-right: 0
  }

  .question__image {
    margin-top: 23px;
    margin-right: 0
  }

  .question__form .input {
    margin-bottom: 10px;
    border-radius: 10px
  }

  .question__form .textarea {
    min-height: 76px;
    height: auto
  }

  .question__form .form__group_button .btn {
    max-width: 300px
  }

  .question__form .form__policy {
    text-align: left
  }
}

.affiliates {
  margin-top: 94px
}

.affiliates__grid {
  align-items: center;
  margin-left: -15px;
  margin-right: -15px
}

.affiliates__grid>* {
  flex: 0 1 50%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 11px
}

.affiliates_map {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 33px;
  margin-left: -30px;
  background: #fff;
  box-shadow: 0 18px 30px rgba(0, 0, 0, .1);
  border-radius: 10px
}

.affiliates__list {
  padding-right: 30px
}

.affiliate_item {
  position: relative;
  padding-left: 27px;
  color: #6b6b6b;
  margin-top: 31px
}

.affiliate_item:first-child {
  margin-top: 0
}

.affiliate_item>* {
  font-size: 16px;
  line-height: 22px
}

.affiliate_item>:first-child {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 8px;
  color: #333
}

.affiliate_item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50%
}

.affiliate_item.red:before {
  background-color: #e94e3b
}

.affiliate_item.blue:before {
  background-color: #467fc4
}

.affiliate_item.violet:before {
  background-color: #8962ac
}

.affiliate_item.brown:before {
  background-color: #8b5544
}

.affiliate_item.yellow:before {
  background-color: #fec734
}

.affiliate_item.green:before {
  background-color: $color-primary
}

@media screen and (max-width:1312px) {
  .affiliates {
    margin-top: 47px
  }

  .affiliates__grid {
    margin-top: -10px
  }

  .affiliates__grid>* {
    margin-top: 0
  }

  .affiliate_item {
    padding-left: 22px;
    margin-top: 19px
  }

  .affiliate_item>* {
    font-size: 14px;
    line-height: 22px
  }

  .affiliate_item>:first-child {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 4px
  }

  .affiliates_map {
    padding: 14px 49px
  }
}

@media screen and (max-width:1023px) {
  .affiliates {
    margin-top: 52px
  }

  .affiliates__grid {
    margin-top: 20px
  }

  .affiliates__grid>* {
    flex-basis: 41.66%
  }

  .affiliates__grid>:nth-child(2) {
    flex-basis: 58.33%
  }

  .affiliates_map {
    padding: 0 0 0 30px;
    margin-left: 0;
    box-shadow: none
  }

  .affiliates__list {
    padding-right: 0;
    margin-top: 5px
  }

  .affiliate_item {
    margin-top: 14px
  }

  .affiliate_item:before {
    top: 3px
  }
}

@media screen and (max-width:767px) {
  .affiliates {
    margin-top: 36px
  }

  .affiliates__grid>*,
  .affiliates__grid>:nth-child(2) {
    flex-basis: 100%
  }

  .affiliates__list {
    margin-top: 0
  }

  .affiliate_item {
    margin-top: 8px
  }

  .affiliate_item>* {
    font-size: 12px;
    line-height: 22px
  }

  .affiliate_item>:first-child {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: -3px
  }

  .affiliates_map {
    padding-left: 0;
    margin-top: 13px;
    background-color: transparent
  }
}

// консультация
.consultation {
  margin-top: 78px;
  margin-bottom: 87px;

  .h2 {
    padding-left: 0;

    &:before {
      content: none;
    }
  }

  &__grid {
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-left: -15px;
    margin-right: -15px;

    &>* {
      max-width: 60%;
      padding-left: 15px;
      padding-right: 15px;
    }

    &> :nth-child(2) {
      min-width: 549px;
      padding-right: 146px;
    }

    &-wrapper {
      position: relative;
      background-color: $color-primary;
      background-image: linear-gradient(-219.35deg, $color-primary, $color-primary 32.29%, #fff);
      color: #333;
      border-radius: 10px;
      box-shadow: 0 17.6126px 29.3544px rgba(0, 0, 0, .1);
    }
  }

  &__content {
    padding: 66px 30px 73px 79px;

    .h2 {
      margin-bottom: 10px;
    }
  }

  &__text {
    font-family: $font-geometria;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    line-height: 30px;
    color: #fff;

    &>span {
      color: #10323d;
    }
  }

  &__prices {
    max-width: 410px;
    margin-top: 23px;
    min-width: unset;
    background: none;
    flex-direction: column;
    padding: 0;

    &>* {
      flex-basis: 100%;
      padding-right: 0;
      padding-top: 20px;
      width: 400px;
    }

    &> :first-child {
      padding-top: 0;
    }

    .fs__price {
      min-height: 95px;
    }

    .fs__price__price {
      margin-top: 7px;
      padding-right: 50px;
    }

    .fs__price__value {
      margin-right: 17px;

      .price {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: -3px;
      }
    }
  }

  &__form {
    background-color: $color-primary-light;
    border-radius: 10px;
    height: calc(100% + 54px);
    max-width: 403px;
    margin-top: -27px;
    padding: 44px 40px 14px;

    .form__title {
      text-align: center;
      line-height: 38px;
    }

    .form__text {
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      margin-top: 18px;
      margin-bottom: 32px;
    }

    .form__group-wrapper {
      max-width: 302px;
      margin-left: auto;
      margin-right: auto;
    }

    .form__policy {
      max-width: 260px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.bg-block {
  .consultation {
    margin-bottom: 67px;
  }
}

.form__text-phone {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  font-size: 28px;
  line-height: 35px;
  color: #333;
  display: inline-block;
  margin-top: 17px;
  margin-bottom: 12px;
  white-space: nowrap
}

.form__text-phone>span {
  color: $color-primary
}

@media screen and (max-width:1312px) {
  .consultation {
    margin-top: 41px
  }

  .bg-block .consultation {
    margin-bottom: 0
  }

  .consultation__grid-wrapper {
    box-shadow: none
  }

  .consultation__grid>* {
    min-width: 50%
  }

  .consultation__grid>:nth-child(2) {
    width: 40%;
    min-width: 413px;
    padding-right: 25px
  }

  .consultation__content {
    padding: 50px 30px 50px 50px
  }

  .consultation__text {
    font-size: 20px;
    line-height: 25px;
    max-width: 382px
  }

  .consultation__prices {
    margin-top: 12px
  }

  .consultation__prices>* {
    padding-top: 10px;
    max-width: 100%
  }

  .consultation__form {
    height: calc(100% + 40px)
  }

  .consultation__form .form__title {
    font-size: 30px
  }

  .consultation__form .form__text {
    margin: 17px 0 15px;
    max-width: unset
  }

  .consultation__form .form__group-wrapper {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto
  }

  .consultation__form .btn {
    max-width: 100%
  }

  .form__text-phone {
    font-size: 22px;
    line-height: 28px;
    margin-top: 6px;
    margin-bottom: 9px
  }
}

@media screen and (max-width:1023px) {
  .page_success .consultation {
    margin-bottom: 26px
  }

  .consultation__grid {
    margin-left: 0;
    margin-right: 0;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px
  }

  .consultation__grid>* {
    padding-left: 0;
    padding-right: 0
  }

  .consultation__grid>:nth-child(2) {
    max-width: 360px;
    min-width: unset;
    padding-right: 10px
  }

  .consultation__content {
    padding: 0 30px 0 32px
  }

  .consultation__prices {
    margin-top: 23px
  }

  .consultation__prices .fs__price {
    min-height: 108px;
    padding-top: 16px
  }

  .consultation__prices .fs__price__price {
    padding-right: 0
  }

  .consultation__prices .fs__price__value {
    margin-right: 12px
  }

  .consultation__prices .fs__price__value .price {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: -1px
  }

  .consultation__prices .fs__price__text {
    max-width: 100%
  }

  .consultation__prices .fs__price_second {
    padding-top: 10px
  }

  .consultation__form {
    padding: 30px 15px;
    height: 100%;
    margin-top: 0
  }

  .consultation__form .form__text {
    margin-bottom: 23px
  }

  .consultation__form .form__policy {
    text-align: center;
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 13px
  }

  .form__text-phone {
    font-size: 24px;
    line-height: 30px;
    margin-top: 12px;
    margin-bottom: 11px
  }
}

@media screen and (max-width:767px) {
  .consultation {
    margin-top: 20px;
    margin-bottom: 30px
  }

  .consultation .row {
    padding: 0
  }

  .page_success .consultation {
    margin-bottom: 0
  }

  .consultation__grid-wrapper {
    border-radius: 0;
    background: none
  }

  .consultation__grid {
    padding: 0;
    flex-direction: column
  }

  .consultation__grid>* {
    max-width: 100%;
    min-width: 100%
  }

  .consultation__grid>:first-child {
    background-color: $color-primary;
    background-image: linear-gradient(-219.35deg, $color-primary, $color-primary 32.29%, #fff)
  }

  .consultation__grid>:nth-child(2) {
    max-width: 100%;
    min-width: 100%;
    padding-right: 0
  }

  .consultation__content {
    padding: 30px 16px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center
  }

  .consultation__content .h2 {
    font-size: 20px;
    line-height: 25px;
    text-align: center
  }

  .consultation__text {
    font-size: 18px;
    line-height: 23px;
    margin-top: 1px;
    max-width: 610px;
    margin-left: auto;
    margin-right: auto
  }

  .consultation__prices {
    max-width: unset
  }

  .consultation__form,
  .consultation__prices>* {
    margin-left: auto;
    margin-right: auto
  }

  .consultation__form {
    margin-top: -25px
  }

  .consultation__form .form__text {
    margin-bottom: 27px
  }

  .form__text-phone {
    font-size: 22px;
    line-height: 28px;
    margin-top: 10px;
    margin-bottom: 8px
  }

  .consultation_rounded {
    border-radius: 5px;
    margin-left: 5px;
    margin-right: 5px
  }

  .consultation_rounded .row {
    padding: 0 11px
  }
}

@media screen and (max-width:479px) {
  .consultation {
    margin-bottom: 0
  }

  .consultation__grid>:nth-child(2) {
    background-color: $color-primary-light
  }

  .consultation__content {
    padding-bottom: 25px
  }

  .consultation__content .h2 {
    text-align: left
  }

  .consultation__form {
    max-width: 100%;
    border-radius: 0;
    margin-top: 0;
    padding: 25px 16px;
    background: none
  }

  .consultation__form .form__group-wrapper {
    margin-left: auto
  }
}

// бесплатная диагностика
.free-diagnostic {
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url(../static/implant_dentalway__ru/build/i/23781c0ab0cd4bebb4064dc237f31dd1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;

  .block-label_large {
    font-size: 130px;
    width: 395px;

    &>span {
      &>span {
        margin-bottom: 0;
      }
    }
  }

  .header__text {
    font-family: $font-geometria;
    font-weight: 700;
    font-style: normal;
    font-size: 24px;
    line-height: 28px;
    margin-top: 0;
  }

  .h2 {
    margin-top: 20px;
  }

  .block-header {
    .block-header__text {
      font-size: 24px;
      font-family: $font-geometria;
      font-weight: 700;
      font-style: normal;
    }
  }

  &__grid {
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;

    &>* {
      flex-basis: calc(100% - 660px);
      padding-left: 15px;
      padding-right: 15px;
    }

    &> :nth-child(2) {
      flex-basis: 660px;
    }
  }

  &__text {
    position: relative;
    font-size: 18px;
    line-height: 28px;
    max-width: 555px;
    padding-left: 23px;
    margin-top: 34px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 7px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $color-primary;
    }
  }

  &__image {
    position: relative;
    background-color: rgba(0, 140, 212, .3);
    border-radius: 10px;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      height: 0;
      padding-top: 71.42857%;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  &__content {
    margin-top: 3px;
  }

  &__button {
    margin-top: 40px;
    position: relative;
    max-width: 540px;
    padding-right: 160px;

    .btn {
      max-width: 362px;
    }

    &__image {
      position: absolute;
      right: 4px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    &-after {
      font-family: $font-geometria;
      font-weight: 400;
      font-style: italic;
      color: #7f8f98;
      font-size: 16px;
      line-height: 22px;
      margin-top: 28px;
    }
  }
}

@media screen and (max-width:1312px) {
  .free-diagnostic {
    padding-top: 67px;
    padding-bottom: 67px
  }

  .free-diagnostic .header__text {
    font-size: 22px
  }

  .free-diagnostic .h2 {
    margin-top: 10px
  }

  .free-diagnostic__grid>* {
    flex-basis: calc(100% - 430px)
  }

  .free-diagnostic__grid>:nth-child(2) {
    flex-basis: 430px
  }

  .free-diagnostic__text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px
  }

  .free-diagnostic__button {
    margin-top: 23px;
    padding-right: 151px
  }

  .free-diagnostic__button__image {
    right: 0
  }

  .free-diagnostic__button-after {
    margin-top: 17px
  }
}

@media screen and (max-width:1023px) {
  .free-diagnostic {
    padding-top: 50px;
    padding-bottom: 50px
  }

  .free-diagnostic .block-label_large {
    display: block;
    width: 182px
  }

  .free-diagnostic .block-label_large * span {
    display: none
  }

  .free-diagnostic .header__text {
    font-size: 18px
  }

  .free-diagnostic .h2 {
    margin-top: 16px
  }

  .free-diagnostic .block-header .block-header__text {
    font-size: 18px
  }

  .free-diagnostic__grid>* {
    flex-basis: calc(100% - 345px)
  }

  .free-diagnostic__grid>:nth-child(2) {
    flex-basis: 345px
  }

  .free-diagnostic__text {
    font-size: 14px;
    line-height: 22px
  }

  .free-diagnostic__image-wrapper {
    padding-right: 33px
  }

  .free-diagnostic__image {
    border-radius: 5px
  }

  .free-diagnostic__button {
    padding-right: 93px
  }

  .free-diagnostic__button__image {
    max-width: 118px;
    top: calc(100% + 28px);
    -webkit-transform: none;
    transform: none;
    right: auto
  }

  .free-diagnostic__button-after {
    font-size: 14px;
    line-height: 22px;
    margin-top: 23px;
    padding-left: 136px;
    min-height: 75px
  }

  .free-diagnostic__button-after br {
    display: none
  }
}

@media screen and (max-width:767px) {
  .free-diagnostic {
    padding-top: 40px;
    padding-bottom: 40px;
    background-image: none;
    background-color: $color-bg-gray
  }

  .free-diagnostic .block-label_large {
    bottom: 0;
    top: auto;
    left: 0;
    width: 100%;
    height: 173px;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg)
  }

  .free-diagnostic .header__text {
    font-size: 16px
  }

  .free-diagnostic .h2 {
    margin-top: 12px
  }

  .free-diagnostic .block-header .block-header__text {
    font-size: 16px
  }

  .free-diagnostic__grid>*,
  .free-diagnostic__grid>:nth-child(2) {
    flex-basis: 100%
  }

  .free-diagnostic__content {
    margin-left: 0
  }

  .free-diagnostic__button {
    padding-right: 0
  }

  .free-diagnostic__button__image {
    top: calc(100% + 36px);
    max-width: 106px
  }

  .free-diagnostic__button-after {
    font-size: 14px;
    line-height: 20px;
    padding-left: 120px;
    margin-top: 21px;
    min-height: 80px
  }

  .free-diagnostic__image-wrapper {
    padding-right: 0;
    padding-top: 20px
  }

  .free-diagnostic__note {
    font-size: 12px;
    line-height: 20px;
    padding-left: 91px;
    padding-right: 13px;
    min-height: 146px
  }
}

// карта
.map {
  position: relative;
  height: 390px;
  border-radius: 10px;
  overflow: hidden;

  &-wrapper {
    margin-top: 87px;
    margin-bottom: 100px;

    &__list {
      align-items: center;
      margin-bottom: 30px;
    }

    &__item {
      position: relative;
      padding-left: 23px;

      &:not(:last-child) {
        margin-right: 50px
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $color-primary
      }
    }
  }
}

@media screen and (max-width:1312px) {
  .map-wrapper {
    margin-top: 80px;
    margin-bottom: 50px
  }

  .map {
    height: 360px
  }
}

@media screen and (max-width:1023px) {
  .map-wrapper {
    margin-top: 45px;
    margin-bottom: 50px
  }

  .map {
    height: 280px
  }
}

@media screen and (max-width:767px) {
  .map-wrapper {
    margin-top: 40px;
    margin-bottom: 40px
  }
}

.quiz {
  position: relative;
  padding-top: 82px;
  padding-bottom: 100px;
  overflow: hidden;
  background-color: $color-bg-gray
}

.quiz .block-label_large {
  width: 515px;
  font-size: 187px;
  line-height: 169px
}

.quiz .block-label_large>span {
  -webkit-transform: translate(-58%, -50%) rotate(-90deg);
  transform: translate(-58%, -50%) rotate(-90deg)
}

.quiz__form {
  position: relative;
  z-index: 2;
  border-radius: 5px;
  overflow: hidden;
  min-height: 670px;
  transition: height .1s ease
}

.quiz__grid>* {
  flex: 0 1 410px;
  max-width: 410px
}

.quiz__grid>:first-child {
  margin-right: 20px
}

.quiz__grid>:nth-child(2) {
  flex-basis: calc(100% - 430px);
  max-width: calc(100% - 430px)
}

.quiz__employee {
  background-color: #fff;
  padding: 52px 60px 60px;
  border-radius: 5px
}

.quiz__employee__photo-wrapper {
  text-align: center;
  position: relative
}

.quiz__employee__photo-wrapper .experience {
  font-family: $font-geometria;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  background: $color-primary;
  display: inline-block;
  padding: 6px 14px;
  border-radius: 5px;
  font-size: 14px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -7px
}

.quiz__employee__photo {
  position: relative;
  max-width: 186px;
  border-radius: 50%;
  background-color: #f1ebe4;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto
}

.quiz__employee__photo:after {
  content: "";
  display: block;
  height: 0;
  padding-top: 100%
}

.quiz__employee__photo img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%
}

.quiz__employee__fio {
  text-align: center;
  margin-top: 25px
}

.quiz__employee__fio strong {
  display: block;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 8px
}

.quiz__employee__fio>span {
  font-family: $font-geometria;
  font-weight: 400;
  font-style: italic;
  font-size: 12px;
  line-height: 20px;
  color: #6b6b6b
}

.quiz__employee__text {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: #333;
  padding-top: 25px;
  margin-top: 13px;
  min-height: 190px
}

.quiz__employee__text:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 17px;
  height: 11px;
  background-image: url(../static/implant_dentalway__ru/build/i/6a1c5d95fc8bdb293685d1da3a6fbd0b.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0
}

.quiz__employee__button {
  margin-top: 73px;

  @include respond-to(lg) {
    margin-top: 20px;
  }
}

.quiz__form__pre-title {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: 28px;
  background: $color-primary;
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  margin-top: 6px;
  padding: 1px 8px 2px 7px;
  margin-bottom: 6px
}

.quiz__form__subtitle {
  font-size: 18px;
  line-height: 28px;
  position: relative;
  padding-left: 23px;
  margin-top: 21px
}

.quiz__form__subtitle:before {
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $color-primary
}

.quiz__form__progress {
  margin-top: 17px
}

.quiz__form__progress__step {
  font-family: $font-geometria;
  font-weight: 400;
  font-style: italic;
  font-size: 14px;
  line-height: 18px;
  color: #6b6b6b
}

.quiz__form__progress__line {
  background: #fff;
  border-radius: 10px;
  width: 100%;
  height: 15px;
  position: relative;
  overflow: hidden;
  margin-top: 14px
}

.quiz__form__progress__line>span {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 20%;
  height: 100%;
  border-radius: 10px;
  background: $color-primary;
  transition: width .1s ease
}

.quiz__page__title {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  font-size: 25px;
  line-height: 31px;
  color: #333;
  margin-top: 36px
}

.quiz__page__content {
  margin-top: 10px;
  min-height: 245px
}

.quiz__radio_images {
  margin-left: -5px;
  margin-right: -5px
}

.quiz__radio_images>* {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 15px
}

.quiz__radio_images .radio__item {
  padding: 10px 10px 20px;
  background: #fff;
  border-radius: 5px
}

.quiz__radio_images .radio__item .text {
  margin-left: 9px;
  margin-top: 22px
}

.radio__item {
  cursor: pointer
}

.radio__item .text {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 26px;
  color: #333;
  position: relative;
  height: 30px;
  padding-left: 42px
}

.radio__item .text:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  border: 1px solid #d1c9bd;
  background-image: none;
  background-size: 13px 10px;
  background-position: center left 8px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  border-radius: 5px
}

.radio__item.checked {
  cursor: default
}

.radio__item.checked .text:before {
  background-image: url(../static/implant_dentalway__ru/build/i/7a7c409581b721b5b652aad438d30225.svg)
}

.radio__item img {
  border-radius: 5px
}

.radio__item input[type=checkbox],
.radio__item input[type=radio] {
  display: none
}

.quiz__radio_text {
  padding-top: 1px
}

.quiz__radio_text>* {
  margin-top: 15px
}

.quiz__radio_text .radio__item .text {
  padding-left: 52px
}

.quiz__radio_text_column>* {
  flex: 0 1 40%;
  padding-right: 15px
}

.quiz__radio_text_column>:nth-child(2n) {
  flex-basis: 60%;
  padding-right: 0
}

.quiz__pages__navigation {
  margin-top: 10px
}

.quiz__pages__navigation .btn_back {
  margin-right: 20px
}

.btn_forward {
  max-width: 290px
}

.quiz__pages__gift {
  display: flex;
  align-items: center;
  background-color: $color-primary;
  max-width: 454px;
  margin-top: 20px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  padding-left: 138px;
  color: #fff;
  min-height: 88px;
  z-index: 1
}

.quiz__pages__gift:before {
  content: "";
  position: absolute;
  left: 5px;
  top: -7px;
  width: 68px;
  height: 64px;
  background-image: url(../static/implant_dentalway__ru/build/i/6fc69f57fcd1b39200e056a0cb5421e9.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  z-index: -1
}

.quiz__pages__gift>i {
  position: absolute;
  max-width: 79px;
  top: 12px;
  left: 41px
}

.quiz__pages__gift>div {
  font-family: $font-geometria;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 20px
}

.quiz__pages__gift>div b {
  color: #fff
}

.quiz__pages__gift>div>span {
  color: $color-primary
}

.quiz__results__count {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  height: 100%;
  background: $color-bg-gray
}

.quiz__results__count>div {
  width: 100%
}

.quiz__results__progress {
  position: relative;
  background: #fff;
  height: 20px;
  width: 100%;
  max-width: 770px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 22px;
  border-radius: 10px
}

.quiz__results__progress .bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: linear-gradient(176deg, $color-primary, $color-primary 32.29%, #fff);
  width: 0;
  transition: width .1s ease;
  border-radius: 10px
}

.quiz__results__text {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  font-size: 25px;
  line-height: 31px;
  color: #333
}

.quiz__results__value {
  font-family: $font-geometria;
  font-weight: 400;
  font-style: italic;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  margin-top: 21px;
  color: $color-primary
}

.quiz__loader {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.quiz__loader svg {
  stroke: $color-primary !important
}

.quiz__results {
  left: 0;
  width: 100%;
  display: none;
  background: $color-bg-gray;
  z-index: 1
}

.quiz__results,
.quiz__results:after {
  top: 0;
  height: 100%;
  position: absolute;
  border-radius: 5px
}

.quiz__results:after {
  content: "";
  right: 0;
  width: 410px;
  background: linear-gradient(176deg, $color-primary, $color-primary 32.29%, #fff);
  z-index: -1
}

.quiz__results__form {
  padding-left: 80px;
  flex: 0 1 640px;
  margin-top: 85px;
  padding-bottom: 36px
}

.quiz__results__form>* {
  max-width: 550px
}

.quiz__results__form .input {
  background-color: transparent;
  border-color: #cdcdcd
}

.quiz__results__header {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal;
  font-size: 38px;
  line-height: 48px;
  color: #333
}

.quiz__results__sub-header {
  font-size: 18px;
  line-height: 28px;
  color: #333;
  margin-top: 20px;
  max-width: 460px
}

.quiz__results__form-inner {
  max-width: 410px;
  margin-top: 35px
}

.quiz__results__form-inner .btn {
  max-width: 410px
}

.quiz__results__form-inner .form__policy {
  margin-left: 0;
  text-align: left;
  max-width: none
}

.quiz__results__image-wrapper {
  flex: 0 1 calc(100% - 550px);
  position: relative
}

.quiz__results__image {
  position: absolute;
  right: 0;
  bottom: 0
}

.quiz__results__gift {
  max-width: 410px;
  padding-left: 179px
}

.quiz__results__gift>i {
  max-width: 117px;
  left: 38px;
  top: 10px
}

.quiz__pages__gift_adaptive {
  display: none
}

@media screen and (max-width:1312px) {
  .quiz {
    padding-top: 57px;
    padding-bottom: 70px
  }

  .quiz .block-label_large {
    width: 289px
  }

  .quiz .block-label_large>span {
    display: none
  }

  .quiz__form {
    background-color: transparent;
    min-height: 582px
  }

  .quiz__grid {
    align-items: center
  }

  .quiz__grid>* {
    flex-basis: 350px;
    max-width: 350px
  }

  .quiz__grid>:nth-child(2) {
    flex-basis: calc(100% - 370px);
    max-width: calc(100% - 370px)
  }

  .quiz__employee {
    // height: 689px;
    margin-top: 14px;
    padding: 23px 30px 96px;
    position: relative
  }

  .quiz__employee .btn {
    position: absolute;
    left: 50%;
    bottom: 30px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    max-width: 290px
  }

  .quiz__employee__photo {
    max-width: 160px
  }

  .quiz__employee__fio {
    margin-top: 16px
  }

  .quiz__employee__fio strong {
    margin-bottom: 5px
  }

  .quiz__employee__fio>span {
    font-size: 12px;
    line-height: 16px;
    display: inline-block
  }

  .quiz__employee__text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 15px
  }

  .quiz__form-wrapper {
    padding-left: 22px
  }

  .quiz__form-wrapper .h2 {
    margin-bottom: 10px
  }

  .quiz__form__pre-title {
    font-size: 20px;
    margin-top: 0;
    padding: 1px 3px 0 7px
  }

  .quiz__form__subtitle {
    font-size: 16px;
    margin-top: 12px
  }

  .quiz__form__progress {
    margin-top: 11px
  }

  .quiz__form__progress__line {
    margin-top: 9px
  }

  .quiz__page__title {
    margin-top: 27px
  }

  .quiz__radio_images {
    margin-left: -8px;
    margin-right: -8px;
    max-width: 500px;
    margin-top: -14px
  }

  .quiz__radio_images>* {
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 15px;
    flex-basis: 50%;
    max-width: 190px
  }

  .quiz__radio_images .radio__item {
    padding: 5px 5px 10px
  }

  .quiz__radio_images .radio__item .text {
    margin-top: 10px
  }

  .quiz__radio_text_column>*,
  .quiz__radio_text_column>:nth-child(2n) {
    flex-basis: 100%
  }

  .quiz__pages__navigation {
    margin-top: 31px
  }

  .quiz__pages__navigation .btn_forward {
    max-width: 211px
  }

  .quiz__pages__gift {
    max-width: 409px;
    margin-top: 15px;
    padding: 11px 10px 13px 133px
  }

  .quiz__pages__gift>i {
    left: 34px
  }

  .quiz__results__form {
    position: relative;
    z-index: 2;
    padding-left: 49px;
    margin-top: 50px;
    flex-basis: calc(100% - 480px)
  }

  .quiz__results__image-wrapper {
    flex-basis: 100%;
    max-width: 454px;
    position: absolute;
    right: 0;
    bottom: 0
  }

  .quiz__results__image {
    position: relative
  }

  .quiz__results__form-inner {
    max-width: 351px
  }

  .quiz__results__gift {
    max-width: 351px;
    padding-left: 139px
  }

  .quiz__results__gift>i {
    left: 18px;
    max-width: 103px;
    top: 14px
  }
}

@media screen and (max-width:1023px) {
  .quiz {
    padding-top: 54px;
    padding-bottom: 61px
  }

  .quiz .block-label_large {
    left: auto;
    right: 0;
    width: 147px;
    display: block
  }

  .quiz__grid {
    margin-left: 0;
    margin-right: 0;
    padding-right: 252px
  }

  .quiz__grid>* {
    padding-left: 0;
    padding-right: 0
  }

  .quiz__grid>:first-child {
    display: none
  }

  .quiz__grid>:nth-child(2) {
    flex-basis: 100%;
    max-width: 100%
  }

  .quiz__form {
    overflow: visible;
    min-height: 525px
  }

  .quiz__form-wrapper {
    padding-left: 0
  }

  .quiz__form__pre-title {
    font-size: 18px;
    margin-left: -7px;
    line-height: 25px;
    padding: 0 6px;
    margin-bottom: 8px
  }

  .quiz__form__subtitle {
    font-size: 14px;
    margin-top: 14px
  }

  .quiz__form__progress {
    margin-top: 6px
  }

  .quiz__form__progress__step {
    font-size: 12px;
    line-height: 15px
  }

  .quiz__page__title {
    font-size: 22px;
    line-height: 28px
  }

  .quiz__page__content {
    margin-top: 8px
  }

  .quiz__radio_images {
    margin-top: 0;
    max-width: calc(100% + 14px);
    margin-left: -7px;
    margin-right: -7px
  }

  .quiz__radio_images>* {
    margin-top: 14px;
    padding-left: 7px;
    padding-right: 7px;
    flex-basis: 33.33%;
    max-width: 33.33%
  }

  .quiz__radio_images .radio__item .text {
    margin-top: 12px
  }

  .radio__item .text {
    font-size: 14px;
    line-height: 16px
  }

  .quiz__radio_text>* {
    margin-top: 10px
  }

  .quiz__pages__navigation .btn_forward {
    max-width: 159px
  }

  .quiz__pages__navigation .btn_back {
    margin-right: 10px
  }

  .quiz__pages__gift {
    display: none
  }

  .quiz__pages__gift_adaptive {
    display: block;
    position: absolute;
    right: 0;
    top: 256px;
    max-width: 222px;
    padding: 111px 15px 16px;
    text-align: center
  }

  .quiz__pages__gift_adaptive>i {
    max-width: 92px;
    left: 50%;
    top: 17px;
    -webkit-transform: none;
    transform: none;
    margin-left: -46px
  }

  .quiz__results__progress {
    max-width: 600px
  }

  .quiz__results:after {
    width: 222px
  }

  .quiz__results__header {
    font-size: 28px;
    line-height: 35px
  }

  .quiz__results__sub-header {
    font-size: 14px;
    line-height: 22px;
    margin-top: 12px
  }

  .quiz__results__form-inner {
    margin-top: 27px
  }

  .quiz__results__image-wrapper {
    max-width: 370px;
    right: -30px
  }

  .quiz__results__form {
    flex-basis: calc(100% - 360px);
    margin-top: 53px
  }

  .quiz__results__form .form__policy {
    margin-top: 14px
  }

  .quiz__results__gift {
    display: block;
    padding-left: 117px;
    margin-top: 22px
  }

  .quiz__results__gift>i {
    max-width: 87px;
    left: 18px;
    top: 19px
  }
}

@media screen and (max-width:767px) {
  .quiz {
    padding-top: 36px;
    padding-bottom: 51px
  }

  .quiz .block-label_large {
    display: none
  }

  .quiz__form {
    min-height: 500px;
    overflow: hidden
  }

  .quiz__grid {
    padding-right: 0
  }

  .quiz__grid>:nth-child(2) {
    max-width: 100%;
    flex-basis: 100%
  }

  .quiz__form__pre-title {
    font-size: 16px;
    line-height: 20px;
    margin-left: -3px;
    padding: 1px 6px
  }

  .quiz__form-wrapper .h2 {
    font-size: 22px;
    line-height: 28px
  }

  .quiz__form-wrapper .h2 span {
    white-space: nowrap
  }

  .quiz__form__subtitle {
    margin-top: 8px;
    padding-left: 18px
  }

  .quiz__form__progress {
    margin-top: 4px
  }

  .quiz__page__title {
    font-size: 18px;
    line-height: 23px
  }

  .quiz__radio_images>* {
    flex-basis: 50%;
    max-width: 190px
  }

  .quiz__radio_images .radio__item .text {
    padding-left: 36px
  }

  .quiz__page__content {
    margin-top: 0
  }

  .quiz__pages__navigation {
    margin-top: 19px
  }

  .quiz__pages__navigation .btn_back {
    margin-right: 10px
  }

  .quiz__pages__gift {
    display: block;
    margin-top: 30px;
    padding-left: 109px
  }

  .quiz__pages__gift>i {
    max-width: 68px;
    left: 23px;
    top: 33px
  }

  .quiz__pages__gift_adaptive {
    display: none
  }

  .quiz__results__count {
    padding: 0 23px
  }

  .quiz__results__text {
    font-size: 18px;
    line-height: 23px
  }

  .quiz__results__progress {
    height: 15px;
    margin-top: 16px
  }

  .quiz__results__value {
    font-size: 20px;
    line-height: 25px;
    margin-top: 16px
  }

  .quiz__results:after {
    content: none
  }

  .quiz__results__form {
    padding: 0 23px;
    flex-basis: 100%;
    margin-top: 32px
  }

  .quiz__results__header {
    font-size: 24px;
    line-height: 30px
  }

  .quiz__results__sub-header {
    font-size: 12px;
    line-height: 20px
  }

  .quiz__results__form-inner {
    margin-top: 12px
  }

  .quiz__results__form-inner .form__policy {
    padding-left: 0;
    text-align: center
  }

  .quiz__results__gift {
    padding: 67px 20px 10px;
    text-align: center;
    margin-top: 19px
  }

  .quiz__results__gift>i {
    max-width: 84px;
    left: 50%;
    margin-left: -42px;
    top: 11px
  }

  .quiz__results__image-wrapper {
    display: none
  }
}

.dont-go__content {
  padding-left: 52px
}

.dont-go__text {
  padding-left: 58px
}

.dont-go__text p {
  font-family: Gotham, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 18px
}

.dont-go__text .checked>li {
  font-size: 16px;
  margin-top: 16px
}

.dont-go__form {
  padding-left: 58px;
  margin-top: 35px
}

.dont-go__form .form {
  max-width: 370px;
  margin-top: 17px
}

.dont-go__form .form__policy {
  margin-top: 23px
}

.dont-go__employees {
  justify-content: flex-start;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
  display: flex;
  max-width: 575px;
  margin-top: 35px
}

.dont-go__employees>* {
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 1 33.33%
}

.dont-go__employee__photo {
  position: relative;
  background-color: rgba(51, 51, 51, .5);
  max-width: 160px
}

.dont-go__employee__photo:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 35px;
  background-image: url(../static/implant_dentalway__ru/build/i/6d8975e0807d9596fdd14a52c6ab4c43.svg), url(../static/implant_dentalway__ru/build/i/90f0a2fa58246079527aa2a4c0e76b5f.svg);
  background-size: 16px 16px, contain;
  background-position: center top 7px, top;
  background-repeat: no-repeat;
  z-index: 2
}

.dont-go__employee__photo:after {
  content: "";
  display: block;
  height: 0;
  padding-top: 118.75%
}

.dont-go__employee__photo img {
  position: absolute;
  left: 0;
  top: 0
}

.dont-go__employee__name {
  font-family: Gotham, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  margin-top: 24px
}

.dont-go__employee__profession {
  font-size: 12px;
  line-height: 18px;
  color: #7f8f98;
  margin-top: 6px
}

.dont-go__employee__experience {
  margin-top: 7px
}

.dont-go__employee__experience,
.dont-go__employee__feature {
  font-family: Gotham, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  color: #333
}

.dont-go__advantages {
  justify-content: flex-start;
  margin-right: -10px;
  flex-wrap: wrap;
  display: flex;
  margin-top: 50px;
  max-width: 584px;
  margin-left: -25px
}

.dont-go__advantages>* {
  padding-left: 10px;
  padding-right: 10px;
  flex: 0 1 25%
}

.dont-go__advantage {
  text-align: center;
  margin-top: 28px
}

.dont-go__advantage__image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 102px;
  background-image: url(../static/implant_dentalway__ru/build/i/85dc7e52be3b5c21023d3cae5581ee3e.svg);
  background-repeat: no-repeat;
  background-size: 80px 102px;
  background-position: 50%;
  padding-bottom: 12px
}

.dont-go__advantage__text {
  font-size: 12px;
  line-height: 18px;
  margin-top: 22px
}

@media screen and (max-width:1312px) {
  .modal_dont-go .modal__wrapper {
    padding: 57px 48px 66px
  }

  .modal_dont-go .modal__grid>*,
  .modal_dont-go .modal__grid>:nth-child(2) {
    flex-basis: 50%
  }

  .modal_dont-go .modal__form {
    max-width: 242px
  }

  .modal_dont-go .modal__content {
    margin-top: 25px
  }

  .modal_dont-go .best__awards-wrapper {
    margin-left: 1px
  }

  .modal_dont-go .best__awards {
    display: flex;
    flex-wrap: wrap;
    max-width: 420px
  }

  .modal_dont-go .best__awards>* {
    margin-top: 5px;
    flex-basis: 100%
  }

  .modal_dont-go .best__awards .tall {
    order: 5
  }

  .modal_dont-go .best__awards .tall .best__award {
    padding-bottom: 0
  }

  .modal_dont-go .best__awards .tall .best__award i.icon-large {
    height: 40px
  }

  .modal_dont-go .best__award {
    min-height: 57px;
    padding-top: 0;
    padding-left: 0;
    font-size: 14px;
    line-height: 18px;
    display: block;
    text-align: left
  }

  .modal_dont-go .best__award:before {
    content: none
  }

  .modal_dont-go .best__award>div {
    padding-left: 62px;
    display: flex;
    align-items: center;
    height: 100%
  }

  .modal_dont-go .best__award i {
    max-width: 37px;
    width: 37px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    text-align: center
  }

  .modal_dont-go .best__award i.icon-large {
    max-width: 37px
  }

  .modal_dont-go .best__award i img {
    max-height: 100%;
    width: auto
  }

  .modal_dont-go .best__award .title {
    margin-top: 0
  }

  .modal_dont-go .best__image {
    margin-left: 0;
    margin-top: 21px
  }

  .modal__employees>* {
    flex-basis: 50%
  }

  .modal__employees>:nth-child(3) {
    display: none
  }

  .modal__employees .employee__fio {
    padding: 0 7px
  }
}

@media screen and (max-width:1023px) {
  .modal_dont-go .modal__wrapper {
    padding: 37px 53px 50px 32px
  }

  .modal_dont-go .modal__grid>* {
    flex-basis: 60%
  }

  .modal_dont-go .modal__grid>:nth-child(2) {
    flex-basis: 40%
  }

  .modal_dont-go .modal__content {
    padding-right: 40px;
    margin-top: 5px
  }

  .modal_dont-go .modal__form {
    max-width: 218px
  }

  .modal_dont-go .modal__form .form__policy {
    padding-left: 0;
    margin-left: -5px;
    margin-right: -5px
  }

  .modal_dont-go .modal__employees {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-left: 0;
    margin-right: auto
  }

  .modal_dont-go .modal__employees>* {
    flex-basis: 100%;
    margin-top: 15px;
    padding: 0
  }

  .modal_dont-go .modal__employees>:first-child {
    margin-top: 0
  }

  .modal_dont-go .modal__employees .employee__content,
  .modal_dont-go .modal__employees .employee__fio {
    padding: 0
  }

  .modal_dont-go .modal__employees .employee__profession {
    padding: 0 3px
  }

  .modal_dont-go .best__awards-wrapper {
    display: none
  }
}

@media screen and (max-width:767px) {
  .modal_dont-go .modal__grid {
    margin: 0
  }

  .modal_dont-go .modal__grid>* {
    flex-basis: 100%;
    padding: 0
  }

  .modal_dont-go .modal__grid>:nth-child(2) {
    flex-basis: 100%
  }

  .modal_dont-go .modal__wrapper {
    padding: 30px 23px 45px
  }

  .modal_dont-go .modal__content {
    padding-right: 0
  }

  .modal_dont-go .modal__text .list {
    margin-top: 10px
  }

  .modal_dont-go .modal__form {
    max-width: 340px
  }

  .modal_dont-go .modal__employees>* {
    display: none
  }

  .modal_dont-go .modal__employees>:first-child {
    display: block
  }

  .modal_dont-go .modal__employees .employee__profession {
    padding: 0
  }

  .modal_dont-go .employee {
    display: flex;
    align-items: center;
    border-top: 1px solid #ececec;
    margin-top: 16px;
    padding-bottom: 25px;
    position: relative;
    padding-top: 11px
  }

  .modal_dont-go .employee__photo {
    width: 63px;
    height: 63px;
    margin-right: 10px
  }

  .modal_dont-go .employee__content {
    flex-basis: calc(100% - 73px);
    margin-top: 0
  }

  .modal_dont-go .employee__fio {
    text-align: left;
    font-size: 12px;
    line-height: 14px
  }

  .modal_dont-go .employee__profession {
    text-align: left;
    font-size: 10px;
    line-height: 14px
  }

  .modal_dont-go .employee__about {
    position: absolute;
    width: 100%;
    bottom: -7px;
    left: 0
  }

  .modal_dont-go .employee__about>* {
    margin-right: 7px;
    padding-top: 3px;
    padding-bottom: 2px
  }

  .modal_dont-go .employee__about>:last-child {
    margin-right: 0
  }

  .modal_dont-go .employee__about .experience {
    padding-left: 6px;
    padding-right: 8px
  }
}

.text-block {
  margin-top: 100px;
  margin-bottom: 99px
}

.text-block__grid {
  margin-left: -15px;
  margin-right: -15px
}

.text-block__grid>* {
  flex: 0 1 50%;
  padding-left: 15px;
  padding-right: 15px
}

.text-block__grid_reverse {
  flex-direction: row-reverse
}

.text-block__grid_reverse .text-block__content {
  padding-left: 60px;
  margin-top: 15px
}

.text-block__grid_reverse .text-block__text {
  margin-top: 20px
}

.text-block__content,
.text-block__text {
  margin-top: 40px
}

.text-block__image {
  position: relative;
  background-color: rgba(0, 140, 212, .3);
  max-width: 630px;
  border-radius: 10px;
  overflow: hidden
}

.text-block__image:after {
  content: "";
  display: block;
  height: 0;
  padding-top: 71.42857%
}

.text-block__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%
}

.text-block_center .text-block__grid {
  align-items: center
}

.text-block_center .text-block__content {
  margin-top: 0
}

.text-block_center .text-block__text {
  margin-top: 34px
}

.text-block_image-tall .text-block__content {
  margin-top: 10px
}

.text-block_image-tall .text-block__text {
  margin-top: 32px;
  max-width: 630px
}

.text-block_image-tall .text-block__image {
  position: relative;
  max-width: 519px
}

.text-block_image-tall .text-block__image:after {
  content: "";
  display: block;
  height: 0;
  padding-top: 100.57803%
}

.text-block_image-tall .text-block__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%
}

.text-block_image-tall .text-block__grid>* {
  flex-basis: 58.33%
}

.text-block_image-tall .text-block__grid>:nth-child(2) {
  flex-basis: 41.66%
}

@media screen and (max-width:1312px) {
  .text-block {
    margin-top: 100px;
    margin-bottom: 71px
  }

  .text-block__grid>* {
    flex-basis: 58.33%
  }

  .text-block__grid>:nth-child(2) {
    flex-basis: 41.66%
  }

  .text-block__text {
    margin-top: 26px
  }

  .text-block__text .list>li {
    margin-top: 17px
  }

  .text-block__text .list>li:first-child {
    margin-top: 0
  }

  .text-block__image {
    margin-left: -30px;
    margin-top: 6px
  }

  .text-block__grid_reverse .text-block__image {
    margin-left: 0;
    margin-right: -30px
  }

  .text-block__grid_reverse .text-block__content {
    padding-left: 51px
  }

  .text-block__grid_reverse .text-block__text {
    margin-top: 28px
  }

  .text-block_second {
    margin-top: 51px;
    margin-bottom: 64px
  }

  .text-block_second .text-block__image {
    margin-top: 63px
  }

  .text-block_center {
    margin-top: 61px
  }

  .text-block_center .text-block__content {
    padding-right: 0
  }

  .text-block_image-tall {
    margin-top: 56px
  }

  .text-block_image-tall .text-block__grid {
    align-items: center
  }

  .text-block_image-tall .text-block__content {
    margin-top: 0;
    padding-right: 50px
  }
}

@media screen and (max-width:1023px) {
  .text-block {
    margin-top: 76px;
    margin-bottom: 41px
  }

  .text-block .h2 {
    margin-bottom: 8px
  }

  .text-block__grid {
    align-items: center
  }

  .text-block__content {
    padding-right: 30px
  }

  .text-block__text,
  .text-block__text .list>li {
    margin-top: 14px
  }

  .text-block__text .list>li:before {
    top: 5px
  }

  .text-block__text .list>li:first-child {
    margin-top: 0
  }

  .text-block__image {
    margin-top: -5px
  }

  .text-block__grid_reverse .text-block__content {
    padding-left: 33px;
    padding-right: 0
  }

  .text-block__grid_reverse .text-block__text {
    margin-top: 20px
  }

  .text-block_second {
    margin-top: 20px;
    margin-bottom: 30px
  }

  .text-block_second .text-block__image {
    margin-top: 13px
  }

  .text-block_center {
    margin-top: 42px
  }

  .text-block_image-tall {
    margin-top: 35px
  }

  .text-block_image-tall .text-block__content {
    padding-right: 30px
  }

  .text-block_image-tall .text-block__image {
    margin-left: 0
  }
}

@media screen and (max-width:767px) {

  .text-block__grid>*,
  .text-block__grid>:nth-child(2) {
    flex-basis: 100%
  }

  .text-block__image {
    margin-left: 0;
    margin-top: 24px
  }

  .text-block__content {
    padding-right: 0
  }

  .text-block__text {
    margin-top: 18px
  }

  .text-block__text .list>li {
    margin-top: 8px
  }

  .text-block__text .list>li:first-child {
    margin-top: 0
  }

  .text-block__grid_reverse .text-block__content {
    padding-left: 0
  }

  .text-block__grid_reverse .text-block__text {
    margin-top: 17px
  }

  .text-block__grid_reverse .text-block__text .list li {
    padding-left: 23px
  }

  .text-block__grid_reverse .text-block__image {
    margin-right: 0
  }

  .text-block_second {
    margin-bottom: 41px
  }

  .text-block_center,
  .text-block_second .text-block__image {
    margin-top: 32px
  }

  .text-block_image-tall {
    margin-top: 28px
  }

  .text-block_image-tall .text-block__grid>*,
  .text-block_image-tall .text-block__grid>:nth-child(2) {
    flex-basis: 100%
  }

  .text-block_image-tall .text-block__content {
    padding-right: 0
  }

  .text-block_image-tall .text-block__text {
    margin-top: 16px
  }

  .text-block_image-tall .text-block__text .list>li {
    margin-top: 8px
  }
}

.fs__top_bg_mob {
  display: none
}

.fs__gift {
  display: flex;
  align-items: center;
  background-color: $color-primary;
  margin-top: 40px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  color: #fff;
  min-height: 88px;
  max-width: 454px;
  padding: 27px 30px 27px 138px;
  z-index: 1
}

.fs__gift:before {
  content: "";
  position: absolute;
  left: 5px;
  top: -7px;
  width: 68px;
  height: 64px;
  background-image: url(../static/implant_dentalway__ru/build/i/6fc69f57fcd1b39200e056a0cb5421e9.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  z-index: -1
}

.fs__gift>i {
  position: absolute;
  max-width: 108px;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.fs__gift>div {
  font-family: $font-geometria;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 20px
}

.fs__gift>div b {
  color: #fff
}

.fs__gift>div>span {
  display: block;
  font-style: italic;
  font-weight: 300;
  padding-top: 10px
}

.fs__grid__text {
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #333;
  margin-top: 18px
}

.fs__grid__note,
.fs__grid__text {
  font-family: $font-geometria;
  line-height: 20px
}

.fs__grid__note {
  font-weight: 300;
  font-style: italic;
  font-size: 14px;
  color: $color-primary;
  margin-top: 12px
}

.error-block {
  display: block;
  position: relative;
  background-image: url(../static/implant_dentalway__ru/build/i/0ecf778b54232e00c42f594584d0a962.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  min-height: 400px;
  padding-top: 92px;
  padding-bottom: 67px;
  color: #333
}

.error-block .block-label_right>span>span {
  margin-bottom: 37px
}

.error-block__content {
  margin-top: 8px
}

.error-block__content .btn {
  max-width: 359px;
  margin-top: 34px
}

.error-block__content .h1 {
  font-size: 38px;
  line-height: 48px
}

.error-block__text {
  font-size: 16px;
  line-height: 22px;
  margin-top: 23px;
  max-width: 430px
}

.error-block__text>span {
  font-family: $font-geometria;
  font-weight: 400;
  font-style: italic;
  display: block;
  margin-top: 22px
}

.error-block__text br {
  display: none
}

.error-block__grid {
  margin-left: -15px;
  margin-right: -15px
}

.error-block__grid>* {
  flex-basis: 50%;
  padding-left: 15px;
  padding-right: 15px
}

.error-block__info {
  font-weight: 700;
  color: $color-primary;
  font-size: 250px;
  line-height: 250px;
  display: inline-block;
  text-align: center;
  position: relative;
  padding-top: 68px;
  margin-left: 7px
}

.error-block__info,
.error-block__info:before {
  font-family: $font-geometria;
  font-style: normal
}

.error-block__info:before {
  font-weight: 500;
  content: "Ошибка";
  font-size: 18px;
  line-height: 23px;
  color: #333;
  position: absolute;
  left: 50%;
  top: 2px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

.error-block__info:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 43px;
  margin-left: -54px;
  width: 109px;
  height: 150px;
  background-image: url(../static/implant_dentalway__ru/build/i/26fbfb9f337add04f40b9d42d3aae574.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%
}

@media screen and (max-width:1312px) {
  .error-block {
    position: relative;
    z-index: 1;
    padding-top: 76px;
    padding-bottom: 26px
  }

  .error-block .block-label_large {
    display: none
  }

  .error-block:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 49px;
    height: 100%;
    background: linear-gradient(176deg, $color-primary, $color-primary 32.29%, #fff);
    z-index: -1
  }

  .error-block__grid>*,
  .error-block__grid>:nth-child(2) {
    flex-basis: 50%
  }

  .error-block__content {
    margin-top: 0
  }

  .error-block__content .h1 {
    font-size: 30px;
    line-height: 38px
  }

  .error-block__text {
    line-height: 22px;
    margin-top: 29px
  }

  .error-block__text br {
    display: none
  }

  .error-block__info {
    font-size: 200px;
    margin-left: 0
  }

  .error-block__info:before {
    margin-top: 0
  }
}

@media screen and (max-width:1023px) {
  .error-block {
    padding-top: 44px;
    min-height: auto
  }

  .error-block:after {
    width: 21px
  }

  .error-block__content {
    margin-top: 12px
  }

  .error-block__content .h1 {
    font-size: 28px;
    line-height: 35px
  }

  .error-block__content .btn {
    max-width: 263px
  }

  .error-block__text {
    font-size: 14px;
    line-height: 22px;
    max-width: 320px;
    margin-top: 16px
  }

  .error-block__info {
    font-size: 170px;
    line-height: 170px;
    padding-top: 110px;
    margin-left: -30px
  }

  .error-block__info:before {
    font-size: 14px;
    top: 10px
  }
}

@media screen and (max-width:767px) {
  .error-block {
    padding-top: 21px;
    padding-bottom: 45px
  }

  .error-block .h1 {
    font-size: 24px;
    line-height: 30px
  }

  .error-block:after {
    content: none
  }

  .error-block__grid {
    margin-left: 0;
    margin-right: 0
  }

  .error-block__grid>* {
    flex-basis: 100%;
    padding-left: 0;
    padding-right: 0
  }

  .error-block__grid>:nth-child(2) {
    flex-basis: 100%;
    order: -1;
    text-align: center
  }

  .error-block__info {
    font-size: 140px;
    line-height: 140px;
    margin-left: 0;
    text-align: center;
    padding-top: 83px
  }

  .error-block__info:after {
    top: 22px;
    max-width: 80px;
    margin-left: -40px
  }

  .error-block__content {
    margin-top: 1px
  }

  .error-block__content .btn {
    margin-top: 24px
  }

  .error-block__text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 19px
  }

  .error-block__text>span {
    margin-top: 20px
  }
}

.fs__ratings:before {
  display: none
}


.fs__price {
  display: flex;
  justify-content: space-around
}

.fs__price__price {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin-top: 6px
}

.fs__price__text {
  margin-bottom: 13px
}

.fs__best-price__item {
  display: flex;
  align-items: center
}

.fs__best-price__text {
  display: none
}

@media screen and (max-width:1919px) {
  .fs__top:before {
    // background-image: url(../static/implant_dentalway__ru/build/i/ceddadd7fc0ca5c3cbdc372867d7097e.png);
    background-image: url('../static/implant_dentalway__ru/build/i/c38ec46697885ee5a3acb978364b3eb8.png');
  }
}

.quote .get-price__employee__fio {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: normal
}

.quote__calculation__button>div {
  max-width: 350px
}

.quote-offer__desc p {
  font-size: inherit;
  line-height: inherit;
  color: inherit
}

.quote-offer__item-label {
  color: $color-primary
}

@media screen and (max-width:1312px) {
  .quote__calculation__button .btn {
    max-width: 370px
  }

  .quote__calculation__button>div {
    margin-top: 15px
  }
}

@media screen and (max-width:1023px) {
  .quote__calculation__button .btn {
    max-width: 320px
  }

  .quote__calculation__button>div {
    max-width: 320px;
    margin-top: 10px
  }
}

@media screen and (max-width:767px) {

  .quote__calculation__button .btn,
  .quote__calculation__button>div {
    max-width: 265px
  }

  .quiz__pages__gift>div {
    font-size: 11px
  }
}

.best-price .block-label>span span {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.best-price__installment {
  max-width: 520px
}

.best-price__video {
  padding: 10px 17px 10px 185px;
  min-height: 120px;
  max-width: 522px;
}

.best-price__video:before {
  display: none
}

@media screen and (max-width:767px) {
  .best-price__installment {
    max-width: 100%
  }

  .best-price__video {
    padding: 10px 17px 10px 90px;
    max-width: 100%
  }
}

.plastic__content .h2 {
  margin-bottom: 28px
}

.plastic__video {
  background-color: $color-primary-light
}

.plastic__video:before {
  display: none
}

.plastic__total__bg-value {
  color: darken($color-primary, 5%);
}

.consultation .implant__prices__list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;
  margin-top: 19px
}

.consultation .implant__price {
  display: flex;
  flex-direction: row;
  margin: 0 50px 20px 0;
  min-height: 95px;
  width: 230px;
  padding: 0;
  justify-content: space-around
}

.consultation .implant__price__inner {
  margin: 0
}

.consultation .implant__price__prices {
  margin-top: 10px
}

.consultation .implant__price__prices .new {
  font-size: 24px
}

.consultation .implant__price__title {
  font-size: 15px;
  line-height: 18px
}

.consultation .implant__price__title span {
  font-size: 11px;
  line-height: 13px;
  margin-top: 4px
}

.consultation__prices {
  min-width: auto;
  max-width: none;
  flex-wrap: wrap;
  flex-direction: row;
  margin-right: 100px;
  margin-bottom: -20px
}

.consultation__prices>* {
  padding-top: 0;
  width: 235px;
  flex-basis: auto;
  margin-bottom: 20px
}

.consultation__prices .fs__price {
  border-radius: 10px
}

.consultation__prices .fs__price__price {
  padding-right: 0;
  margin-top: 0
}

@media screen and (max-width:767px) {
  .consultation .implant__price {
    margin-right: auto;
    margin-left: auto;
    min-width: auto
  }
}

@media screen and (max-width:1312px) {
  .modal_dont-go .best__awards .tall .best__award i {
    width: 54px;
    max-width: 54px;
    top: 16px;
    left: 2px
  }
}

@media screen and (max-width:767px) {
  .modal_dont-go .employee__about {
    bottom: -27px
  }
}

.quiz__pages__gift {
  max-width: 620px
}

.quiz__employee__photo {
  background-color: $color-bg-gray
}

@media screen and (max-width:1312px) {
  .quiz__pages__gift {
    max-width: 409px
  }
}

@media screen and (max-width:1023px) {
  .quiz__pages__gift_adaptive {
    top: 458px
  }
}

@media screen and (max-width:767px) {
  .quiz__pages__gift {
    display: flex;
    align-items: center
  }

  .quiz__pages__gift>i {
    top: 30px
  }

  .quiz__pages__gift.quiz__pages__gift_adaptive {
    display: none
  }
}

.recovery__note {
  font-family: $font-geometria;
  font-weight: 700;
  font-style: italic
}

.ratings .slick-arrow {
  top: 105px
}

.ratings__platforms {
  justify-content: center
}

.rating__slide {
  background-color: $color-primary-light
}

.ratings__slider .slick-slide>div {
  height: auto
}

@media screen and (max-width:767px) {
  .rating__slide__bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start
  }

  .ratings__value {
    margin-right: auto
  }
}

.employees .slick-track {
  display: flex
}

.employees .slick-slide {
  height: auto
}

.employees .slick-slide>div {
  height: 100%
}

.employees .slick-slide>div .employee {
  display: flex !important;
  flex-direction: column;
  height: 100%
}

.employee__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1
}

.employee__certificates {
  flex-grow: 1;
  align-items: flex-end;
  justify-content: center
}

.text-block.text-block_big-img .text-block__image:after {
  padding-top: 90%
}

@media screen and (max-width:1023px) {
  .best__awards .tall .best__award-image {
    max-width: 70px
  }
}

@media screen and (max-width:767px) {
  .best__awards .tall .best__award-image {
    width: 54px;
    max-width: 54px;
    top: 16px;
    left: 2px
  }
}

@media screen and (max-width:1023px) {
  .question .block-header__text {
    line-height: 28px
  }
}

@media screen and (max-width:767px) {
  .question .block-header__text {
    line-height: 30px;
    padding: 2px 6px
  }
}