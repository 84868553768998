// Показания
.indication-list {
  ul {
    list-style: none;
    padding-left: 0;
    margin-left: 0;

    li {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      // align-items: center;
      font-size: inherit;
      display: flex;
      margin-left: 3px;
      margin-bottom: 10px;

      // Иконка
      &:before {
        content: '';
        display: inline-block;
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-color: $color-primary;
        position: relative;
        // background: url(../img/icons/check.svg),
        //   linear-gradient(180deg, #FFE89E 0%, #FECC3C 100%);
        background: url(../img/icons/check-white.svg),
          $gradient-secondary;
        background-size: 15px, contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 16px;
        top: -3px;

        @include respond-to(md) {
          width: 30px;
          height: 30px;
          top: 2px;
          margin-right: 15px;
          background-size: 13px,
            contain;
        }
      }

      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}

// Противопоказания
.contrindication-list {
  ul {
    list-style: none;
    padding-left: 0;
    margin-left: 0;

    li {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      // align-items: center;
      font-size: 18px;
      line-height: 140%;
      display: flex;
      margin-left: 3px;
      margin-bottom: 10px;

      @include respond-to(lg) {
        font-size: 1rem;
      }

      &:before {
        content: '';
        display: inline-block;
        flex-shrink: 0;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background-color: $color-primary;
        position: relative;
        top: 0;
        background-image: url('../img/icons/close-icon-white.svg');
        background-size: 8px;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 16px;
      }
    }

    p {
      margin: 0;
      padding: 0;
    }
  }
}