/*
 * Градиент для рамки
 */
@mixin gradient-border($color1, $color2, $border-width, $direction) {
    border: none;
    background-repeat: no-repeat;
    background-image: linear-gradient(#{$direction}, $color1 0%, $color2 100%),
    linear-gradient(#{$direction}, $color1 0%, $color2 100%);

    @if $direction=='to right'or $direction=='to left' {
        @if $direction=='to right' {
            border-left: $border-width solid $color1;
            border-right: $border-width solid $color2;
        }

        @else {
            border-left: $border-width solid $color2;
            border-right: $border-width solid $color1;
        }

        background-position: 0 0,
        0 100%;
        background-size: 100% $border-width;
    }

    @if $direction=='to top'or $direction=='to bottom' {
        @if $direction=='to top' {
            border-top: $border-width solid $color2;
            border-bottom: $border-width solid $color1;
        }

        @else {
            border-top: $border-width solid $color1;
            border-bottom: $border-width solid $color2;
        }

        background-position: 0 0,
        100% 0;
        background-size: $border-width 100%;
    }
}

/*
 * Перевод цвета в CSS фильтр (например для окраски SVG иконок)
 */
@mixin recolor($color: #000, $opacity: 1) {
    $r: calc(red($color) / 255);
    $g: calc(green($color) / 255);
    $b: calc(blue($color) / 255);
    $a: $opacity;

    // color filter
    $svg-filter-id: "recolor";
    filter: url('data:image/svg+xml;utf8,\
    <svg xmlns="http://www.w3.org/2000/svg">\
      <filter id="#{$svg-filter-id}" color-interpolation-filters="sRGB">\
        <feColorMatrix type="matrix" values="\
          0 0 0 0 #{$r}\
          0 0 0 0 #{$g}\
          0 0 0 0 #{$b}\
          0 0 0 #{$a} 0\
        "/>\
      </filter>\
    </svg>\
    ##{$svg-filter-id}');

    // grayscale fallback if SVG from data url is not supported
    $lightness: lightness($color);
    filter: saturate(0%) brightness(0%) invert($lightness) opacity($opacity);
}
