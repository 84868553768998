/*
* mixin: Respond-To
* Область видимости ДО определенного брейкпоинта
*/
@mixin respond-to($media) {
    @if $media==sm {
        @media only screen and (max-width: $breakpoint-xsm-max) {
            @content;
        }
    }

    @else if $media==md {
        @media only screen and (max-width: $breakpoint-sm-max) {
            @content;
        }
    }

    @else if $media==lg {
        @media only screen and (max-width: $breakpoint-md-max) {
            @content;
        }
    }

    @else if $media==xl {
        @media only screen and (max-width: $breakpoint-lg-max) {
            @content;
        }
    }

    @else if $media==2xl {
        @media only screen and (max-width: $breakpoint-xl-max) {
            @content;
        }
    }
}

/*
* mixin: Respond-From
* Область видимости ОТ определенного брейкпоинта
*/
@mixin respond-from($media) {
    @if $media==sm {
        @media only screen and (min-width: $breakpoint-sm) {
            @content;
        }
    }

    @else if $media==md {
        @media only screen and (min-width: $breakpoint-md) {
            @content;
        }
    }

    @else if $media==lg {
        @media only screen and (min-width: $breakpoint-lg) {
            @content;
        }
    }

    @else if $media==xl {
        @media only screen and (min-width: $breakpoint-xl) {
            @content;
        }
    }
}

/*
* mixin: Respond-In
* Область видимости В определенной области между брейкпоинтами
* TODO: Доделать
*/
@mixin respond-in($media) {
    @if $media==small {
        @media only screen and (max-width: 640px) {
            @content;
        }
    }

    @else if $media==medium {
        @media only screen and (max-width: 960px) {
            @content;
        }
    }

    @else if $media==large {
        @media only screen and (max-width: 1200px) {
            @content;
        }
    }

    @else if $media==xlarge {
        @media only screen and (max-width: 1600px) {
            @content;
        }
    }
}
