#before-after-swiper {
  .swiper-wrapper {
    @include respond-from(md) {
      padding: 0 200px;
    }
  }

  .swiper-slide {
    @include respond-from(md) {
      padding: 15px 20px 30px;
    }

    // // отступ до контейнера
    // &:first-child {
    //   margin-left: 200px;
    // }
  }
}

.before-after-card {
  &__item {
    padding: 40px;
    border-radius: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  &__photos {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &__2-photos {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;

    .image {
      position: relative;

      img {
        height: 100px;
        border-radius: 10px;
        object-fit: cover;
        object-position: center;
        aspect-ratio: 5/3;

      }

      .label {
        background-color: #33BBC5;
        position: absolute;
        bottom: -10px;
        left: -10px;
        border-radius: 7px;
        padding: 3px 10px;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: #fff;
      }
    }
  }

  &__main-photo {
    flex: 1;
    position: relative;

    img {
      border-radius: 15px;
      aspect-ratio: 3/4;
      object-fit: cover;
      object-position: center;
    }

    .label {
      background-color: #33BBC5;
      position: absolute;
      bottom: -10px;
      left: -10px;
      border-radius: 7px;
      padding: 3px 10px;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.2px;
      color: #fff;
      width: 70%;
    }
  }

  &__doctor {
    font-size: 14px;
    margin-top: 30px;

    span {
      font-weight: 600;
    }
  }

  &__quote {
    font-size: 16px;
    color: #fff;
    background: $color-primary;
    padding: 20px 25px;
    border-radius: 15px;
    margin-top: 20px;
  }
}