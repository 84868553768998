// Кнопки для SwiperJS
$swiper-button-size: 50px;

.swiper {

  // прячем swiper пока он не инициализируется
  &:not(.swiper-initialized) {
    display: none;
  }

  &-base-button {
    display: block;
    background: $color-primary;
    border-radius: 100px;
    width: $swiper-button-size !important;
    height: $swiper-button-size !important;
    position: absolute;
    z-index: 1;

    background-image: url('../img/icons/swiper-arrow.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
  }

  &-button {
    &-prev {
      @extend .swiper-base-button;
      transform: rotate(-180deg);

      @include respond-from(lg) {
        left: 30px;
      }

      &:after {
        content: none !important;
      }

      &.swiper-button-outside {
        left: calc(-1*(#{$swiper-button-size} + 30px));
      }
    }

    &-next {
      @extend .swiper-base-button;

      @include respond-from(lg) {
        right: 30px;
      }

      &:after {
        content: none !important;
      }

      &.swiper-button-outside {
        right: calc(-1*(#{$swiper-button-size} + 30px));
      }
    }

    &-outside {
      @include respond-to(lg) {
        display: none;
      }
    }

    &-disabled {
      // opacity: 0 !important;
    }
  }

  &-pagination {
    position: relative;
    height: 0;
    bottom: 35px !important;

    &-bullet {
      margin: 0 3px;
      width: 10px;
      height: 10px;
      background-color: #666;

      &-active {
        background-color: $color-primary;
      }
    }

    &.swiper-pagination-outside {
      bottom: -35px !important;

      @include respond-to(md) {
        bottom: -20px !important;
      }
    }
  }
}

.header-swiper-buttons {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;

  .swiper-button-next,
  .swiper-button-prev {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    margin: 0;
  }
}

// Мобильный Swiper
$swiper-mobile-horisontal-gutter: 7px;

.swiper-mobile {
  .swiper-slide {
    @include respond-to(lg) {
      width: 150px;
      margin: 0 $swiper-mobile-horisontal-gutter; // горизонтальный отступ

      &:first-child {
        margin-left: 1rem;
      }

      &:last-child {
        margin-right: 1rem;
      }
    }
  }

  &--180 {
    .swiper-slide {
      @include respond-to(lg) {
        width: 180px;
        margin: 0 8px;
      }
    }
  }

  &--260 {
    .swiper-slide {
      @include respond-to(lg) {
        width: 260px;
        margin: 0 8px;
      }
    }
  }

  &--40p {
    .swiper-slide {
      @include respond-to(lg) {
        width: 40%;
        margin: 0 10px;
      }
    }
  }

  &--50p {
    .swiper-slide {
      @include respond-to(lg) {
        width: 50%;
        margin: 0 10px;
      }
    }
  }

  &--60p {
    .swiper-slide {
      @include respond-to(lg) {
        width: 60%;
        margin: 0 10px;
      }
    }
  }

  &--80p {
    .swiper-slide {
      @include respond-to(lg) {
        width: 80%;
        margin: 0 10px;
      }
    }
  }
}