.footer {
  color: rgba(255, 255, 255, 0.7);
  background-color: #1D2F3C;

  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 30px 0;

    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    @include respond-to(md) {
      flex-direction: column;
    }

  }

  &__top-right {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 50px;

    @include respond-to(md) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__grid {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    padding: 40px 0;
  }

  &__logo {
    img {
      // @include recolor(#fff);
      height: 80px;
      object-fit: contain;
      object-position: center;

      @include respond-to(lg) {
        height: 80px;
      }
    }
  }

  &__address {
    display: flex;
    flex-direction: row;
    gap: 15px;
    font-size: 14px;
    line-height: 140%;

    font-weight: 400;
    font-size: 12px;
    line-height: 150%;

    &:before {
      content: '';
      width: 22px;
      height: 22px;
      background-image: url(/img/icons/time.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__work-time {
    display: flex;
    flex-direction: row;
    gap: 15px;
    font-size: 14px;
    line-height: 140%;

    font-weight: 400;
    font-size: 12px;
    line-height: 150%;

    &:before {
      content: '';
      width: 22px;
      height: 22px;
      background-image: url(/img/icons/address.svg);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &__phone-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    &>div {
      display: flex;
      flex-direction: column;
    }

    &:before {
      content: '';
      display: flex;
      align-items: center;
      justify-content: center;

      width: 36px;
      height: 36px;
      border-radius: 100px;
      background: url(../img/icons/phone.svg), linear-gradient(180deg, #FFE89E 0%, #FECC3C 100%);
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50px;

    }
  }

  &__phone {
    @extend .link;
    font-weight: 600;
    font-size: 18px;
    line-height: 152%;
    color: #fff;
    white-space: nowrap;
  }

  &__callback {
    @extend .link;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: fade-out($color: #fff, $amount: .3);
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #6b6b6b;

    @include respond-to(md) {
      font-size: 12px;
    }

    @include respond-from(md) {
      // max-width: calc(100% - 447px);
    }
  }

  &__disclamer {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #5B5F6E;
    padding: 25px 5px;

    &--mobile {
      font-size: 14px;
      text-align: center;
    }

    @include respond-to(md) {
      text-align: center;
    }
  }
}

.footer-horisontal-nav {
  &__nav {
    padding: 20px 0;
  }

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 13px;

  }

  &__link {
    @extend .link;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.004em;
    color: rgba(255, 255, 255, 0.7);
  }
}