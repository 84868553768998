// Прайс на всю ширину страницы,
// может конфликтовать с классом .content-area. Использовать без него
.price-table-full {
  table {
    width: 100%;
    border-collapse: collapse;
    font-family: inherit;
    font-size: 1rem;
    // min-width: 400px;
    max-width: 100%;
    overflow: hidden;
    border: unset !important;

    @include respond-to(lg) {
      font-size: 14px;
    }

    thead tr {
      background-color: $color-primary;
      color: #ffffff;
      text-align: left;
    }

    & tbody {
      tr {
        &:nth-of-type(even) {
          // background-color: #fff;
        }

        &.active-row {
          font-weight: bold;
          color: $color-primary;
        }
      }
    }

    tr {
      // background-color: #fff;
      border-bottom: 1px solid #e5e5e5;

      // на мобильном делаем переносы
      @include respond-to(md) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 5px;
        column-gap: 15px;

        // другой стиль строки для мобильного
        background: #f5f5f5;
        border-radius: 9px;
        padding: 15px 15px;
        border: unset !important;
        margin-bottom: 7px;
      }

      &:nth-of-type(even) {}

      // заголовки
      &:first-child {
        @include respond-to(md) {
          display: none;
        }
      }

      &:last-child {
        border-bottom: unset;
      }
    }

    // заголовки
    th {
      padding: 16px 0;
      text-align: left;
      white-space: nowrap;

      // для названия
      &:nth-child(1) {
        padding-left: 0;
      }

      // // для цены
      // &:nth-child(2),
      // &:nth-child(3) {
      //   text-align: right;
      // }

      @include respond-from(md) {
        padding: 7px 30px;
      }

      @include respond-from(lg) {
        padding: 15px 50px;
      }

      @include respond-to(md) {
        padding: 0;
      }

      p {
        font-weight: 600;
      }
    }

    td {
      // padding: 16px 10px;
      text-align: left;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      @include respond-from(md) {
        padding: 7px 30px;
      }

      @include respond-from(lg) {
        padding: 15px 50px;
      }

      @include respond-to(md) {
        padding: 0;
      }

      // для названия
      &:nth-child(1) {
        line-height: 140%;
        font-weight: 500;

        @include respond-to(md) {
          font-size: 14px;
          font-weight: 600;
          flex: 0 0 100%;
        }
      }

      // для цены
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        // text-align: right;
        text-align: left;
        font-weight: 500;

        // автоширина колонок
        @include respond-from(md) {
          width: 1px;
          white-space: nowrap;
        }

        @include respond-to(md) {
          font-size: 16px;
          margin-top: 5px;
        }
      }

      p {
        padding: 0;
      }

      // // для зачеркнутой цены
      // &:nth-child(3) {
      //   display: flex;
      //   flex-direction: row;
      //   align-items: center;
      //   justify-content: flex-start;
      //   text-align: right;

      //   @include respond-to(md) {
      //     font-size: 0.8rem;
      //   }
      // }

      del {
        text-decoration: none;
        white-space: nowrap;
        // &:before {
        //   display: block;
        //   content: 'Старая цена';
        //   font-size: 0.9rem;
        //   font-weight: 400;
        //   line-height: normal;
        // }

        // Или зачеркнутая цена
        // span {
        position: relative;

        &:before {
          content: " ";
          display: block;
          width: 100%;
          border-top: 2px solid #f00c;
          height: 12px;
          position: absolute;
          bottom: 1px;
          left: 0;
          -webkit-transform: rotate(-7deg);
          -ms-transform: rotate(-7deg);
          transform: rotate(-7deg);

          @include respond-to(md) {
            bottom: -2px;
          }
        }

        // }
      }
    }
  }
}

.price-table-full {
  table {
    width: 100%;
    border-collapse: collapse;
    font-family: inherit;
    font-size: 1rem;
    // min-width: 400px;
    max-width: 100%;
    overflow: hidden;
    border: unset !important;

    @include respond-to(lg) {
      font-size: 14px;
    }

    // заголовки
    th {
      padding: 16px 0;
      text-align: left;
      white-space: nowrap;

      // для названия
      &:nth-child(1) {
        padding-left: 0;
      }

      // для цены
      &:nth-child(2),
      &:nth-child(3) {
        text-align: right;
        padding-right: 0;
      }

      @include respond-from(md) {
        padding: 7px 30px;
      }

      @include respond-from(lg) {
        padding: 15px 50px;
      }

      @include respond-to(md) {
        padding: 0;
      }

      p {
        font-weight: 600;
      }
    }

    td {
      color: #000;
    }

    // tbody {
    //   tr {
    //     &:nth-of-type(even) {
    //       background-color: #fff;
    //     }

    //     &.active-row {
    //       font-weight: bold;
    //       color: $color-primary;
    //     }
    //   }
    // }

    tr {
      // background-color: #fff;
      border-bottom: 1px solid #e5e5e5;

      // на мобильном делаем переносы
      @include respond-to(md) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 2px;
        column-gap: 15px;

        // другой стиль строки для мобильного
        background: #f5f5f5;
        border-radius: 15px;
        padding: 15px 15px;
        border: unset !important;
        margin-bottom: 5px;
      }

      &:nth-of-type(even) {}

      // заголовки
      &:first-child {
        @include respond-to(md) {
          display: none;
        }
      }
    }

    td {
      // padding: 16px 10px;
      // text-align: left;

      // для названия
      &:nth-child(1) {
        text-align: left;
        padding-left: 0;

        @include respond-to(md) {
          font-size: 14px;
          font-weight: 400;
          line-height: 150%;
          flex: 0 0 100%;
        }
      }

      // для цены
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        text-align: right;
        padding-right: 0;
        font-weight: 600;
        letter-spacing: .5px;

        // автоширина колонок
        @include respond-from(md) {
          width: 1px;
          white-space: nowrap;
        }

        @include respond-to(md) {
          font-size: 16px;
        }
      }

      @include respond-from(md) {
        padding: 7px 30px;
      }

      @include respond-from(lg) {
        padding: 15px 50px;
      }

      @include respond-to(md) {
        padding: 0;
      }

      p {
        padding: 0;
      }

      // // для зачеркнутой цены
      // &:nth-child(3) {
      //   display: flex;
      //   flex-direction: row;
      //   align-items: center;
      //   justify-content: flex-start;
      //   text-align: right;

      //   @include respond-to(md) {
      //     font-size: 0.8rem;
      //   }
      // }

      del {
        text-decoration: none;
        white-space: nowrap;
        // &:before {
        //   display: block;
        //   content: 'Старая цена';
        //   font-size: 0.9rem;
        //   font-weight: 400;
        //   line-height: normal;
        // }

        // Или зачеркнутая цена
        // span {
        position: relative;

        &:before {
          content: " ";
          display: block;
          width: 100%;
          border-top: 2px solid #f00c;
          height: 12px;
          position: absolute;
          bottom: 1px;
          left: 0;
          -webkit-transform: rotate(-7deg);
          -ms-transform: rotate(-7deg);
          transform: rotate(-7deg);

          @include respond-to(md) {
            bottom: -2px;
          }
        }

        // }
      }
    }
  }
}