// Карточка преимущества
.advantage-checked-card {
	&__item {
		height: 100%;
		// padding: 30px 40px;
		// border-radius: 20px;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		background-color: #fff;
		border-radius: 20px;
		padding: 30px 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		position: relative;

		// background: #FAF0E1;
		box-shadow: 0px 20px 60px -10px rgba(15, 29, 67, 0.25);

		@include respond-to(md) {
			flex-direction: row;
			align-items: flex-start;
			padding: 20px 25px;

			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; // легкая тень
		}

		// Иконка
		&:before {
			content: '';
			display: inline-block;
			flex-shrink: 0;
			width: 50px;
			height: 50px;
			border-radius: 100%;
			background-color: $color-primary;
			position: absolute;
			top: -20px;
			background: url(../img/icons/check.svg),
				$gradient-yellow;
			background-size: 20px, contain;
			background-repeat: no-repeat;
			background-position: center;
			margin-right: 16px;

			@include respond-to(md) {
				position: relative;
				width: 40px;
				height: 40px;
				top: -1px;
				background-size: 13px,
					contain;
			}
		}

	}

	&__img {
		img {
			aspect-ratio: 1/1;
			object-fit: cover;
			object-position: center;
			border-radius: 300px;
			width: 200px;
		}
	}

	&__title {
		font-size: 16px;
		font-weight: 500;
		line-height: 140%;
		text-align: center;
		margin-top: 20px;
		// padding: 0 40px;

		@include respond-to(md) {
			text-align: left;
			font-size: 15px;
			line-height: 150%;
			margin-top: 0;
		}
	}

	&__description {
		font-size: 16px;
		font-weight: 500;
		line-height: 140%;
		text-align: center;
		padding: 0 40px;
	}

}

// Сетка
.advantage-grid {
	display: flex;
	flex-wrap: wrap;
	column-gap: 30px;
	row-gap: 50px;
	justify-content: center;

	@include respond-to(md) {
		row-gap: 20px;
	}

	&__item {
		flex: 0 0 calc((100% / 4) - 30px);

		@include respond-to(xl) {
			flex: 1 1 calc((100% / 5) - 30px);
		}

		@include respond-to(lg) {
			flex: 1 1 calc((100% / 3) - 30px);
		}

		@include respond-to(md) {
			flex: 1 1 calc((100% / 2) - 30px);
		}
	}
}