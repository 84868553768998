.awards {
	&__section-bg {
		@extend .base-section-bg;
	}
}

// Карточка награды
.award-card {
	&__item {
		height: 100%;

		padding: 20px 25px 85px;
		border-radius: 20px;
		background-color: #fff;
		// box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		box-shadow: 0 30px 100px -10px rgba(15, 29, 67, .25);

		position: relative;

		@include respond-to(md) {
			box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; // легкая тень
		}
	}

	&__icon {
		img {
			width: 50%;
			max-width: 100px;

			position: absolute;
			bottom: -20px;
			right: -15px;
		}
	}

	&__title {
		color: darken($color: $color-primary, $amount: 10%);
		font-size: 16px;
		font-weight: 700;
		line-height: 130%;
	}

	&__description {
		font-size: 12px;
		font-weight: 400;
		line-height: 160%;
		margin-top: 10px;
	}
}

// Сетка
.award-grid {
	display: flex;
	flex-wrap: wrap;
	column-gap: 30px;
	row-gap: 50px;
	justify-content: center;

	@include respond-to(md) {
		row-gap: 20px;
	}

	&__item {
		flex: 0 0 calc((100% / 5) - 30px);

		@include respond-to(xl) {
			flex: 1 1 calc((100% / 5) - 30px);
		}

		@include respond-to(lg) {
			flex: 1 1 calc((100% / 3) - 30px);
		}

		@include respond-to(md) {
			flex: 1 1 calc((100% / 2) - 30px);
		}
	}
}