#prices-swiper-pagination {
  position: relative;
  white-space: nowrap;
  bottom: 0;

  .swiper-pagination-bullet {
    background: #fff;
    opacity: .5;

    &-active {
      background: $color-primary;
      opacity: 1;
    }
  }
}

.price-card {
  &__include-content {
    ul {
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 35px;
      column-gap: 35px;
      grid-row-gap: 13px;
      row-gap: 13px;

      li {
        position: relative;
        display: flex;
        font-size: 14px;
        padding-left: 28px;

        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 18px;
          border-radius: 100%;
          left: 0;
          top: 0;
          background: url(../img/icons/check-white.svg),
            $gradient-primary;
          background-size: 10px,
            contain;
          background-repeat: no-repeat;
          background-position: center;
          margin-right: 16px;
        }
      }

    }
  }

}

.page_success .prices__bg {
  position: relative;
  padding-top: 0;
  background: transparent
}

.page_success .prices__bg .rs-awards {
  display: flex
}

.prices {
  position: relative;
  // background: $bg-light-gradient;
  background: #fafafa;

  @include respond-from(lg) {
    padding: 80px 40px;
  }

  // &__bg {
  //   padding: 80px 40px;
  //   background: $color-primary;
  //   border-radius: 30px;
  // }

  &__title {}

  &__subtitle {
    font-weight: 500;
    font-style: normal;
    text-align: center;
    padding-bottom: 50px;
    font-size: 25px;

    &.white {
      color: #fff;
    }
  }
}

.prices .h2 {
  max-width: 815px;
  margin: 0 auto;
  padding-bottom: 20px;
  font-size: 45px
}

.prices .rs-awards {
  display: none
}


.prices__slider-nav {
  margin-top: 40px
}

.prices__slider-nav .slick-arrow {
  background-color: $color-primary
}

.prices__slider-nav .slick-dots li button:before {
  background-color: #fff
}

.prices__slider-nav .slick-dots li.slick-active button:before {
  background-color: $color-primary
}

.prices__wrap {
  max-width: 1120px;
  margin-right: auto;
  margin-left: auto
}

.prices__wrap .slick-slide {
  margin-left: 22px;
  margin-right: 22px
}

.prices-implant {
  align-items: start;
  padding: 40px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 20px 60px -10px rgba(15, 29, 67, .25);
}

.prices-implant,
.prices-implant__wrap {
  display: grid;
  grid-gap: 30px;
  gap: 30px
}

.prices-implant__top {
  display: flex;
  align-items: flex-start;
  grid-gap: 23px;
  gap: 23px
}

.prices-implant__title {
  font-weight: 500;
  font-style: normal;
  text-align: center;
  font-size: 22px
}

.prices-implant-images {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 60px auto;
  background: linear-gradient(107.11deg, #b8cdee .78%, #dbcced 100.41%);
  border-radius: 15px;
  padding-right: 10px;
  padding-top: 10px;
  min-width: 176px;
  min-height: 125px;
  overflow: hidden
}

.prices-implant-images .implant-img__main {
  position: absolute;
  top: 15px
}

.prices-implant-images .implant-img__flag {
  top: 10px;
  right: 10px;
  position: absolute;
  border-radius: 8px
}

.prices-implant-images .implant-img__name {
  display: flex;
  align-items: center;
  grid-row: 2/3;
  grid-column: 2/3
}

.prices-implant-images .implant-img__image_mini {
  position: relative;
  left: 15px
}

.prices-implant-images .implant-img__logo {
  background-color: #fff;
  border-radius: 6px
}

.price-implant__text {
  display: grid;
  position: relative
}

.prices-implant__brand {
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  padding-bottom: 5px
}

.prices-implant__country {
  padding-bottom: 15px;
  font-size: 14px;
  color: #716b81
}

.prices-implant__discount {
  position: relative;
  font-weight: 700;
  font-style: normal;
  padding: 5px 20px 5px 35px;
  margin-bottom: 12px;
  color: #fff;
  background: linear-gradient(107.11deg, #f4b172 .78%, #f1e257 100.41%);
  border-radius: 15px;
  font-size: 14px
}

.prices-implant__discount:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 3px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-image: url(../img/i/7216276198d147bee99174443a7b7d71.svg);
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 3px solid hsla(0, 0%, 100%, .3)
}

.prices-implant__discount-mobile {
  display: none
}

.prices-implant__price {
  display: flex;
  align-items: center;
  grid-gap: 9px;
  gap: 9px
}

.prices-implant__price .old {
  font-weight: 500;
  font-style: normal;
  color: #838885;
  font-size: 18px;
  text-decoration: line-through
}

.prices-implant__price .new {
  font-weight: 700;
  font-style: normal;
  color: $color-secondary;
  font-size: 28px
}

.price__include {
  display: grid;
  align-items: start
}

.price__include .title {
  color: #716b81
}

.price__include .price,
.price__include .title {
  font-weight: 700;
  font-style: normal;
  font-size: 18px
}

.price__include .price {
  color: $color-secondary
}

.prices__include-title {
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  text-align: center;
  padding-bottom: 15px;
  margin-top: 25px
}

.prices__include-title .description {
  display: none
}

.price__include-option {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  padding-top: 13px
}

.price__include-option span:nth-child(2) {
  font-weight: 500;
  font-style: normal;
  max-width: 85px;
  font-size: 15px
}

.price__include-option span:nth-child(3) {
  max-width: 130px;
  color: #716b81;
  font-size: 14px
}

.price__include-option.hidden {
  display: none
}

.price__include-item {
  position: relative;
  display: grid;
  grid-gap: 15px;
  gap: 15px;
  justify-items: center;
  padding: 25px 28px;
  border-radius: 15px;
  background-color: #eff0f7
}

.price__include-item .btn__more {
  display: none
}

.price__include-item:not(:first-of-type) {
  margin-top: 43px
}

.price__include-item:not(:first-of-type):before {
  content: "";
  position: absolute;
  width: 17px;
  height: 18px;
  left: 50%;
  top: -29px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-image: url(../img/i/74cd866fbd03e38ee3b01c5e85c1a371.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain
}

.price__include-content {
  display: flex;
  flex-direction: column;
  align-items: center
}

.prices__button {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-left: 250px
}

.prices__button .btn {
  max-width: 325px
}

.price__note {
  max-width: 250px;
  padding-left: 13px;
  color: #838885;
  line-height: 22px
}

.price__content-tooltip {
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: $color-primary;
  position: absolute;
  right: -20px;
  top: 50%
}

.price__tooltip-button {
  display: flex;
  align-items: center;
  max-width: 111px
}

.price__tooltip-button:after {
  content: "";
  width: 15px;
  height: 15px;
  background-image: url(../img/i/1f9dedbee24c74fe20c84f5b634a7480.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  display: inline-block
}

@media screen and (max-width:1600px) {

  .page_success .prices:before,
  .page_success .prices__bg-overlay {
    width: calc(100% - 45px)
  }
}

@media screen and (max-width:1420px) {

  .page_success .prices:before,
  .page_success .prices__bg-overlay {
    width: calc(100% - 25px)
  }

  .page_success .prices__bg-overlay .ellipse-2 {
    top: -170px;
    left: -235px
  }
}

@media screen and (max-width:1380px) {
  .page_success {
    padding-bottom: 50px
  }

  .page_success .prices:before,
  .page_success .prices__bg-overlay {
    border-radius: 100px 0 0 20px
  }

  .page_success .rs-awards {
    top: -30px;
    padding-bottom: 90px
  }

  .prices {
    margin-top: 110px
  }

  .prices .h2 {
    font-size: 40px;
    padding-bottom: 15px
  }

  .prices__bg {
    padding-top: 110px;
    padding-bottom: 110px;
    border-radius: 100px 50px
  }

  .prices__wrap .slick-slide {
    margin-left: 16px;
    margin-right: 16px
  }

  .prices__wrap .slick-list {
    margin-right: -16px;
    margin-left: -16px
  }

  .prices-implant__top {
    grid-gap: 20px;
    gap: 20px
  }

  .prices__subtitle {
    font-size: 23px;
    padding-bottom: 40px;
    max-width: 684px;
    margin: 0 auto
  }

  .prices-implant {
    padding: 35px 20px 20px
  }

  .prices-implant__title {
    font-size: 20px
  }

  .prices-implant__discount {
    padding: 5px 10px 5px 35px;
    font-size: 14px
  }

  .prices-implant__price .old {
    font-size: 17px
  }

  .prices-implant__price .new {
    font-size: 25px
  }

  .price__include-item {
    padding: 20px
  }

  .prices__button {
    padding-top: 40px
  }
}

@media screen and (max-width:1023px) {
  .page_success {
    padding-top: 90px;
    padding-bottom: 60px
  }

  .page_success .prices:before,
  .page_success .prices__bg-overlay {
    width: 100%;
    border-radius: 80px 0 0 20px
  }

  .page_success .rs-awards {
    top: -75px;
    padding-bottom: 40px
  }

  .prices {
    margin-top: 90px
  }

  .prices .h2 {
    font-size: 34px;
    padding-bottom: 10px
  }

  .prices .accordion__tab__title.active .btn__more:before {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
    transform: translate(-50%, -50%) rotate(180deg)
  }

  .prices .accordion__tab__content {
    display: none
  }

  .prices .accordion__tab__content.active {
    display: block
  }

  .prices__bg {
    padding-top: 90px;
    padding-bottom: 90px;
    border-radius: 40px 0 80px
  }

  .prices__subtitle {
    font-size: 22px
  }

  .prices-implant__top {
    grid-gap: 15px;
    gap: 15px
  }

  .prices-implant__wrap {
    grid-gap: 20px;
    gap: 20px
  }

  .prices-implant__title {
    font-size: 18px
  }

  .prices-implant-images {
    grid-template-columns: 1fr;
    min-width: 124px;
    min-height: 116px
  }

  .prices-implant-images .implant-img__main {
    top: 7px;
    left: -13px
  }

  .prices-implant-images .implant-img__flag {
    width: 23px;
    height: 23px
  }

  .prices-implant-images .implant-img__name {
    grid-row: auto;
    grid-column: auto;
    justify-content: flex-end;
    padding-top: 50px;
    z-index: 1
  }

  .prices-implant-images .implant-img__image_mini {
    display: none
  }

  .prices-implant__brand {
    font-size: 16px;
    padding-bottom: 0
  }

  .prices-implant__country {
    padding-bottom: 8px
  }

  .prices-implant__discount {
    font-size: 13px
  }

  .prices-implant__price .old {
    font-size: 14px
  }

  .prices-implant__price .new {
    font-size: 22px
  }

  .price__include .price,
  .price__include .title {
    font-size: 14px
  }

  .price__include .list {
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px
  }

  .prices__include-title {
    font-size: 16px
  }

  .prices__include-title .description {
    font-weight: 400;
    font-style: normal;
    display: block;
    margin: 0 auto;
    max-width: 210px;
    font-size: 14px;
    color: #716b81
  }

  .price__include-item {
    justify-items: start;
    grid-gap: 15px;
    gap: 15px;
    padding: 15px 15px 20px
  }

  .price__include-item span {
    max-width: 90%;
    width: 100%;
    cursor: pointer
  }

  .price__include-item .btn__more {
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    // background-color: $button-primary-bg;
    border-radius: 5px;
    right: 17px;
    top: 17px;
    border: none;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg)
  }

  .price__include-item .btn__more:before {
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-image: url(../img/i/ca93124ffaff3cd5de6209631ca2b96b.svg);
    background-repeat: no-repeat;
    background-position: 50%
  }

  .price__include-item:not(:first-of-type) {
    margin-top: 13px
  }

  .price__include-item:not(:first-of-type):before {
    display: none
  }

  .price__include-option span:nth-child(2) {
    font-size: 14px
  }

  .prices__button {
    flex-direction: column;
    padding-top: 35px;
    padding-left: 0
  }

  .prices__button .btn {
    max-width: 325px
  }

  .prices__button .btn span {
    max-width: 265px
  }

  .price__note {
    max-width: 385px;
    text-align: center;
    padding-left: 0
  }

  .price__content-tooltip {
    right: -20px;
    top: 45%
  }
}

@media screen and (max-width:767px) {
  .page_success {
    padding-top: 60px;
    padding-bottom: 50px
  }

  .page_success .prices:before,
  .page_success .prices__bg-overlay {
    border-radius: 40px 0 0 20px
  }

  .page_success .rs-awards {
    top: -40px;
    padding-bottom: 20px
  }

  .prices {
    // margin-top: 60px
  }

  .prices .h2 {
    font-size: 30px;
    padding-bottom: 15px
  }

  .prices__bg {
    padding-top: 60px;
    padding-bottom: 60px;
    border-radius: 40px 20px 20px 20px
  }

  .prices__subtitle {
    font-size: 20px
  }

  .prices-implant__top {
    position: relative;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px
  }

  .prices-implant {
    padding: 30px 20px;
    grid-gap: 25px;
    gap: 25px;
    width: 100%;
    max-width: 560px
  }

  .prices-implant__wrap {
    grid-gap: 10px;
    gap: 10px
  }

  .prices-implant-images {
    min-width: 105px;
    min-height: 98px;
    padding-right: 4px;
    padding-top: 4px
  }

  .prices-implant-images .implant-img__flag {
    right: 4px;
    top: 4px
  }

  .prices-implant-images .implant-img__main {
    height: 110px;
    width: 68px;
    left: -5px
  }

  .prices-implant__title {
    font-size: 15px
  }

  .price-implant__text {
    margin-right: auto;
    position: static
  }

  .prices-implant__discount {
    display: none
  }

  .prices-implant__discount-mobile {
    display: inline-block;
    max-width: 210px;
    margin: 0 auto 6px
  }

  .prices-implant__brand {
    font-size: 14px;
    padding-bottom: 6px
  }

  .prices-implant__country {
    padding-bottom: 15px
  }

  .prices-implant__price {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 0;
    gap: 0
  }

  .price__include .list {
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
    row-gap: 12px
  }

  .price__include-item {
    margin-top: 10px;
    padding: 14px 15px 9px
  }

  .prices__include-title {
    font-size: 15px
  }

  .price__include-option {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 0;
    row-gap: 0;
    max-width: none;
    justify-content: center
  }

  .price__include-option img {
    grid-row: 1/3;
    width: 39px;
    height: 40px
  }

  .price__include-option span {
    line-height: 17px
  }

  .price__include-option span:nth-child(2) {
    grid-column: 2/2;
    max-width: 100%;
    margin-top: 7px
  }

  .price__include-option span:nth-child(3) {
    grid-column: 2/2;
    grid-row: 2/2;
    max-width: 100%
  }

  .price__include-option.hidden {
    display: grid
  }

  .price__include-option.showed {
    display: none
  }

  .prices__button {
    grid-gap: 15px;
    gap: 15px
  }

  .prices__button .btn {
    max-width: 260px
  }

  .prices__button .price__note {
    font-size: 15px
  }

  .price__content-tooltip {
    top: -37px;
    right: 120px
  }
}

@media screen and (max-width:520px) {
  .price__content-tooltip {
    top: -37px;
    right: 90px
  }
}

@media screen and (max-width:460px) {
  .price__content-tooltip {
    top: -37px;
    right: 60px
  }
}

@media screen and (max-width:395px) {
  .price__content-tooltip {
    top: -37px;
    right: 20px
  }
}

.price__include .list li:before {
  background-image: url(../img/i/b9b951e89e314d5bdc347330a2b650fb.svg)
}