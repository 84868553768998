.link {
  cursor: pointer;
  transition: .2s ease-in-out;

  &:hover {
    color: $color-primary;

    &>span {
      border-bottom: 1px solid transparent
    }
  }

  span {
    border-bottom: 1px solid;
    transition: border .2s ease
  }
}

.link_notline {
  color: #333
}

.link_notline>span {
  border-bottom: 1px solid transparent;
  transition: border .2s ease
}

.link_notline:hover>span,
.link_underline>span {
  border-bottom: 1px solid
}

.link_underline>span {
  transition: border .2s ease
}

.link_underline:hover>span {
  border-bottom: 1px solid transparent
}

.link_white {
  color: #fff
}

.link_dashed>span {
  border-bottom: 1px dashed
}

.link_dashed:hover>span {
  border-bottom: 1px dashed transparent
}