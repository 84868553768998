// Контент на странице
$content-text-font-size: 16px;
$content-text-base: #0d0d0d;
$content-text-bold: 600;
$content-text-line-height: 170%;
$content-header-weight: 600;
// $content-font: Roboto,
// ui-sans-serif,
// system-ui,
// -apple-system,
// BlinkMacSystemFont,
// Segoe UI,
// Roboto,
// Helvetica Neue,
// Arial,
// Noto Sans,
// sans-serif,
// "Apple Color Emoji",
// "Segoe UI Emoji",
// Segoe UI Symbol,
// "Noto Color Emoji";

// $content-font: 'Golos Text',
// sans-serif;

$content-font: $font-decorative;

.base-header {
    font-family: $font-decorative;

}

article,
.page-content,
.content-area {
    font-family: $content-font !important;
    color: $content-text-base;
    font-size: $content-text-font-size;
    // line-height: 170%;
    // line-height: 1.5rem;
    line-height: $content-text-line-height;
    font-weight: 400;
    flex: 1 1 auto;
    padding: 0;
    margin: 0;

    @include respond-to(md) {
        // font-size: 0.9rem;
        font-size: 1rem;
        line-height: 1.625;
    }

    @include respond-to(sm) {
        font-size: 0.9rem;
        line-height: 1.625;
    }

    // * {
    //     color: $content-text-base;
    // }

    iframe {
        max-width: 100%;
    }

    .lead {
        font-family: $font-decorative;
        font-size: 18px;
        line-height: 170%;
        font-weight: 500;

        @include respond-to(md) {
            font-size: 0.9rem;
            font-weight: 500;
            line-height: 170%;
        }
    }

    .lead-xl {
        font-size: 22px;
        line-height: 150%;
        font-weight: 400;
    }

    // Тильдавский редактор
    .t-redactor__video-container,
    // Видео в контенте
    .video-container {
        position: relative;
        padding-bottom: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    a:not(.button) {
        cursor: pointer;
        color: $color-primary;

        &:hover {
            color: darken($color: $color-primary, $amount: 10%);
        }
    }

    blockquote {
        color: #fff;
        background-color: $color-primary;
        padding: 1rem 1.5rem;
        border-radius: 5px;
    }

    h1 {
        display: block;
        font-size: 2em;
        margin-top: 0.67em;
        margin-bottom: 0.67em;
        margin-left: 0;
        margin-right: 0;
        font-weight: 500;
        font-family: inherit;
        color: darken($content-text-base, 20%);
    }

    h2 {
        @extend .base-header;
        display: block;

        font-size: 1.65rem;
        // font-size: 24px;

        font-weight: $content-header-weight;
        // line-height: 1.3em;

        // margin: 1.5em 0 1em;
        margin: 2.75rem 0 1.5rem;

        // font-family: inherit;
        color: darken($content-text-base, 20%);

        @include respond-to(lg) {
            // font-size: 1.45rem;
            font-size: 1.25rem;
            line-height: 130%;
            margin: 1.2em 0 1.2em;
        }

        &:first-of-type {
            margin-top: 0;
        }

        span {
            color: $color-primary;
        }
    }

    .img+h2,
    p+h2 {
        margin-top: 2.75rem !important;
    }

    h3 {
        @extend .base-header;
        display: block;
        font-size: 1.4rem;
        margin: 1.5em 0 1.5em;
        font-weight: $content-header-weight;
        // font-family: inherit;
        color: darken($content-text-base, 20%);

        @include respond-to(lg) {
            // font-size: 1.45rem;
            font-size: 1.1rem;
            line-height: 130%;
            margin: .7em 0 .7em;
        }
    }

    h4 {
        @extend .base-header;
        display: block;
        margin-top: 1.33em;
        margin-bottom: 1.33em;
        margin-left: 0;
        margin-right: 0;
        font-weight: bold;
        // font-family: inherit;
        color: darken($content-text-base, 20%);
    }

    h5 {
        @extend .base-header;
        display: block;
        font-size: .83em;
        margin-top: 1.67em;
        margin-bottom: 1.67em;
        margin-left: 0;
        margin-right: 0;
        font-weight: bold;
        // font-family: inherit;
        color: darken($content-text-base, 20%);
    }

    h6 {
        @extend .base-header;
        display: block;
        font-size: .67em;
        margin-top: 2.33em;
        margin-bottom: 2.33em;
        margin-left: 0;
        margin-right: 0;
        font-weight: bold;
        // font-family: inherit;
        color: darken($content-text-base, 20%);
    }

    b {
        font-weight: $content-text-bold;
        // color: darken($content-text-base, 15%);
        // letter-spacing: 0.2px;
    }

    p {
        color: $content-text-base;
        font-weight: normal;
        // margin-top: 0;
        // margin-bottom: 1.3rem;
    }

    // Отступы между абзацами
    p+p,
    p+img,
    p+ul,
    p+ol {
        margin-top: 25px;
        // margin-top: 2rem;
        // margin-bottom: 2rem;

        @include respond-to(md) {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }

    .img {
        // max-width: 60%;
        width: 100%;
        margin-bottom: 40px;
        max-width: 640px;
        border-radius: 20px;

    }

    // нумерованный список
    ol {
        margin-left: 20px;
        margin-top: 0;
        margin-bottom: 1rem;
        list-style: decimal;

        li+li {
            // margin-top: 7px;
            margin-top: 12px;

            @include respond-to(md) {
                margin-top: 7px;
            }
        }

        li {
            padding-left: 5px;

            // вложенный список
            ul {
                margin-top: 5px;

                li {
                    margin-top: 0;
                }
            }

            p {
                font-size: .9rem;
                line-height: 140%;
                color: fade-out($color: $content-text-base, $amount: .3) !important;
                // margin-bottom: 0;
                margin: 7px 0;
            }
        }
    }

    // ненумерованный список
    ul {
        margin-left: 20px;
        margin-top: 0;
        margin-bottom: 1rem;
        list-style: disc;

        li+li {
            // margin-top: 7px;
            margin-top: 12px;

            @include respond-to(md) {
                margin-top: 7px;
            }
        }

        li {
            padding-left: 5px;

            &::marker {
                color: $color-primary;
                font-size: 1.1rem;
            }

            p {
                font-size: .9rem;
                line-height: 140%;
                color: fade-out($color: $content-text-base, $amount: .3) !important;
                // margin-bottom: 0;
                margin: 7px 0;
            }
        }
    }

    table {
        border-collapse: collapse;
        margin: 25px 0;
        font-size: 0.9em;
        font-family: sans-serif;
        min-width: 400px;
        overflow: hidden;
        border: 1px solid #e5e5e5;

        thead tr {
            background-color: $color-primary;
            color: #ffffff;
            text-align: left;
        }

        & th,
        & td {
            padding: 12px 15px;
        }

        & tbody {
            tr {
                &:nth-of-type(even) {
                    background-color: #f3f3f3;
                }

                &.active-row {
                    font-weight: bold;
                    color: $color-primary;
                }

            }
        }
    }
}

ul.checked-list {
    font-size: 18px;
    list-style: none;

    @include respond-to(md) {
        font-size: 16px;
        margin-left: 0;
    }

    li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;
        padding-left: 0;

        @include respond-to(md) {
            margin-bottom: 5px;
        }

        &:before {
            content: '';
            display: inline-block;
            flex-shrink: 0;
            height: 1.2em;
            width: 1.2em;
            position: relative;
            top: 7px;
            background-image: url(../img/icons/check-2.svg);
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: .7em;

            @include respond-to(md) {
                height: 1rem;
                width: 1rem;
            }
        }
    }
}

.list {

    // Список с папками
    &-folders {
        // font-size: 16px;
        list-style: none;
        margin: 0 !important;
        padding: 0;

        @include respond-to(md) {
            font-size: 15px;
            margin-left: 0;
        }

        li {
            display: flex;
            align-items: flex-start;
            margin-bottom: 7px;
            padding-left: 0;

            @include respond-to(md) {
                margin-bottom: 5px;
            }

            &:before {
                content: '';
                display: inline-block;
                flex-shrink: 0;
                height: 1rem;
                width: 1rem;
                position: relative;
                top: 5px;
                background-image: url(../img/icons/folder-yellow.svg);
                background-size: contain;
                background-repeat: no-repeat;
                margin-right: .7em;

                @include respond-to(md) {
                    height: 1rem;
                    width: 1rem;
                }
            }

            a {
                color: inherit;

                &:active,
                &:hover {
                    // color: $color-primary;
                    @include recolor($color-primary, 1);

                    &:after {
                        opacity: .7;
                    }
                }

                &:after {
                    content: '';
                    display: inline-block;
                    flex-shrink: 0;
                    height: .6rem;
                    width: .6rem;
                    position: relative;
                    top: 0px;
                    background-image: url(../img/icons/upper-right-arrow.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    margin-left: .7em;
                    opacity: .2;

                    @include respond-to(md) {
                        // height: 1rem;
                        // width: 1rem;
                    }
                }
            }
        }

        a {
            color: inherit !important;
        }
    }
}

// Преимущества в контенте
.content-advantage {
    &__item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        &+& {
            margin-top: 35px;
        }
    }

    &__number {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        color: #000;
        font-size: 1rem;
        line-height: 1rem;
        font-family: $font-decorative;
        font-weight: 500;
        margin-top: 3px;
        // border: 2px solid;
        padding: 10px 10px 10px 11px;
        border-radius: 100px;
        height: 40px;
        width: 40px;

        // background-color: #fafafa;
        background-color: #f5f5f5;

        @include respond-to(md) {
            font-size: 1rem;
            margin-top: 0;
        }
    }

    &__info {
        padding-left: 25px;

        @include respond-to(lg) {
            padding-left: 20px;
        }
    }

    &__title {
        // @include text-brand-styling();
        // color: #3f4451 !important;
        // font-family: $font-decorative;
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: .3px;

        @include respond-to(md) {
            font-size: 1.1rem;
            line-height: 140%;
        }
    }

    &__desc {
        @extend .content-area;
        font-size: 1rem;
        line-height: 160%;
        margin-top: 15px;

        @include respond-to(md) {
            font-size: 14px;
            margin-top: 10px;
        }
    }
}