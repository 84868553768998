// Карточка преимущества
.advantage-card {
  &__item {
    // padding: 30px 40px;
    // border-radius: 20px;
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__img {
    img {
      aspect-ratio: 1/1;
      object-fit: cover;
      object-position: center;
      border-radius: 300px;
      width: 200px;
    }
  }

  &__description {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    margin-top: 30px;
    text-align: center;
    padding: 0 40px;
  }

}