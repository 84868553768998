 .section {
   margin: var(--space-2xl-3xl) 0;
 }

 .base-section-bg {
   //  margin-top: 91px;
   //  position: relative;
   //  background-image: url(../static/implant_dentalway__ru/build/i/25517530f635a65bead6f4b0b80cbbb5.jpg);
   //  background-repeat: no-repeat;
   //  background-size: cover;
   //  background-position: top;

   //  // padding: 120px 0 80px;

   //  &:before {
   //    content: '';
   //    display: block;
   //    width: 100%;
   //    background: rgb(255, 255, 255);
   //    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
   //    height: 100px;
   //    position: absolute;
   //    top: 0;
   //    left: 0;
   //    right: 0;
   //  }

   //  &:after {
   //    content: '';
   //    display: block;
   //    width: 100%;
   //    background: rgb(255, 255, 255);
   //    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
   //    height: 100px;
   //    position: absolute;
   //    bottom: 0;
   //    left: 0;
   //    right: 0;
   //  }

   //  &>.container {
   //    position: relative;
   //    z-index: 10;
   //  }
 }