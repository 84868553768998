.button {
  // border-radius: 15px/30px;
  border-radius: 100px;
  min-height: 70px;
  padding: 0 40px;
  line-height: 140%;
  transition: .2s ease-in-out;

  //-----
  // Анимация кнопки
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 40px;
    height: 300px;
    margin-left: 60px;
    background: fade-out($color: #ffffff, $amount: .5);
    position: absolute;
    left: -100px;
    top: -150px;
    z-index: 1;
    transform: rotate(45deg);
    transition: all 0.1s;
    opacity: .5;
  }

  &:hover {

    &:after {
      animation-name: slideme;
      animation-duration: 2s;
      animation-delay: 0.05s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  }

  &:active {
    top: 2px;
  }
}

.button-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 50px;
  gap: 10px;
  border-radius: 15px;

  // /* Gradient Yellow */
  // background: linear-gradient(180deg, #FFE89E 0%, #FECC3C 100%);
  // border-bottom: 3px solid #CBB647;
  // box-shadow: 0px 4px 40px 5px #FFE89E;

  /* Gradient Secondary */
  background: linear-gradient(180deg, lighten($color-secondary, 10%) 0%, $color-secondary 100%);
  border-bottom: 3px solid darken($color-secondary, 10%);
  // box-shadow: 0px 4px 40px 5px $color-secondary;

  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  letter-spacing: .5px;
  // color: #11263A;
  color: #fff !important;

  &-primary {
    color: #fff !important;
    // background: linear-gradient(180deg, #FFE89E 0%, #FECC3C 100%);
    background: $gradient-button-primary;
    border-bottom: 3px solid darken($color-primary, 20%);
    box-shadow: 0px 4px 20px 5px lighten($color-primary, 25%);
  }
}

/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  // background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  // background-image: radial-gradient(100% 100% at 100% 0, #89f7fe 0, #66a6ff 100%);
  background-image: radial-gradient(100% 100% at 100% 0, $gradient-button-color-1, $gradient-button-color-2 100%);
  border: 0;
  // border-radius: 6px;
  // box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  height: 60px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s, transform .15s;
  user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;

  @include respond-to(md) {
    height: 45px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
  }

  &:focus {
    box-shadow: $gradient-button-color-1 0 0 0 1.5px inset,
      $gradient-button-color-1 0 2px 4px,
      $gradient-button-color-1 0 7px 13px -3px,
      $gradient-button-color-1 0 -3px 0 inset;
  }

  &:hover {
    box-shadow: $gradient-button-color-1 0 4px 8px,
      $gradient-button-color-1 0 7px 13px -3px,
      $gradient-button-color-1 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: $gradient-button-color-1 0 3px 7px inset;
    transform: translateY(2px);
  }
}

@keyframes slideme {
  0% {
    left: -30px;
    margin-left: 0px;
  }

  30% {
    left: 110%;
    margin-left: 80px;
  }

  100% {
    left: 110%;
    margin-left: 80px;
  }
}