/*
 * Брэйкпоинты
 * Tailwind CSS
 */
$breakpoint-sm: 640px; // sm
$breakpoint-md: 768px; // md
$breakpoint-lg: 1024px; // lg
$breakpoint-xl: 1280px; // xl
$breakpoint-2xl: 1536px; // 2xl
$breakpoint-xsm-max: ($breakpoint-sm - 1);
$breakpoint-sm-max: ($breakpoint-md - 1);
$breakpoint-md-max: ($breakpoint-lg - 1);
$breakpoint-lg-max: ($breakpoint-xl - 1);

// ШРИФТЫ
$font-header: 'Montserrat',
sans-serif;
$font-decorative: 'Montserrat',
sans-serif;
$font-geometria: Geometria,
sans-serif;


// ОСНОВНЫЕ ЦВЕТА
// $color-primary: #008cd4; // default
// $color-primary: red;
// $color-primary: #756AB6;
// $color-primary:#f68081;
$color-primary: #2b74a7;
$color-secondary: #2b74a7;
$color-third: rgb(34, 161, 210);

// $color-primary-light: #d0e3ee; // default
// $color-primary-light: lighten(red, 40%);
$color-primary-light: #f1f1ff;

// ФОН КАРТОЧЕК
$color-bg-gray: #f3f6f9; // default
// $color-bg-gray: lighten(red, 45%);
$color-bg-gray: #fafafa;

// gradients
// $gradient-button-primary: linear-gradient(222.59deg, #e75a3c 2.98%, #f0b050 99.12%); // default
// $gradient-button-primary: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
// $gradient-button-primary: linear-gradient(62deg, #8EC5FC 0%, #E0C3FC 100%);

$gradient-primary-color-1: #0b4f7f;
$gradient-primary-color-2: #2b74a7;
$gradient-primary: linear-gradient(135deg, $gradient-primary-color-1 0%, $gradient-primary-color-2 100%); // цвет фона

$gradient-secondary-color-1: #0b4f7f;
$gradient-secondary-color-2: #2b74a7;
$gradient-secondary: linear-gradient(135deg, $gradient-secondary-color-1 0%, $gradient-secondary-color-2 100%); // цвет фона

$gradient-yellow: linear-gradient(180deg, #FFE89E 0%, #FECC3C 100%);

// ***********************
// ЦВЕТА ГРАДИЕНТА КНОПОК
// ***********************
// Дефолтный
$gradient-button-color-1: #e75a3c; // default
$gradient-button-color-2: #f0b050; // default

// // Вариант 2
// $gradient-button-color-1: #85c599;
// $gradient-button-color-2: #85c599;
$gradient-button-color-1: #2b74a7;
$gradient-button-color-2: #0b4f7f;

// это не меняем
$gradient-button-primary: linear-gradient(135deg, $gradient-button-color-1 0%, $gradient-button-color-2 100%); // цвет фона
$gradient-button-primary-embossing: linear-gradient(222deg, darken($gradient-button-color-1, 20%), darken($gradient-button-color-2, 20%)); // цвет тиснения
$gradient-button-primary-hover: linear-gradient(135deg, darken($gradient-button-color-1, 3%) 0%, darken($gradient-button-color-2, 3%) 100%); // цвет фона при наведении

$gradient-button-secondary: linear-gradient(135deg, $color-secondary 0%, darken($color-secondary, 10%) 100%); // цвет фона
$gradient-button-secondary-embossing: linear-gradient(222deg, darken($color-secondary, 20%), darken($color-secondary, 25%)); // цвет тиснения
$gradient-button-secondary-hover: linear-gradient(135deg, darken($color-secondary, 3%) 0%, darken($color-secondary, 6%) 100%); // цвет фона при наведении

$gradient-button-third: linear-gradient(135deg, $color-third 0%, darken($color-third, 10%) 100%); // цвет фона
$gradient-button-third-embossing: linear-gradient(222deg, darken($color-third, 20%), darken($color-third, 25%)); // цвет тиснения
$gradient-button-third-hover: linear-gradient(135deg, darken($color-third, 3%) 0%, darken($color-third, 6%) 100%); // цвет фона при наведении

// форма кнопки
// $button-default-border-radius: 50px; // default
$button-default-border-radius: 10px;

// Палитра для тем
$color-1: #98024c;
$color-2: #f68081;
$color-3: #fab8a4;
$color-4: #e69505;
$color-5: #bedaf0;
$color-6: #eeecf3;

// для использования в циклах
$colors: (color-1: $color-1,
  color-2: $color-2,
  color-3: $color-3,
  color-4: $color-4,
  color-5: $color-5,
  color-6: $color-6);



/* @link https://utopia.fyi/space/calculator?c=320,16,1.2,1240,18,1.25,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
  /* Space 3xs: 4px → 5px */
  --space-3xs: clamp(4px, 3.6522px + 0.1087vi, 5px);
  /* Space 2xs: 8px → 9px */
  --space-2xs: clamp(8px, 7.6522px + 0.1087vi, 9px);
  /* Space xs: 12px → 14px */
  --space-xs: clamp(12px, 11.3043px + 0.2174vi, 14px);
  /* Space s: 16px → 18px */
  --space-s: clamp(16px, 15.3043px + 0.2174vi, 18px);
  /* Space m: 24px → 27px */
  --space-m: clamp(24px, 22.9565px + 0.3261vi, 27px);
  /* Space l: 32px → 36px */
  --space-l: clamp(32px, 30.6087px + 0.4348vi, 36px);
  /* Space xl: 48px → 54px */
  --space-xl: clamp(48px, 45.913px + 0.6522vi, 54px);
  /* Space 2xl: 64px → 72px */
  --space-2xl: clamp(64px, 61.2174px + 0.8696vi, 72px);
  /* Space 3xl: 96px → 108px */
  --space-3xl: clamp(96px, 91.8261px + 1.3043vi, 108px);

  /* One-up pairs */
  /* Space 3xs-2xs: 4px → 9px */
  --space-3xs-2xs: clamp(4px, 2.2609px + 0.5435vi, 9px);
  /* Space 2xs-xs: 8px → 14px */
  --space-2xs-xs: clamp(8px, 5.913px + 0.6522vi, 14px);
  /* Space xs-s: 12px → 18px */
  --space-xs-s: clamp(12px, 9.913px + 0.6522vi, 18px);
  /* Space s-m: 16px → 27px */
  --space-s-m: clamp(16px, 12.1739px + 1.1957vi, 27px);
  /* Space m-l: 24px → 36px */
  --space-m-l: clamp(24px, 19.8261px + 1.3043vi, 36px);
  /* Space l-xl: 32px → 54px */
  --space-l-xl: clamp(32px, 24.3478px + 2.3913vi, 54px);
  /* Space xl-2xl: 48px → 72px */
  --space-xl-2xl: clamp(48px, 39.6522px + 2.6087vi, 72px);
  /* Space 2xl-3xl: 64px → 108px */
  --space-2xl-3xl: clamp(64px, 48.6957px + 4.7826vi, 108px);

  /* Custom pairs */
  /* Space s-l: 16px → 36px */
  --space-s-l: clamp(16px, 9.0435px + 2.1739vi, 36px);
}