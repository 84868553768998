.about {
  &__section-bg {
    @extend .base-section-bg;
  }

  &__text {
    @extend .content-area;

    @include respond-to(md) {
      margin-top: 20px;
    }

    p {
      // color: #8b8b8b;
      color: fade-out($color: #000000, $amount: .3);
      font-size: 18px;
      line-height: 170%;

      @include respond-to(md) {
        font-size: 14px;
      }
    }

    ul {
      font-size: 18px;
      line-height: 150%;

      @include respond-to(md) {
        font-size: 16px;
        line-height: 160%;
      }

      li {
        color: fade-out($color: #000000, $amount: .3);
      }
    }
  }

  &__img {
    height: 100%;

    @include respond-to(md) {
      height: 240px;
    }

    img {
      height: 100%;
      width: 100%;
      border-radius: 15px;
      object-fit: cover;
      object-position: top center;
    }
  }

  &__photogallery {
    img {
      border-radius: 20px;
      object-fit: cover;
      object-position: center;
      height: 320px;
      width: 100%;


      @include respond-to(md) {
        height: 320px;
        width: 100%;
      }
    }
  }
}