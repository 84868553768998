#employees-swiper {
	.swiper-wrapper {
		@include respond-from(md) {
			// padding: 0 200px;
		}
	}

	.swiper-slide {
		padding: 60px 30px 60px;
		width: fit-content !important;

		@include respond-to(md) {
			padding: 60px 15px;
		}

		// // отступ до контейнера
		// &:first-child {
		//   margin-left: 200px;
		// }
	}
}

.employee-card {
	&__item {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		background-color: #fff;
		border-radius: 20px;
		position: relative;
		box-shadow: 0px 20px 65px -10px rgba(15, 29, 67, 0.25);

		height: 420px;
		// width: 340px;

		@include respond-to(md) {
			// height: 600px;
			// max-width: 260px;
			margin-top: 30px;
		}
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		img {
			position: absolute;
			right: -10px;
			top: -20px;
			bottom: 0;
			// height: calc(100% + 20px);
			height: 380px;
			z-index: 0;

			object-fit: contain;
			object-position: center;
		}
	}

	&__top {
		padding: 30px 30px 10px;
		position: relative;
		z-index: 10;

		display: flex;
		flex: 1;
		align-items: flex-end;

		@include respond-to(md) {
			padding: 20px 15px 0px;
		}
	}

	&__bottom {
		background-color: #E6F7F9;
		color: #E6F7F9;
		border-radius: 15px;
		margin: 10px;
		position: relative;
		z-index: 2;

	}

	&__name-card {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: space-between;
		// background-color: #fff;
		// border-radius: 15px;

		// box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
		// margin-top: 20px;
		// padding: 15px 20px;

		position: relative;
		z-index: 10;

		@include respond-from(md) {
			max-width: 80%;
		}
	}

	&__name-wrapper {
		border-radius: 20px;
		padding: 20px 25px;
		// backdrop-filter: blur(5px);

		background: linear-gradient(180deg, #ffe89e 0, #fecc3c 100%);
	}

	&__experience {
		position: absolute;
		top: 20px;
		left: 40px;
	}

	&__experience-caption {
		font-size: 12px;
		line-height: normal;
		color: $color-primary;
		font-weight: 600;
		font-weight: 400;
		font-size: 18px;
		color: #11263A;
	}

	&__experience-value {
		line-height: normal;
		color: $color-primary;
		// border-bottom: 1px solid fade-out($color: $color-primary, $amount: 0.5);
		padding-bottom: 10px;
		font-weight: 600;
		font-weight: 400;
		font-size: 18px;
		color: #11263A;

		span {
			font-weight: 600;
			font-size: 48px;
			line-height: 133%;

			@include respond-to(md) {
				font-size: 32px;
			}
		}
	}

	&__name {
		color: #11263A;
		font-size: 16px;
		line-height: 160%;

		font-weight: 600;
		font-size: 18px;
		line-height: 130%;
		letter-spacing: 0.2px;

		@include respond-to(md) {
			font-size: 16px;
		}
	}

	&__position {
		font-weight: 400;
		font-size: 12px;
		line-height: 160%;
		color: #727272;
		margin-top: 10px;

		@include respond-to(md) {}
	}

	&__quote {
		font-size: 14px;
		font-weight: 400;
		line-height: 150%;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 20px;
		color: #11263A;
		padding: 20px 20px 15px 0;

		@include respond-to(md) {
			font-size: 12px;
		}

		&:before {
			content: '';
			background-image: url(../img/icons/quote.svg);
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
			width: 30px;
			height: 30px;
			display: block;
			flex-shrink: 0;
		}
	}
}

.employees {
	.lead-text {
		font-size: 20px;
		text-align: center;
		margin: 50px 0 70px;

		@include respond-to(md) {
			margin: 30px 40px;
		}
	}
}