// Модальные окна
.modal {
  &__window {
    border-radius: 7px;
    max-width: 100%;
    width: 500px;
  }

  &__header {
    font-size: 26px;
    font-weight: 600;
    color: #000;
    text-align: center;
    line-height: 140%;

    @include respond-to(md) {
      font-size: 18px;
    }
  }

  &__subtitle {
    font-size: 16px;
    line-height: 1.55;
    font-weight: 400;
    color: #666;
    text-align: center;
    margin-top: 10px;

    @include respond-to(md) {
      font-size: 13px;
      line-height: 140%;
    }
  }

  // Форма в модальном окне
  &__form {
    margin-top: 20px;

    @include respond-from(md) {
      padding: 0 20px;
    }

    input[type=text],
    input[type=tel],
    input[type=email] {
      background-color: #f5f5f5;
      border: 2px solid #c9c9c9;
      margin: 0;
      height: 50px;
      padding: 0 20px;
      font-size: 16px;
      line-height: 1.33;
      width: 100%;
      border: 0;
      box-sizing: border-box;
      outline: 0;
      border-radius: 0;
      border-radius: 7px;

      &:focus {
        border-color: $color-primary;
      }
    }

    input[type=checkbox],
    input[type=radio] {
      background: #FFF;
      border-radius: 5px;
      width: 20px;
      height: 20px;
      margin-right: 15px;
      padding: 2px;

      //&:checked {
      //  background-image: linear-gradient(225deg, #FFAE00 0%, #FF7600 100%);
      //  border-radius: 3px;
      //}

      &:checked {
        border: 2px solid #ccc;
        background-color: #fff;
        background-size: 14px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3c!-- Generator: Sketch 60 (88103) - https://sketch.com --%3e%3ctitle%3eRectangle Copy 18%3c/title%3e%3cdesc%3eCreated with Sketch.%3c/desc%3e%3cdefs%3e%3clinearGradient x1='100%25' y1='0%25' x2='0%25' y2='100%25' id='linearGradient-1'%3e%3cstop stop-color='%23FFAE00' offset='0%25'%3e%3c/stop%3e%3cstop stop-color='%23FF7600' offset='100%25'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Лендосик' transform='translate(-771.000000, -1097.000000)' fill-rule='nonzero'%3e%3crect id='Rectangle-Copy-51' stroke='%232D7BD7' stroke-width='4' fill='%23FFFFFF' x='738' y='1019' width='581' height='482' rx='5'%3e%3c/rect%3e%3crect id='Rectangle-Copy-12' stroke='%232D7BD7' fill='%23FFFFFF' x='766.5' y='1092.5' width='25' height='25' rx='5'%3e%3c/rect%3e%3crect id='Rectangle-Copy-18' fill='url(%23linearGradient-1)' x='771' y='1097' width='16' height='16' rx='3'%3e%3c/rect%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    input[type=submit] {
      color: #fff !important;
      background-color: $color-primary !important;
      text-align: center;
      height: 50px;
      border: 0;
      font-size: 16px;
      padding-left: 60px;
      padding-right: 60px;
      font-weight: 700;
      white-space: nowrap;
      background-image: none;
      cursor: pointer;
      margin: 0;
      box-sizing: border-box;
      outline: 0;
      background: 0 0;
      border-radius: 7px;
      transition: .1s ease-in-out;
      width: 100%;

      &:hover {
        background-color: darken($color-primary, 10%) !important;
      }
    }
  }

  &__success {
    display: none;
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
  }

  &__errors {
    display: none;
  }

  &__pa {
    text-align: center;
    font-size: 14px;
    line-height: 1.55;
    font-weight: 400;
    color: #222;
    margin-top: 0;

    @include respond-to(md) {
      font-size: 10px;
      line-height: 130%;
      margin-top: 5px;
    }

    a {
      color: $color-primary;
      border-bottom: 1px solid $color-primary;
      box-shadow: none;
      text-decoration: none;
      transition: .1s ease-in-out;

      &:hover {
        color: fade-out($color: $color-primary, $amount: .5);
        border-color: fade-out($color: $color-primary, $amount: .5);
      }
    }
  }
}

// Стили валидации плагина JustValidate
.just-validate {
  &-error-label {
    color: red !important;
    font-size: 13px;
    margin-top: 5px;
  }

  &-error-field {
    border: 2px solid red !important;
  }

  &-success-field {
    border: 2px solid green !important;
  }
}