.price-table {
  &__title {
    @extend .base-header;
    font-size: 32px;
    margin-bottom: 20px;
    color: $color-primary;
  }

  &__table {
    @extend .price-table-full;
    @extend .tile;
    @extend .tile-white-shadow;
    background-color: #fff;

    &+& {
      margin-top: 50px;
    }
  }
}